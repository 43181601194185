import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(public Api: ApiService) { }

  getOrganization(data: any) {
    return this.Api.get(`/organization/${data}`);
  }
}
