import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ProcedureService {

  constructor(public Api: ApiService) { }

  getProcedureTypesByParams(data: any) {
    return this.Api.get(`/procedure/params/${data}`);
  }

  getProcedureSearch(data: any, token: string = null) {
    return this.Api.get(`/procedure/search/${data}`, token);
  }

  getProcedureId(data: any) {
    return this.Api.get(`/procedure/${data}`);
  }

  postCreateProcedure(data: any) {
    return this.Api.post('/procedure/', data);
  }

  putUpdateProcedure(idProcedure, data: any) {
    return this.Api.put(`/procedure/${idProcedure}`, data);
  }
}
