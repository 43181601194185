import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(public Api: ApiService) { }

  postClientOrganizationValidate(data: any) {
    return this.Api.post('/client/organization/validate/', data);
  }
}
