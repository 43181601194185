<!-- activo el chat de facebook -->
<app-chat-facebook></app-chat-facebook>
<app-boton-whatsapp></app-boton-whatsapp>
<div id="page-top" class="landing-page no-skin-config">
  <div class="navbar-wrapper">
    <nav class="navbar navbar-default navbar-fixed-top navbar-expand-md" role="navigation">
      <div class="container">
        <a class="navbar-brand" href="#">AGÉNDATE APP</a>
        <div class="navbar-header page-scroll">
          <button aria-label="Menu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar">
            <i class="fa fa-bars"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse justify-content-end" id="navbar">
          <ul class="nav navbar-nav navbar-right">
            <li><a class="nav-link page-scroll" href="#page-top">Inicio</a></li>
            <li><a class="nav-link page-scroll" href="#features">caracteristicas</a></li>
            <li><a class="nav-link page-scroll" href="#team">Equipo</a></li>
            <li><a class="nav-link page-scroll d-none" href="#testimonials">Testimonials</a></li>
            <li><a class="nav-link page-scroll" href="#pricing">Precios</a></li>
            <li><a class="nav-link page-scroll" href="#contact">Contacto</a></li>
            <li><a class="nav-link page-scroll" [routerLink]="['/cuenta/ingresar']">Iniciar sesión</a></li>
            <li><a class="nav-link page-scroll" [routerLink]="['/cuenta/registrarme']">Regístrate</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <div id="inSlider" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators pb-3">
      <li data-target="#inSlider" data-slide-to="0" class="active"></li>
      <li data-target="#inSlider" data-slide-to="1"></li>
      <li data-target="#inSlider" data-slide-to="2"></li>
      <li data-target="#inSlider" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <div class="container">
          <div class="carousel-caption caption-responsive caption-left ml-5 ml-lg-3">
            <span>Agéndate App</span>
            <h1>Mejora<br>la eficiencia<br>administrativa<br>de tu empresa.</h1>
            <p>Al automatizar la comunicación,<br>agendamiento, confirmación y<br> recordatorio de citas.</p>
            <a class="btn btn-lg btn-white rounded-pill text-uppercase font-weight-bold animate__animated animate__pulse animate__infinite"
              [href]="constants.urlAppGooglePlay" target="_blanck" role="button">Descargar YA!</a>
          </div>
          <div class="carousel-image ilustracion-uno animate__animated animate__zoomIn">
            <img class="img-fluid" src="assets/img/landing/slides/ilustracion1.jpg" alt="Gestión de citas | Agéndate App" />
          </div>
        </div>
        <div class="header-back one"></div>
      </div>

      <div class="carousel-item">
        <div class="container">
          <div class="carousel-image ilustracion-dos animate__animated animate__zoomIn">
            <img class="img-fluid" src="assets/img/landing/slides/ilustracion2.jpg" alt="Gestión de citas | Agéndate App" />
          </div>
          <div class="carousel-caption caption-responsive caption-right ml-5 mr-lg-3">
            <span>Agéndate App</span>
            <h1>El papel será<br>solo parte<br>del pasado.</h1>
            <p>Recibe información del estado<br>de tus citas en tiempo real.</p>
            <a class="btn btn-lg btn-white rounded-pill text-uppercase font-weight-bold animate__animated animate__pulse animate__infinite"
              [href]="constants.urlAppGooglePlay" target="_blanck" role="button">Descargar YA!</a>
          </div>
        </div>
        <div class="header-back"></div>
      </div>

      <div class="carousel-item">
        <div class="container">
          <div class="carousel-caption caption-responsive caption-center ml-5 ml-lg-0">
            <span>Agéndate App</span>
            <h1>Recordatorio de citas automatizado<br>¡La solución más óptima!</h1>
          </div>
          <div class="carousel-caption caption-responsive caption-right ml-5 mr-lg-3">
            <h1 class="mt-5">&nbsp;</h1>
            <p class="mt-5">De forma masiva y automatizada<br>potencia la atención que brindas<br>mediante múltiples
              canales de<br>comunicación maximizando la<br>satisfacción del cliente.</p>
            <a class="btn btn-lg btn-white rounded-pill text-uppercase font-weight-bold animate__animated animate__pulse animate__infinite"
              [href]="constants.urlAppGooglePlay" target="_blanck" role="button">Descargar YA!</a>
          </div>
          <div class="carousel-image ilustracion-tres animate__animated animate__zoomIn">
            <img class="img-fluid" src="./assets/img/landing/slides/ilustracion3.png" alt="Gestión de citas | Agéndate App" />
          </div>
        </div>
        <div class="header-back"></div>
      </div>

      <div class="carousel-item">
        <div class="container">
          <div class="carousel-caption caption-responsive caption-left ml-5 ml-lg-5">
            <span>Agéndate App</span>
            <h1>Mejora la<br>comunicación<br>con tus clientes.</h1>
            <p>De forma masiva y automatizada<br>potencia la atención que brindas<br>mediante múltiples canales
              de<br>comunicación maximizando la<br>satisfacción del cliente.</p>
            <a class="btn btn-lg btn-white rounded-pill text-uppercase font-weight-bold animate__animated animate__pulse animate__infinite"
              [href]="constants.urlAppGooglePlay" target="_blanck" role="button">Descargar YA!</a>
          </div>
          <div class="carousel-image ilustracion-cuatro animate__animated animate__zoomIn">
            <img class="img-fluid" src="assets/img/landing/slides/ilustracion4.jpg" alt="Gestión de citas | Agéndate App" />
          </div>
        </div>
        <div class="header-back"></div>
      </div>

    </div>
    <a class="carousel-control-prev" href="#inSlider" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#inSlider" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <div id="fondoFeatures">
    <section id="features" class="container services">
      <div class="row">
        <div class="col-lg-12 text-center">
          <!-- <div class="navy-line" style="margin: 0px auto 0 !important;"></div> -->
          <span class="text-info">Caracteristicas</span>
          <h1 class="titulos mb-5"><span class="navy"> Con Agéndate App:</span> </h1>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-3 justify-content-center">
          <div class="card card-border-radius border-0 text-center" style="width: 18rem;">
            <div class="card-title-services">
              <h2>Notifica tus citas<br>y eventos</h2>
            </div>
            <img loading="lazy" src="assets/img/landing/caracteristicas/caracteristica1.svg" class="card-img-top card-img-services"
              alt="Notifica tus citas y eventos | Agéndate App">
            <div class="card-body">
              <p class="card-text">En los canales de comunicación que usan tus clientes: Whatsapp, SMS o correos
                electrónicos.</p>
              <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-3 justify-content-center">
          <div class="card card-border-radius border-0 text-center" style="width: 18rem;">
            <div class="card-title-services">
              <h2>Optimiza<br>procesos</h2>
            </div>
            <img loading="lazy" src="assets/img/landing/caracteristicas/caracteristica2.svg" class="card-img-top card-img-services"
              alt="Optimiza procesos | Agéndate App">
            <div class="card-body">
              <p class="card-text">Incrementa la productividad de tus colaboradores, obtén información actualizada de
                tus citas y disminuye el índice de ausentismo de tus clientes.</p>
              <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-3 justify-content-center">
          <div class="card card-border-radius border-0 text-center" style="width: 18rem;">
            <div class="card-title-services">
              <h2>Mejora la<br>experiencia de<br>tus clientes</h2>
            </div>
            <img loading="lazy" src="assets/img/landing/caracteristicas/caracteristica3.svg" class="card-img-top card-img-services"
              alt="Mejora la experiencia de tus clientes | Agéndate App">
            <div class="card-body">
              <p class="card-text">Consigue fidelizarlos, a través de una comunicación constante y de calidad,
                compartiendo información clara y eficaz para un agendamiento satisfactorio.</p>
              <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-3 justify-content-center">
          <div class="card card-border-radius border-0 text-center" style="width: 18rem;">
            <div class="card-title-services">
              <h2>Escoge el<br>plan a tu medida</h2>
            </div>
            <img loading="lazy" src="assets/img/landing/caracteristicas/caracteristica4.svg" class="card-img-top card-img-services"
              alt="...">
            <div class="card-body">
              <p class="card-text">
                Según las necesidades que tengas en tu empresa.
                <a class="btn btn-lg btn-info rounded-pill text-uppercase font-weight-bold my-4 animate__animated animate__pulse animate__infinite {{ constants.gtm.css.contact }}"
                  href="https://wa.me/573052898203&text=Hola%2C%20estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20los%20planes%20de%20%20Ag%C3%A9ndate%20App"
                  target="_black" role="button">Pide tu cotización aquí.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="bgCirculo"></div>

  <div class="contFondoTextura mt-5 justify-content-center">
    <section class="container">
      <div class="row">
        <div class="col-12 col-lg-6 text-white">
          <span class="text-info">Caracteristicas</span>
          <h1 class="titulos text-white">
            Comunícate con tus clientes<br>de forma individual, masiva<br>y automatizada<br>
            <span class="parrafos"> a través de sus canales de<br>comunicación favoritos.</span>
          </h1>
          <p class="text-white">
            Agéndate App automátiza la interacción de<br>tus citas mediante múltiples canales de<br>comunicación
            maximizando la satisfacción<br>de tus clientes.
          </p>
          <div class="row">
            <div class="col-12 col-lg-6 my-lg-4">
              <div class="items-img-descripcion">
                <img loading="lazy" src="/assets/img/landing/caracteristicas/icon-whatsapp.svg" class="img-fluid small">
                <div class="cont-descripcion small-des">
                  <span class="text-info">WhatsApp</span>
                  <br>
                  <p class="descripcion text-white">Notifica tus citas y eventos a tus clientes en la aplicación de mensajería más usada en el
                    mundo.</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 my-lg-4">
              <div class="items-img-descripcion">
                <img loading="lazy" src="/assets/img/landing/caracteristicas/icon-sms.svg" class="img-fluid small">
                <div class="cont-descripcion small-des">
                  <span class="text-info">SMS</span>
                  <br>
                  <p class="descripcion text-white">Un canal que te permite tener el 100% de cobertura en el que tus clientes solo necesitan un
                    celular.</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 my-lg-4">
              <div class="items-img-descripcion">
                <img loading="lazy" src="/assets/img/landing/caracteristicas/icon-correo.svg" class="img-fluid small">
                <div class="cont-descripcion small-des">
                  <span class="text-info">Correo electrónico</span>
                  <br>
                  <p class="descripcion text-white">Envía información detallada de tus servicios y realiza campañas de email marketing.</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 my-lg-4">
              <div class="items-img-descripcion">
                <img loading="lazy" src="/assets/img/landing/caracteristicas/icon-correo.svg" class="img-fluid small">
                <div class="cont-descripcion small-des">
                  <span class="text-info">Notificaciones push</span>
                  <br>
                  <p class="descripcion text-white">El personal de tu empresa recibirá la confirmación o cancelación de las citas que han sido
                    asignadas a tus clientes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 justify-content-center mt-lg-5">
          <img loading="lazy" src="/assets/img/landing/caracteristicas/celularesHerramientas.svg" class="mt-lg-3 img-fluid w-75">
        </div>
      </div>
    </section>
  </div>

  <section class="container features">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 text-left">
        <span class="text-info">Caracteristicas</span>
        <h3 class="titulos">¿Cómo confirman<br>o cancelan las<br>citas tus clientes?</h3>
        <div class="linea linea-60"></div><br>
        <div class="linea linea-160"></div>
        <p class="text-info text-features-left">
          Con Agéndate App mejora la atención<br>
          y fideliza tus clientes con servicios que<br>
          permitan reducir el índice de<br>
          ausentismo en las citas asignadas.
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-6 text-right">
        <h2 class="font-weight-semibold text-primary mt-lg-5">
          Pensamos en la atención al cliente,<br>
          automatizando procesos repetitivos
        </h2>
        <div class="linea linea-100 text-right"></div>
        <p class="text-primary">Mejoramos la experiencia de usuario</p>
        <p class="font-weight-medium text-features-right">
          En <span class="navy"><strong>Agéndate App</strong></span> sabemos que los clientes<br>
          son lo más importante para tu empresa y<br>
          para brindarles un mejor servicio hemos<br>
          desarrollado un módulo de confirmación y<br>
          cancelación de citas para tus clientes,<br>
          funciona de la siguiente forma:
        </p>
      </div>
      <div class="col-12 text-center">
        <img loading="lazy" src="/assets/img/landing/caracteristicas/ipad-citas.svg" class="img-fluid w-75">
      </div>
      <div class="col-12 col-md-6 col-lg-6 text-left">
        <div class="items-img-descripcion">
          <img loading="lazy" src="/assets/img/iconos/icon-h-mensaje.svg" class="img-fluid medium">
          <div class="cont-descripcion medium-des">
            <small class="text-info">Agéndate App</small><br>
            <h4 class="subtitulos">Asignar una cita</h4>
            <p class="descripcion">
              Al asignar una cita, al paciente le llega un mensajes por (Correo electrónico, mensaje de texto o
              WhatsApp)
              con los datos de la cita, el cliente lo que debe hacer es ingresar al enlace enviado y confirmar o
              cancelar
              la
              cita.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 text-left">
        <div class="items-img-descripcion">
          <img loading="lazy" src="/assets/img/iconos/icon-h-telefono.svg" class="img-fluid medium">
          <div class="cont-descripcion medium-des">
            <small class="text-info">Agéndate App</small><br>
            <h4 class="subtitulos">Asignar una cita</h4>
            <p class="descripcion">
              Una vez realizado este proceso <span class="navy"><strong>Agéndate App</strong></span> notifica
              automáticamente
              al encargado de administrar las citas, con esto ayudamos a liberar el tiempo del personal de tu empresa
              para
              que atienda otras tareas de valor.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div id="team" class="contFondoTextura mt-5 justify-content-center">
    <section class="container">
      <div class="row">
        <div class="col-12 col-lg-6 text-white cont-titlo-team">
          <span class="text-info">Equipo</span>
          <h1 class="titulos text-white">
            Somos apasionados por la tecnología y trabajamos para<br>que tu empresa esté a la<br>vanguardia con ella.
          </h1>
        </div>
        <div class="col-12 col-lg-6 mt-lg-5">
          <p class="parrafos mt-lg-5">
            <span class="text-info">
              En Agéndate App contamos con personas extraordinarias<br>
              que están dispuestas diariamente a ser desafiadas<br>
            </span>
            <span class="text-white">
              construir herramientas que permitan revolucionar la<br>
              atención al cliente y aumentar la productividad del personal<br>
              de tu empresa automatizando procesos rutinarios como el<br>
              agendamiento de citas.
            </span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="my-5 col-12 col-sm-6 col-lg-4 animate__animated animate__fadeInLeft">
          <div class="items-img-descripcion teamAgendate">
            <img loading="lazy" src="/assets/img/landing/avatar13.png" class="img-fluid team">
            <div class="cont-descripcion team-des">
              <p class="cargo-team">Cofounder,<br>Backend Developer.</p>
              <h4 class="nombre-team">Victor Alejandro<br>Caicedo R.</h4>
              <ul class="list-inline social-icon">
                <!-- <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                </li> -->
                <li class="list-inline-item"><a href="https://www.linkedin.com/in/victor-a-caicedo-r-036887a4/?msgControlName=reply_to_sender&msgConversationId=6687468178332884992&msgOverlay=true" target="_blanck"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="my-5 col-12 col-sm-6 col-lg-4 animate__animated animate__zoomIn">
          <div class="items-img-descripcion teamAgendate">
            <img loading="lazy" src="/assets/img/landing/avatar10.png" class="img-fluid team">
            <div class="cont-descripcion team-des">
              <p class="cargo-team">CEO, Cofounder,<br>Frontend Developer.</p>
              <h4 class="nombre-team">Juan Manuel<br>Viveros M.</h4>
              <ul class="list-inline social-icon">
                <!-- <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                </li> -->
                <li class="list-inline-item"><a href="https://www.linkedin.com/in/juan-manuel-viveros-m-338263a0/?msgControlName=reply_to_sender&msgConversationId=6687468178332884992&msgOverlay=true" target="_blanck"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="my-5 col-12 col-sm-6 col-lg-4 animate__animated animate__fadeInRight">
          <div class="items-img-descripcion teamAgendate">
            <img loading="lazy" src="/assets/img/landing/avatar11.png" class="img-fluid team">
            <div class="cont-descripcion team-des">
              <p class="cargo-team">Trade Marketing<br>Manager.</p>
              <h4 class="nombre-team">Alejandra<br>Aguilar</h4>
              <ul class="list-inline social-icon">
                <!-- <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                </li> -->
                <li class="list-inline-item"><a href="https://www.linkedin.com/in/maria-alejandra-aguilar-romero-13549b188/?msgControlName=reply_to_sender&msgConversationId=6687468178332884992&msgOverlay=true" target="_blanck"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="my-5 col-12 col-sm-6 col-lg-4 animate__animated animate__fadeInLeft">
          <div class="items-img-descripcion teamAgendate">
            <img loading="lazy" src="/assets/img/landing/avatar12.png" class="img-fluid team">
            <div class="cont-descripcion team-des">
              <p class="cargo-team">Product<br>Designer.</p>
              <h4 class="nombre-team">Cristian<br>Grajales</h4>
              <!-- <ul class="list-inline social-icon">
                <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
        <div class="my-5 col-12 col-sm-6 col-lg-4 animate__animated animate__zoomIn">
          <div class="items-img-descripcion teamAgendate">
            <img loading="lazy" src="/assets/img/landing/avatar14.png" class="img-fluid team">
            <div class="cont-descripcion team-des">
              <p class="cargo-team">Trade Marketing<br>Manager.</p>
              <h4 class="nombre-team">Nelson<br>Daza</h4>
              <!-- <ul class="list-inline social-icon">
                <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
        <div class="my-5 col-12 col-sm-6 col-lg-4 animate__animated animate__zoomIn">
          <div class="items-img-descripcion teamAgendate">
            <img loading="lazy" src="/assets/img/landing/avatar15.png" class="img-fluid team">
            <div class="cont-descripcion team-des">
              <p class="cargo-team">Product Innovation<br>Manager.</p>
              <h4 class="nombre-team">Luisa Fernanda<br>Bermúdez C.</h4>
              <ul class="list-inline social-icon">
                <!-- <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                </li> -->
                <li class="list-inline-item"><a href="https://www.linkedin.com/in/luisabermudezc/?msgControlName=reply_to_sender&msgConversationId=6687468178332884992&msgOverlay=true" target="_blanck"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="container features">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 text-left">
        <span class="text-info">Planes</span>
        <h3 class="titulos">Gestiona mejor las<br>citas de tus clientes</h3>
        <div class="linea linea-60"></div><br>
        <div class="linea linea-160"></div>
        <p class="text-info font-size-1_2">
          Agéndate App es la solución<br>
          para el agendamiento de citas<br>
          automatizadas, optimiza los procesos<br>
          de agenda, confirmación, cancelación<br>
          e interacción con clientes.
        </p>
      </div>
      <div class="col-12 col-md-6 col-lg-6 text-left">
        <div class="row">
          <div class="col-12">
            <div class="items-img-descripcion mt-3">
              <img loading="lazy" src="/assets/img/iconos/icon-h-usuario.svg" class="img-fluid medium">
              <div class="cont-descripcion medium-des">
                <small class="text-info">Agéndate App</small><br>
                <h4 class="subtitulos">Administra tus usuarios</h4>
                <p class="descripcion">
                  Administra el personal de tu empresa y clientes de forma ágil.
                  Si ya cuentas con una base de datos de clientes Agéndate App te permite hacer la integración fácilmente.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="items-img-descripcion mt-3">
              <img loading="lazy" src="/assets/img/iconos/icon-h-reloj.svg" class="img-fluid medium">
              <div class="cont-descripcion medium-des">
                <small class="text-info">Agéndate App</small><br>
                <h4 class="subtitulos">Gestiona citas</h4>
                <p class="descripcion">
                  ¡No más esperas! con Agéndate App obtén información actualizada de los clientes que ya han confirmado,
                  cancelado
                  y los que están pendientes por confirmar las citas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <img loading="lazy" src="/assets/img/landing/header_gestioncitas.png" class="img-fluid medium">
      </div>
      <div class="col-12 text-center">
        <img loading="lazy" src="/assets/img/logos/logo-texto-azul-agendateapp.svg" class="img-fluid medium w-25">
      </div>
    </div>
  </section>

  <section id="testimonials" class="navy-section testimonials d-none" style="margin-top: 0">

    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center animate__animated animate__zoomIn">
          <i class="fa fa-comment big-icon"></i>
          <h1>
            What our users say
          </h1>
          <div class="testimonials-text">
            <i>"Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text,
              and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
              evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."</i>
          </div>
          <small>
            <strong>12.02.2014 - Andy Smith</strong>
          </small>
        </div>
      </div>
    </div>

  </section>

  <section class="comments gray-section d-none" style="margin-top: 0">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>What our partners say</h1>
          <p>Donec sed odio dui. Etiam porta sem malesuada. </p>
        </div>
      </div>
      <div class="row features-block">
        <div class="col-lg-4">
          <div class="bubble">
            "Uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by
            accident, sometimes on purpose (injected humour and the like)."
          </div>
          <div class="comments-avatar">
            <a href="" class="float-left">
              <img loading="lazy" alt="image" src="assets/img/landing/avatar3.jpg">
            </a>
            <div class="media-body">
              <div class="commens-name">
                Andrew Williams
              </div>
              <small class="text-muted">Company X from California</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="bubble">
            "Uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by
            accident, sometimes on purpose (injected humour and the like)."
          </div>
          <div class="comments-avatar">
            <a href="" class="float-left">
              <img loading="lazy" alt="image" src="assets/img/landing/avatar1.jpg">
            </a>
            <div class="media-body">
              <div class="commens-name">
                Andrew Williams
              </div>
              <small class="text-muted">Company X from California</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="bubble">
            "Uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by
            accident, sometimes on purpose (injected humour and the like)."
          </div>
          <div class="comments-avatar">
            <a href="" class="float-left">
              <img loading="lazy" alt="image" src="assets/img/landing/avatar2.jpg">
            </a>
            <div class="media-body">
              <div class="commens-name">
                Andrew Williams
              </div>
              <small class="text-muted">Company X from California</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div id="pricing" class="contFondoTexturaPlanes mt-5 justify-content-center">
    <section class="container pricing">
      <div class="row padding-titulo-planes">
        <div class="col-12 text-white text-center">
          <span class="text-info">Planes</span>
          <h1 class="titulos text-white">
            Nuestro planes y precios<br>
            <small class="subtitulos text-white">
              Elige el plan que más se adapte a las necesidades de tu empresa<br>
              <span class="text-info">y prueba gratis durante 1 mes</span><br>
              los beneficios de Agéndate App.
              <!-- Todos nuestros planes son ajustables a tus necesidades, si te gastas o requieres más
              notificaciones de las que tiene el plan o requieres más usuarios encargados de atender las citas, puedes
              hacer la solicitud y serán cargadas a tu cuenta. -->
            </small>
          </h1>
        <span class="text-info">Recibe descuentos del 15%, 20%, y 30% por pagos adelantados.</span>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-lg-4 animate__animated animate__zoomIn">
          <ul class="pricing-plan list-unstyled">
            <li class="pricing-title text-center text-info">
              Gratis<br>
              <!-- <div>Ideal para emprendedores</div> -->
            </li>
            <li class="pricing-desc text-center">
              <p class="text-white">
                Desde<br>
                <span class="font-size-39">$0</span><br>
                Mensual
              </p>
            </li>
            <!-- <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Usuarios atiende citas ilimitados</span><br>
                  <small>Es el usuario encargado de atender la cita</small>
                </div>
              </div>
            </li> -->
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">30 notificaciones</span><br>
                  <small>(Correo electrónico y mensaje de texto)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Promedio de clientes que pueden ser atendidos</span><br>
                  <small>10 clientes al mes (3 a 4 notificaciones por cliente atendido)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Soporte personalizado</span><br>
                  <small>(Email, Celular y Whatsapp)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">1 Entrenamientos y capacitaciones</span><br>
                  <small>virtuales</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Actualizaciones frecuentes</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Acceso a módulos estandar y módulo de auto agendamiento de citas para clientes</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Acceso administrador web</small>
                </div>
              </div>
            </li>
            <li class="text-center">
              <a [routerLink]="['/cuenta/registrarme']" class="btn btn-lg btn-info rounded-pill text-uppercase font-weight-bold my-4 animate__animated animate__pulse animate__infinite">
                <i class="fas fa-address-card"></i> Regístrate gratis
              </a>
              <br>
              <a href="https://wa.me/573052898203&text=Hola%20Ag%C3%A9ndate%2C%20estoy%20interesado%20en%20adquirir%20el%20plan%20Gratis%20D%C3%BAo%0A" target="_black" class="text-primary text--xl cursor-pointer {{ constants.gtm.css.interestInPlanGratis }}">
                <i class="fab fa-whatsapp"></i>
                Escribenos resolvemos tus dudas.
              </a>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 animate__animated animate__zoomIn">
          <ul class="pricing-plan list-unstyled">
            <li class="pricing-title text-white bg-info">
              Basic<br>
              <div class="text-primary">Ideal para emprendedores</div>
            </li>
            <li class="pricing-desc text-center">
              <p class="text-info">
                Desde<br>
                <span class="font-size-39">$23.000 <small>COP</small></span><br>
                Mensuales
              </p>
            </li>
            <!-- <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">3 usuario atiende citas</span><br>
                  <small>Es el usuario encargado de atender la cita</small>
                </div>
              </div>
            </li> -->
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">200 notificaciones</span><br>
                  <small>(Correos electrónicos y mensajes de texto)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Promedio de clientes que pueden ser atendidos</span><br>
                  <small>40 a 70 clientes al mes (3 a 4 notificaciones por cliente atendido)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Soporte personalizado</span><br>
                  <small>(Email, Celular y Whatsapp)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">4 Entrenamientos y capacitaciones</span><br>
                  <small>virtuales</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Actualizaciones frecuentes</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Acceso al módulo de auto agendamiento de citas para clientes</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Acceso a nuevos módulos y funcionalidades premium</small>
                </div>
              </div>
            </li>
            <li class="text-center">
              <a [routerLink]="['/cuenta/registrarme']" class="btn btn-lg btn-info rounded-pill text-uppercase font-weight-bold my-4 animate__animated animate__pulse animate__infinite">
                <i class="fas fa-address-card"></i> Regístrate gratis
              </a>
              <br>
              <a href="https://wa.me/573052898203&text=Hola%20Ag%C3%A9ndate%2C%20estoy%20interesado%20en%20adquirir%20el%20plan%20Basic%20D%C3%BAo%0A" target="_black" class="text-primary text--xl cursor-pointer {{ constants.gtm.css.interestInPlanBasic }}">
                <i class="fab fa-whatsapp"></i>
                Escribenos resolvemos tus dudas.
              </a>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 animate__animated animate__zoomIn">
          <ul class="pricing-plan list-unstyled">
            <li class="pricing-title text-info">
              Premium<br>
              <div>Ideal para negocios con<br>clientes masivos</div>
            </li>
            <li class="pricing-desc text-center">
              <p class="text-white">
                Desde<br>
                <span class="font-size-39">$104.000</span><br>
                Mensual
              </p>
            </li>
            <!-- <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">5 usuario atiende citas</span><br>
                  <small>Es el usuario encargado de atender la cita</small>
                </div>
              </div>
            </li> -->
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">1.000 notificaciones</span><br>
                  <small>(Correo electrónico y mensaje de texto)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Promedio de clientes que pueden ser atendidos</span><br>
                  <small>200 a 330 clientes al mes (3 a 4 notificaciones por cliente atendido)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">Soporte personalizado</span><br>
                  <small>(Email, Celular y Whatsapp)</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <span class="subtitulos">6 Entrenamientos y capacitaciones</span><br>
                  <small>virtuales</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Actualizaciones frecuentes</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Acceso al módulo de auto agendamiento de citas para clientes</small>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <img loading="lazy" src="assets/img/landing/circulo-like.png" class="mr-3 circle-check" alt="Agéndate App">
                <div class="media-body">
                  <small class="text-primary">Acceso a nuevos módulos y funcionalidades premium</small>
                </div>
              </div>
            </li>
            <li class="text-center">
              <a [routerLink]="['/cuenta/registrarme']" class="btn btn-lg btn-info rounded-pill text-uppercase font-weight-bold my-4 animate__animated animate__pulse animate__infinite">
                <i class="fas fa-address-card"></i> Regístrate gratis
              </a>
              <br>
              <a href="https://wa.me/573052898203&text=Hola%20Ag%C3%A9ndate%2C%20estoy%20interesado%20en%20adquirir%20el%20plan%20Premium%20D%C3%BAo%0A" target="_black" class="text-primary text--xl cursor-pointer {{ constants.gtm.css.interestInPlanPremium }}">
                <i class="fab fa-whatsapp"></i>
                Escribenos resolvemos tus dudas.
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center" style="margin-top: 10px;">
          <div class="rounded-pill todoslosplanes">
            Todos nuestros planes son ajustables a tus necesidades, si te gastas o requieres más
                notificaciones de las que tiene el plan<!--o requieres más usuarios encargados de atender las citas-->, puedes
                hacer la solicitud y serán cargadas a tu cuenta.
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="container features">
        <div class="">
          <div class="row">
            <div class="col-lg-12 text-center">
              <span class="text-info">Planes</span>
              <h1 class="titulos">Todos nuestros planes cuentan con<br>los siguientes beneficios.</h1>
              <p class="parrafos text-primary font-size-25">Trabajamos para que en tu empresa tengan y brinden un servicio de alta calidad.</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 mt-5">
              <div class="media">
                <img loading="lazy" src="assets/img/landing/planes/icon-mantenimiento.svg" class="mr-3 features-icon" alt="Agéndate App">
                <div class="media-body mt-lg-4">
                  <small class="text-info">Agéndate App</small><br>
                  <h2 class="subtitulos">Mantenimiento y<br>soporte de la plataforma</h2>
                  <p class="descripcion">Los datos personales de tu empresa y tus clientes son lo más importante por eso nos aseguramos de dar
                    acceso a personal autorizado de tu empresa.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-5">
              <div class="media">
                <img loading="lazy" src="assets/img/landing/planes/icon-confidencialidad.svg" class="mr-3 features-icon" alt="Agéndate App">
                <div class="media-body mt-lg-4">
                  <small class="text-primary">Agéndate App</small><br>
                  <h2 class="subtitulos text-info">Confidencialidad de<br>la información</h2>
                  <p class="descripcion">Contamos con un potente equipo a tu disposición para ayudarte a solucionar las dudas y problemas que se te
                    puedan presentar.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row ml-lg-5 justify-content-center">
            <div class="col-lg-6 mt-5">
              <div class="media">
                <img loading="lazy" src="assets/img/landing/planes/icon-backups-diarios.svg" class="mr-3 features-icon" alt="Agéndate App">
                <div class="media-body mt-lg-4">
                  <small class="text-info">Agéndate App</small><br>
                  <h2 class="subtitulos">Backups diarios<br>de la información</h2>
                  <p class="descripcion">Sabemos que la pérdida de datos le genera grandes costosos a tu empresa, tiempos de inactividad,
                    insatisfacción de los clientes y pérdida de ingresos. Para evitar estos problemas en Agéndate App se guardar
                    backups de información diarios.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-5">
              <div class="media">
                <img loading="lazy" src="assets/img/landing/planes/icon-actualizaciones.svg" class="mr-3 features-icon" alt="Agéndate App">
                <div class="media-body mt-lg-4">
                  <small class="text-primary">Agéndate App</small><br>
                  <h2 class="subtitulos text-info">Actualizaciones<br>frecuentes</h2>
                  <p class="descripcion">Estamos 100% comprometidos con tu empresa y con brindar un servicio de alta calidad, por eso trabajamos
                    constantemente en brindarte herramientas que ayuden a automatizar los procesos de agendamiento, recordación
                    y cancelación de citas, las actualizaciones no tienen ningún costo adicional en tu plan.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

  </section>

  <div class="contFondoTexturaFooter mt-5 justify-content-center">
  <section id="contact" class="contact">
    <div class="container">
      <div class="row m-b-lg">
        <div class="col-lg-12 text-center">
          <h1 class="titulos text-white">Muy bien pongámonos en contacto</h1>
          <p class="text-white font-size-25">Agenda citas a otro nivel y mejora la comunicación<br>asertiva con tus clientes.</p>
        </div>
      </div>
      <div class="row m-b-lg mb-lg-5 justify-content-center">
        <div class="col-lg-6 text-center">
          <img loading="lazy" src="assets/img/landing/ilustracion_contactanos.svg" class="mr-3" alt="Agéndate App">
        </div>
        <div class="col-lg-6 mt-3 my-lg-5">
          <div class="card card-mensaje-contacto border-0 text-center">
            <div class="card-body">
              <p class="card-text font-size-20">
                <span class="text-info">El primer paso es muy sencillo:</span><br>
                <span>
                  Envíanos un correo con todo<br>
                  lo que desees saber y te daremos<br>
                  respuesta en breve por el medio<br>
                  que nos indiques.</span><br>
                <a class="btn btn-lg btn-info rounded-pill text-uppercase font-weight-bold mt-3 {{ constants.gtm.css.contact }}"
                href="https://walink.co/674ed7"
                target="_black" role="button"><i class="fab fa-whatsapp"></i> Escribenos aquí.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="mt-4 my-2 col-12 col-lg-3 text-center">
          <img loading="lazy" src="assets/img/logos/logo-agendate-blanco.svg" class="mr-3 logo-footer w-75" alt="Agéndate App">
        </div>
        <div class="my-2 col-12 col-lg-4 cont-redes-sociales">
          <h4 class="m-t-sm text-white">
            siguenos en nuestras<br>redes sociales
          </h4>
          <app-social-networks-icons></app-social-networks-icons>
        </div>
        <div class="my-2 col-12 col-lg-4">
          <address>
            <span class="text-info" title="Phone">Celular:</span>
            <a class="text-white {{ constants.gtm.css.contact }}" href="tel:+573166232885">(+57) 316-6232885</a><br>
            <span class="text-info" title="Phone">WhatsApp:</span>
            <a class="text-white {{ constants.gtm.css.contact }}" href="https://walink.co/674ed7" target="_black">(+57) 305-2898203</a><br>
            Colombia, Santiago de Cali<br />
          </address>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center m-t-lg m-b-lg">
          <p class="text-white"><strong>&copy; {{ currentYear }} Agéndate App</strong> Derechos reservados. | <a class="text-white" [routerLink]="['/terminosycondiciones']" target="_blank">Terminos y condiciones</a> | <a class="text-white" [routerLink]="['/politica-de-privacidad']" target="_blank">Politica de tratamiento de datos</a></p>
          <!-- <a href="https://manager.dongee.com/aff.php?aff=367" target="_blank">a</a> -->
        </div>
      </div>
    </div>
  </section>
  </div>
</div>
