import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
// import { ToastController, AlertController, LoadingController, NavController } from '@ionic/angular';
// import { CallNumber } from '@ionic-native/call-number/ngx';
import * as moment from 'moment';

import { Constants } from '../../providers/constants/constants';
import { AuthService } from './auth.service';
import { AppointmentService } from './appointment.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ToolsService {
  constants = Constants;
  moment = moment;
  constructor(
    private router: Router,
    private authService: AuthService,
    private Api: ApiService,
    // public navController: NavController,
    // public toastController: ToastController,
    // public alertController: AlertController,
    // private callNumber: CallNumber,
    // public loadingController: LoadingController,
    public appointmentService: AppointmentService
  ) { }

  statusService(data) {
    if (data.status === 401) {
      this.clearDataRedirectLogin();
    } else {
      // si se presenta un error diferente no detectado y se reporta al usuario para que nos envie notificacion de error
      this.alertErrorInformationWs({ msnCode: 0 }, true, this.constants.contactAgendate.supportTeam.nameKeyTeam);
    }
  }

  clearDataRedirectLogin() {
    localStorage.clear();
    localStorage.setItem(this.constants.localStorage.initAppSlider, 'true');
    this.router.navigate(['/cuenta/ingresar']);
  }

  getDataContactTeamAgendate(typeTeam: string) {
    const dataTeam = this.constants.contactAgendate[typeTeam];
    // <ion-icon name="mail" class="text-color-medium"></ion-icon> <a class="enlaces-no-decoration" href="mailto:${dataTeam.email}">${dataTeam.email}</a><br><br>
    // <ion-icon name="call" class="text-color-medium"></ion-icon> <a class="enlaces-no-decoration" href="tel:${dataTeam.cellPhoneNumber}">${dataTeam.cellPhoneNumber}</a><br><br>
    const message = `<span class="icon-whatsapp mr-1"></span><a class="text--decoration-none text-primary" target="_blank" href="https://wa.me/57${dataTeam.whatsapp}">${dataTeam.whatsapp}</a>`;
    return message;
  }
  // para ejecutar pruebas de alertas
  // const dataError = {
  //   msnCode: 5011
  // };
  // this.toolsService.alertErrorInformationWs(dataError, true, 'salesTeam');

  async alertErrorInformationWs(dataError: any, isShowContact: boolean = false, typeTeam: string) {
    const dataMessageError = this.constants.services.msnCode.filter((idCode) => {
      return idCode.id === dataError.msnCode;
    });
    if (dataMessageError.length > 0) {
      const dataContact = isShowContact ? this.getDataContactTeamAgendate(typeTeam) : '';
      const headerTitle = dataMessageError[0].title;
      const informationalMessage = `${dataMessageError[0].messageDescription} <br><br> ${dataContact}`;

      Swal.fire({
        title: `<span class="text-primary">${headerTitle}</span>`,
        html: `<p class="text-left">${informationalMessage}</p>`,
        icon: 'warning',
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false // permite que solo se cierre el modal con un evento o accion js
      }).then((result) => {
        if (result.isConfirmed) {
          this.validateActionBtnAlertHandler(dataError);
        }
      });
    } else {
      await this.presentToast(dataError.message.messageDescription, this.constants.iconsSweetalert.error);
    }
  }

  /**
   * @description valida los codigos de error dependiendo el codigo se navega a otra vista o se cierra el modal
   * @param dataError data del error que se presento en el web services
   */
  validateActionBtnAlertHandler(dataError: any) {
    console.log(dataError);
    // switch (dataError.msnCode) {
    //   case 5012:
    //     this.router.navigate(['/home']);
    //     break;
    //   case 5013:
    //     this.router.navigate(['/home']);
    //     break;
    // }
  }

  /**
   * @description muestra el detalle de la cita en una alerta
   * @param data data de la cita y usuario al que se le agendo la cita
   */
  alertAppointmentDetail(data: any) {
    const horaFin = (data.citas.fecha_fin) ? ` a ${moment(data.citas.fecha_fin).format('h:mm A')}` : '';
    Swal.fire({
      title: `<span class="text-primary">Detalle de la cita</span>`,
      html: `<p class="text-left">
              <strong>Cliente:</strong> ${data.usuario.nombres} ${data.usuario.apellidos}<br>
              <strong>Fecha:</strong> <span class="text-capitalize">${ this.parseDates(data.citas.fecha, 1)} ${horaFin}</span><br>
              <strong>Tipo de cita:</strong> ${data.citas.procedimientos.nombre}<br>
              <strong>Atendido por:</strong> ${data.citas.usuario_atendedor.nombres} ${data.citas.usuario_atendedor.apellidos}<br>
              <strong>Cita creada por:</strong> ${data.citas.usuario_agendador.nombres} ${data.citas.usuario_agendador.apellidos}<br>
              <strong>Nota:</strong> ${data.citas.nota}
            </p>`,
      // icon: 'warning',
      showCancelButton: false,
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false // permite que solo se cierre el modal con un evento o accion js
    }).then((result) => {
      // if (result.isConfirmed) {

      // }
    });
  }

  /**
   * @description Funcion para sacar un alert de notificacion simple recibe un titulo y mensaje con una opcion de apceptar
   * @param data se debe pasar en formato json el ejemplo es {titulo: '', mensaje: ''}
   * @param redirectUrl url a la que se va a redirigir al presionar el botón de aceptar
   * @param dataRedirectUrl data que se envia a vistas de la app, en donde se reciben al terminar un proceso el usuario es redirigido
   * a la url que se envie en este parametro, ejemplo en la vista de buscar usuario nueva cita
   * @param nameBtnAction nombre del boton que va a salir en la alerta
   * @param iconTypeOfNotification icono tipo de notificacion, posibles iconos: success, error, warning, info, question
   */
  alertNotificacionSimple(data: any, redirectUrl: string = null, dataRedirectUrl: any = {}, nameBtnAction: string = 'Aceptar', iconTypeOfNotification: any = 'success') {
    // const alert = await this.alertController.create({
    //   header: data.titulo,
    //   message: `<p class="ion-text-left">${data.mensaje}</p>`,
    //   translucent: true,
    //   animated: true,
    //   mode: 'ios',
    //   buttons: [
    //     {
    //       text: nameBtnAction,
    //       handler: () => {
    //         if (redirectUrl) {
    //           // this.router.navigate([redirectUrl, dataRedirectUrl]);
    //           this.navController.navigateForward([redirectUrl, dataRedirectUrl]);
    //         }
    //       }
    //     }
    //   ]
    // });

    Swal.fire({
      title: `<span class="text-primary">${data.titulo}</span>`,
      html: `<p class="text-left">${data.mensaje}</p>`,
      icon: iconTypeOfNotification,
      showCancelButton: false,
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      confirmButtonText: nameBtnAction,
      allowOutsideClick: false // permite que solo se cierre el modal con un evento o accion js
    }).then((result) => {
      if (result.isConfirmed) {
        if (redirectUrl) {
          this.router.navigate([redirectUrl, dataRedirectUrl]);
        }
      }
    });
  }

  /**
   * @description Reenvia notificacion de cita a cliente
   * @param data id de la cita a reenviar
   */
  forwardAppointment(data) {
    this.appointmentService.getForwardAppointment(data)
      .subscribe(response => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          this.presentToast(this.constants.appointment.notificationForward);
        } else if (typeResponse === this.constants.services.types.error) {
          this.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
        }
      }, err => {
        this.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Notificación tipo toast
   * @param message mensaje a mostrar
   * @param iconTypeOfNotification icono tipo de notificacion, posibles iconos: success, error, warning, info, question
   * @param position posicion en la que va a salir el toast 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end'.
   */
  presentToast(title: string, iconTypeOfNotification: any = 'success', timer: number = 5000, position: any = 'top-end') {
    const Toast = Swal.mixin({
      toast: true,
      position,
      showConfirmButton: false,
      timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    Toast.fire({
      icon: iconTypeOfNotification,
      title
    });
  }

  /**
   * Funcion auxiliar para visualizacion del loading cuando se ejecuten eventos que
   * requieren de consultar webservis
   * @param message, para pasarle el texto mientras esta cargando
   * @param spinner, define el tipo de spinner que se va a utilizar
   */
  // async presentLoadingNoLimit(message, spinner) {
  //   const loading = await this.loadingController.create({
  //     message: message,
  //     spinner: spinner,
  //     mode: 'ios'
  //   });
  //   await loading.present();
  //   return loading;
  // }

  /**
   * @description Realiza la pantalla de espera para la carga de informacion con opciones
   */
  // async presentLoadingWithOptions(message: string = 'Please wait...,', duration: number = 5000, spinner: any = null, translucent: boolean = true, cssClass: string = 'custom-class custom-loading') {
  //   const loading = await this.loadingController.create({
  //     spinner: spinner,
  //     duration: duration,
  //     message: message,
  //     translucent: translucent,
  //     cssClass: cssClass,
  //     mode: 'ios'
  //   });
  //   return await loading.present();
  // }

  openCall(numberPhone) {
    // this.callNumber.callNumber(numberPhone, true);
    window.open(`tel:${numberPhone}`);
  }

  messageWhatsapp(numberPhone: any) {
    window.open(`https://wa.me/57${numberPhone}`, '_blank');
  }

  // validateUrlInactivateMenu(url) {
  //   if (this.constants.inactivateUrlNavigationMenu.indexOf(url) !== -1) {
  //     return true;
  //   }
  //   return false;
  // }

  updateUserToken(token: string) {
    localStorage.setItem(this.constants.localStorage.token, token);
  }

  validateNumber(numberV: any) {
    if (!/^([0-9])*$/.test(numberV)) {
      // console.log('return false');
      return false;
    } else {
      // console.log('return true');
      return true;
    }
  }

  openPage(page: any) {
    if (page.logout) {
      this.logout();
    } else {
      // this.validateTokenRenewal();
      this.router.navigate([page.url]);
    }
  }

  // validateTokenRenewal() {
  //   this.authService.renewToken()
  //     .subscribe(response => {
  //       console.log(response);
  //       console.log(response['token']);
  //       if (response['renew']) {
  //         this.updateUserToken(response['token']);
  //       }
  //     }, err => {
  //       this.statusService(err);
  //     }, () => {
  //     });
  // }

  logout() {
    const data = { token: localStorage.getItem(this.constants.localStorage.token) };
    this.authService.logout(data)
      .subscribe(response => {
        // if (response['type'] === this.constants.services.types.success) {
          setTimeout(() => {
          this.addAndRemoveCssAdminWeb(false);
          this.clearDataRedirectLogin();
        }, 500);
        // }
      }, err => {
        this.statusService(err);
      }, () => {
      });
  }

  addAndRemoveCssAdminWeb(isAddStyle: boolean = true){
    const styleAdminWeb = document.querySelector('#styleAdminWeb');
    const themeAdminWeb = document.querySelector('#themeAdminWeb');
    // const jsCustomAdminWeb = document.querySelector('#jsCustomAdminWeb');
    if(styleAdminWeb.getAttribute('href') === '' && themeAdminWeb.getAttribute('href')) return;

    let styleAdminWebUrl = '';
    let themeAdminWebUrl = '';
    let jsCustomAdminWebUrl = '';

    if(isAddStyle){
      styleAdminWebUrl = 'assets/css/style.css';
      themeAdminWebUrl = 'assets/css/colors/default-dark.css';
      jsCustomAdminWebUrl = 'assets/js/custom.min.js';
    }

    styleAdminWeb.setAttribute('href', styleAdminWebUrl);
    themeAdminWeb.setAttribute('href', themeAdminWebUrl);
    // jsCustomAdminWeb.setAttribute('src', jsCustomAdminWebUrl);
  }

  /**
   * @description pasea fechas con momment
   * @param dateParse fecha a parsear
   * @param typeParse tipo de formato a parsear
   */
  parseDates(dateParse: any, typeParse: number) {
    // .lang.('es')
    switch (typeParse) {
      case 0:
        // valida que el formato de hora sea valido devuelve true si es valido
        return moment(dateParse, 'HH:mm').isValid();
        break;
      case 1:
        const day = moment(dateParse).locale('es').format('dddd');
        const dayMonth = moment(dateParse).format('D');
        const month = moment(dateParse).locale('es').format('MMMM');
        const year = moment(dateParse).format('YYYY');
        const hour = moment(dateParse).format('h:mm A');
        return `${day}, ${dayMonth} de ${month}. ${year} - ${hour}`;
        break;
      case 2:
        // convierte la hora en formato de 12h Ejm: 03:00 PM
        return moment(dateParse, 'HH:mm').format('hh:mm a');
        break;
      case 3:
        // devuelve el dia de la semana Ejm: Mart
        return moment(dateParse).locale('es').format('ddd');
        break;
    }
  }

  // Devuelve la ip del usuario y datos adicionales
  getIpCliente() {
    // es otra buena opcion
    // https://www.abstractapi.com/api/ip-geolocation-api
    return this.Api.getExterna(`https://ipinfo.io/json?token=${this.constants.tokens.ipinfo}`);
  }
}


