export const Constants = {
  versioApp: '1.0.1',
  urlAppGooglePlay: 'https://play.google.com/store/apps/details?id=com.agendateapp.agendateapp',
  urlAppAppleStore: '',
  idOrganizacionAgendate: '5eadcc4f3b6a8b061510f9d3',
  tokens:{
    ipinfo: 'ffa95d7d57c22f'
  },
  origenNewCita: ['app_movil', 'app_web', 'autoasignacion_web'],
  contactAgendate: {
    salesTeam: {
      nameKeyTeam: 'salesTeam',
      email: 'administracion@agendateapp.com',
      cellPhoneNumber: 3166232885,
      whatsapp: 3052898203
    },
    supportTeam: {
      nameKeyTeam: 'supportTeam',
      email: 'administracion@agendateapp.com',
      cellPhoneNumber: 3166232885,
      whatsapp: 3052898203
    }
  },
  socialMedia: {
    instagram: 'https://www.instagram.com/agendate.app/?hl=es-la',
    facebook: 'https://www.facebook.com/app.agendate',
    whatsapp: 'https://walink.co/674ed7',
    youtube: 'https://www.youtube.com/channel/UC7GZlIki9XObWMAPnef0q0Q/',
    twitter: '',
    linkedin: 'https://www.linkedin.com/company/agendate-app'
  },
  colors:{
    primary: '#1049A2',
    info: '#1049A2'
  },
  accountMenu: [
    // {
    //   title: 'Mi perfil',
    //   url: '/',
    //   icon: 'ti-user',
    //   logout: false
    // },
    {
      title: 'Mi plan actual',
      url: '/admin/cuenta/plan',
      icon: 'mdi mdi-clipboard-text',
      logout: false
    },
    {
      title: 'Cerrar sesión',
      url: '/cuenta/ingresar',
      icon: 'fa fa-power-off',
      logout: true
    }
  ],
  state: {
    active: 'activo',
    inactive: 'inactivo',
    delete: 'eliminar'
  },
  stateAppointments:{
    agendada: 'agendada',
    confirmada: 'confirmada',
    cancelada: 'cancelada'
  },
  roles: {
    administrador: 'administrador',
    asistente: 'asistente',
    atiendecitas: 'atiendecitas',
    paciente: 'paciente'
  },
  users: {
    titleCreated: 'Crear usuario',
    titleUpdate: 'Editar usuario',
    notificationCreated: 'Usuario creado.',
    notificationUpdate: 'Usuario editado.',
    notificationDelete: 'Usuario eliminado.',
    types: {
      patient: 'paciente',
      attendAppointments: 'atiendecitas',
      admin: 'administrador',
    }
  },
  procedures: {
    titleCreated: 'Crear procedimiento',
    titleUpdate: 'Editar procedimiento',
    notificationCreated: 'procedimiento creado.',
    notificationUpdate: 'procedimiento editado.',
    notificationDelete: 'procedimiento eliminado.'
  },
  appointment: {
    titleNewAppointment: 'Nueva cita',
    titleUpdateAppointment: 'Editar cita',
    notificationCreated: 'Cita creada.',
    notificationUpdate: 'Cita editada.',
    notificationCancel: 'Cita cancelada.',
    notificationForward: 'Cita reenviada.',
  },
  agenda: {
    notificationCreated: 'Agenda creada.',
    notificationUpdate: 'Agenda editada.',
  },
  timeProcedures: [
    { label: '15 Min', value: 15 },
    { label: '20 Min', value: 20 },
    { label: '30 Min', value: 30 },
    { label: '40 Min', value: 40 },
    { label: '45 Min', value: 45 },
    { label: '1 Hora', value: 60 },
    { label: '1 Hora 15 Min', value: 75 },
    { label: '1 Hora 30 Min', value: 90 },
    { label: '1 Hora 45 Min', value: 105 },
    { label: '2 Horas', value: 120 },
    { label: '2 Horas 15 Min', value: 135 },
    { label: '2 Horas 30 Min', value: 150 },
    { label: '2 Horas 45 Min', value: 165 },
    { label: '3 Horas', value: 180 },
    { label: '3 Horas 15 Min', value: 195 },
    { label: '3 Horas 30 Min', value: 210 },
    { label: '3 Horas 45 Min', value: 225 },
    { label: '4 Horas', value: 240 },
    { label: '4 Horas 15 Min', value: 255 },
    { label: '4 Horas 30 Min', value: 270 },
    { label: '4 Horas 45 Min', value: 285 },
    { label: '5 Horas', value: 300 },
    { label: '5 Horas 15 Min', value: 315 },
    { label: '5 Horas 30 Min', value: 330 },
    { label: '5 Horas 45 Min', value: 345 },
    { label: '6 Horas', value: 360 },
    { label: '6 Horas 15 Min', value: 375 },
    { label: '6 Horas 30 Min', value: 390 },
    { label: '6 Horas 45 Min', value: 405 },
    { label: '7 Horas', value: 420 }
  ],
  filtersDate:[
    { label: 'Hoy', value: 0 },
    { label: 'Ayer', value: 1 },
    { label: 'Últimos 7 días', value: 7 },
    { label: 'Últimos 30 días', value: 30 },
  ],
  verificationCodePhoneNumber: {
    minAndMaxCode: 'El código de verificación debe ser de 6 dígitos.'
  },
  inactivateUrlNavigationMenu: ['/', undefined, '/login', '/registeraccount'],
  localStorage: {
    isSession: 'isSession',
    token: 'token',
    user: 'user',
    initAppSlider: 'initAppSlider',
  },
  tooltips:{
    btns: {
      actions: 'Ver acciones',
      appointments:{
        create: 'Crear nueva cita',
      },
      users:{
        create: 'Crear nuevo usuario',
      },
      procedures:{
        create: 'Crear nuevo procedimiento',
      }
    }
  },
  validationMessages: {
    isNAn: [
      { type: 'invalid', message: 'El campo debe ser numérico.' }
    ],
    tipoRol: [
      { type: 'required', message: 'El tipo de usuario es obligatorio.' },
    ],
    nombres: [
      { type: 'required', message: 'El nombre es obligatorio.' },
      { type: 'minlength', message: 'El nombre debe de tener minimo 4 caracteres.' },
      { type: 'maxlength', message: '' },
      { type: 'pattern', message: '' }
    ],
    email: [
      { type: 'required', message: 'El correo electrónico es obligatorio.' },
      { type: 'email', message: 'Por favor ingrese un correo electrónico válido.' }
    ],
    tipoIdentificacion: [
      { type: 'required', message: 'El tipo de identificación es obligatorio.' },
    ],
    identificacion: [
      { type: 'required', message: 'El numero de identificación es obligatorio.' },
    ],
    password: [
      { type: 'required', message: 'La contraseña es obligatoria.' },
      { type: 'minlength', message: 'La contraseña debe tener al menos 5 caracteres.' },
      { type: 'pattern', message: 'Su contraseña debe contener al menos una mayúscula, una minúscula y un número.' }
    ],
    confirm_password: [
      { type: 'required', message: 'La contraseña de confirmación es obligatoria.' }
    ],
    matching_passwords: [
      { type: 'areEqual', message: 'Las contraseñas no coinciden.' }
    ],
    celulares: [
      { type: 'required', message: 'El numero de celular es obligatorio.' },
      { type: 'minlength', message: 'El numero de celular debe de ser de 10 caracteres.' },
      { type: 'maxlength', message: 'El numero de celular debe de ser de 10 caracteres.' }
    ],
    telefonos: [
      { type: 'required', message: 'El numero de télefono fijo es obligatorio.' },
      { type: 'minlength', message: 'El numero de télefono fijo debe de ser de 7 caracteres.' },
      { type: 'maxlength', message: 'El numero de télefono fijo debe de ser de 7 caracteres.' }
    ],
    nombreEmpresa: [
      { type: 'required', message: 'La dirección de la empresa es obligatoria.' },
    ],
    nitEmpresa: [
      { type: 'required', message: 'La dirección de la empresa es obligatoria.' },
    ],
    direccionEmpresa: [
      { type: 'required', message: 'La dirección de la empresa es obligatoria.' },
    ],
    tipoServicioEmpresa: [
      { type: 'required', message: 'El tipo de servicio es obligatorio.' },
    ],
    tipoDePlan: [
      { type: 'required', message: 'El tipo de plan es obligatorio.' },
    ],
    usuarioAtendedor: [
      { type: 'required', message: 'Quien atiende la cita es obligatorio.' },
    ],
    tipoCita: [
      { type: 'required', message: 'Tipo de cita es obligatoria.' },
    ],
    fecha: [
      { type: 'required', message: 'La fecha es obligatoria.' },
    ],
    fechaHora: [
      { type: 'required', message: 'La hora es obligatoria.' },
    ],
    tiempoEstimado: [
      { type: 'required', message: 'El tiempo estimado es obligatorio.' },
      { type: 'maxlength', message: 'El tiempo máximo no debe superar los 3 caracteres.' },
      { type: 'pattern', message: 'No se permiten caracteres especiales.' }
    ],
    nota: [
      { type: 'required', message: 'La nota es obligatoria.' },
    ],
    descriptionCancelAppointment: [
      { type: 'required', message: 'El motivo de la cancelación es obligatorio.' },
    ],
    code: [
      { type: 'required', message: 'El código de de recuperación es obligatorio.' },
    ],
    requiredHour: [
      { type: 'required', message: 'La hora es obligatoria.' }
    ],
    startTimeIsInvalid: [
      { type: 'required', message: 'La hora de inicio del turno debe de ser menor a la hora final del turno.' }
    ],
    endTimeIsInvalid: [
      { type: 'required', message: 'La hora de inicio del segundo turno debe de ser mayor a la hora final del primer turno.' }
    ],
    agendaUserOrOrganization: [
      { type: 'required', message: 'La empresa o personal encargado de atender las citas/reservas es obligatoria.' }
    ]
  },
  iconsSweetalert: {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
    question: 'question'
  },
  loading: {
    spinner: 'bubbles',
    messages: {
      login: 'Iniciando sesión',
      createdUser: 'Creando usuario',
      editUser: 'Editando usuario',
      createdAppointment: 'Creando cita',
      updatedAppointment: 'Editando cita',
      createdProcedure: 'Creando procedimiento',
      updatedProcedure: 'Editando procedimiento',
      createBusinessHours: 'Guardando horarios de atención'
    }
  },
  mensajesInformativos: {
    cuentaCreada: {
      titulo: 'Registro exitoso!',
      mensaje: 'Enviamos un correo electrónico para confirmar tu cuenta, cuando la confirmes podrás iniciar sesión y disfrutar de los beneficios que Agéndate App tiene para ti y tu empresa!!!'
    },
    notificationEmailRecoverPassword: {
      titulo: 'Código de recuperación',
      mensaje: `Enviamos un código de recuperación a tu email para restablecer tu contraseña. <br><br>
      Diligencia el formulario de <strong>Restablecer mi contraseña</strong> y continua disfrutando de los servicios de Agéndate App.`
    },
    notificationChangePasswordSuccess: {
      titulo: '¡Contraseña cambiada!',
      mensaje: `Tu contraseña ha sido cambiada correctamente, ya puedes iniciar sesión y continuar
      disfrutando de los servicios de Agéndate App.`
    },
    sinInternet: {
      titulo: 'Sin internet',
      mensaje: 'No se ha detectado ninguna conexión a internet, por favor verifique su conexión y vuelva a intentarlo.'
    },
    noUsersAttendAppointments: {
      titulo: '¡Atención!',
      mensaje: `Crea un nuevo usuario <strong>atiende citas</strong> o verifica el estado de tus usuarios.`
    },
    noUsersAttendAppointmentsNewAppointment: {
      titulo: '¡Atención!',
      mensaje: `Para crear una cita se necesita un usuario <strong>atiende citas o administrador</strong> verifica que tus usuarios tengan un tipo de procedimiento asignado y el estado sea activo o crea un nuevo usuario de tipo atiende citas y asígnale los tipos de procedimientos que atiende.`
    },
    noUsersAttendAppointmentsNewAppointmentClient: {
      titulo: '¡Atención!',
      mensaje: `En el momento no hay usuarios que atienden el tipo de cita seleccionada.`
    },
    fechaMenorAgendarCita: {
      titulo: 'Fecha y hora incorrecta',
      mensaje: 'La fecha y hora ingresada para la cita debe ser posterior a la actual.'
    },
    noHayCitasDisponibles: {
      titulo: 'No hay citas disponibles',
      mensaje: 'No hay citas disponibles para la fecha seleccionada.'
    },
    autoAgendamientoCitaCreada: {
      titulo: 'Gracias por confiar en nosotros',
      mensaje: 'Tu agendamiento ha sido diligenciado exitosamente.<br>Pronto recibirás un correo electrónico o mensaje de texto confirmando los detalles de tu cita.'
    },
  },
  skeletonList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  services: {
    types: {
      success: 'success',
      error: 'error'
    },
    msnCode: [
      {
        id: 5005,
        title: '',
        messageDescription: 'No se ha encontrado este usuario, no se encuentra registrado'
      },
      {
        id: 5006,
        title: 'Usuario inactivo',
        messageDescription: 'El usuario no se encuentra activo, contáctanos para poder ayudarte.'
      },
      {
        id: 5010,
        title: 'Organización inactiva',
        messageDescription: 'La organización no se encuentra activa, contáctanos para poder ayudarte.'
      },
      {
        id: 5011,
        title: 'Límite alcanzado',
        messageDescription: 'Has creado todos los usuarios atiende citas disponibles en tu plan.<br><br>Adiciona más usuarios a tu plan para que sigan gestionando tus citas.<br><br>Contáctanos.'
      },
      {
        id: 5012,
        title: 'Verifica tu número de celular',
        messageDescription: 'Verifica el número celular principal de la cuenta para poder crear citas y notificarle a tus clientes.'
      },
      {
        id: 5013,
        title: 'Límite de notificaciones',
        messageDescription: 'Has usado todas las notificaciones de tu plan, se seguirán enviando pero tendrán un cobro adicional.<br><br>Contáctanos.'
      },
      {
        id: 5014,
        title: 'Límite de notificaciones',
        messageDescription: 'Has usado todas las notificaciones de tu plan, Adiciona más notificaciones a tu plan para que sigas comunicándote con tus clientes.<br><br>Contáctanos.'
      },
      {
        id: 5015,
        title: 'Plan vencido',
        messageDescription: 'Su plan se encuentra vencido, por favor ponte en contacto con el equipo de ventas para activar el plan.'
      }
    ]
  },
  metaDataModulos: {
    compWebAgendate: {
      title: 'Agenda y notifica tus citas o eventos.',
      description: 'Con Agéndate App: Notifica tus citas y eventos. En los canales de comunicación que usan tus clientes, Optimiza procesos y Mejora la experiencia de tus clientes.',
      keywords: 'citas, agendar cita, agendamiento de citas, app de citas, recordatorio de citas, crear cuenta, agenda online,',
      image: 'https://agendateapp.com/assets/img/metadatos/agendateapp_share.jpg'
    }
  },
  gtm: {
    css: {
      contact: 'gtm-contactar',
      interestInPlanGratis: 'gtm-interes-plan-gratis',
      interestInPlanBasic: 'gtm-interes-plan-basic',
      interestInPlanStandard: 'gtm-interes-plan-standard',
      interestInPlanPremium: 'gtm-interes-plan-premium',
      registrationStep1: 'gtm-registration-step1',
      registrationStep2: 'gtm-registration-step2',
      registrationAccountThanks: 'gtm-registration-account-thanks',
    }
  }
};
