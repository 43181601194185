import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { UserService } from '../../../../services/user.service';
import { I18nService } from '../../../../services/i18n.service';
import { CustomDatepickerI18nService } from '../../../../services/custom.datepickerI18n.Service';
import { Constants } from '../../../../../providers/constants/constants';
import { ProcedureService } from '../../../../services/procedure.service';
import { ToolsService } from '../../../../services/tools.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { AppointmentService } from '../../../../services/appointment.service';
import { Subscription } from 'rxjs';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-gestionar-citas',
  templateUrl: './gestionar-citas.component.html',
  styleUrls: ['./gestionar-citas.component.scss'],
  styles: [`
    /* .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    } */
    /* .custom-day.focused {
      background-color: #e6e6e6;
    } */
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `],
  providers: [I18nService, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }] // define custom NgbDatepickerI18n provider
})
export class GestionarCitasComponent implements OnInit, OnDestroy {
  moment = moment;
  constants = Constants;
  @ViewChild('staticTabsTypeAppointment', { static: false }) staticTabsTypeAppointment: TabsetComponent;
  dashboardSubs: Subscription;
  usersSearchSubs: Subscription;
  usersSearchAppointmentCheduledSubs: Subscription;
  usersSearchAppointmentConfirmedSubs: Subscription;
  usersSearchAppointmentCanceledSubs: Subscription;
  validationsForm: FormGroup;
  tipoServicio = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones.tipo_servicio;
  procedures: any = [];
  users: any = [];
  // Data de las citas y usuarios con cita, paginadores
  isSearchUsersChangeScheduled: boolean = false;
  pageSkipScheduled: number = 0;
  pageSizeScheduled: number = 15; // 50;
  pageSkipConfirmed: number = 0;
  pageSizeConfirmed: number = 15; // 50;
  pageSkipCanceled: number = 0;
  pageSizeCanceled: number = 15; // 50;
  dataDashboard: any = {
    scheduled: 0,
    confirmed: 0,
    canceled: 0
  };
  appointmentsScheduled: any = [];
  appointmentsConfirmed: any = [];
  appointmentsCanceled: any = [];
  // data de calendario de fechas
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    private userService: UserService,
    private procedureService: ProcedureService,
    private dashboardService: DashboardService,
    public toolsService: ToolsService,
    private appointmentService: AppointmentService,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
  }

  ngOnInit(): void {
    this.validatorsFormGetAppointments();
    this.initDatesCalendar();
    this.getProcedure();
    this.getUsersSearch();
    // pre selecciono el primer registro
    this.validationsForm.patchValue({fechaCita: this.fromDate});
  }

  ngOnDestroy() {
    try {
      this.usersSearchSubs?.unsubscribe();
      this.dashboardSubs?.unsubscribe();
      this.usersSearchAppointmentCheduledSubs?.unsubscribe();
      this.usersSearchAppointmentConfirmedSubs?.unsubscribe();
      this.usersSearchAppointmentCanceledSubs?.unsubscribe();
    } catch (error) {
      console.log('Error en el ngOnDestroy GestionarCitasComponent');
      console.log(error);
    }
  }

  /**
   * @description con esta funcion calculo el dia inicial y dia final del mes actual para activar la busqueda en el calendario
   */
  initDatesCalendar(){
    const year = parseInt(moment().startOf('month').format('YYYY'));
    const month = parseInt(moment().startOf('month').format('MM'));
    const initialDay = parseInt(moment().startOf('month').format('DD'));
    const finalDay = parseInt(moment().endOf('month').format('DD'));
    this.fromDate = new NgbDate(year, month, initialDay);
    this.toDate = new NgbDate(year, month, finalDay);
  }

  validatorsFormGetAppointments() {
    this.validationsForm = this.formBuilder.group({
      tipoCita: [''],
      usuarioAtendedor: ['', [Validators.required]],
    });
  }

  /**
   * @description Se hace llamado al web services que busca los tipos de procedimiento de la empresa
   */
  getProcedure() {
    const data = JSON.stringify({ tipos: [this.tipoServicio], estados: 'activo' });
    this.procedureService.getProcedureSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.procedures = response.data;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se hace llamado al web services que busca usuarios de la cuenta
   */
  getUsersSearch() {
    this.users = [];
    this.validationsForm.get('usuarioAtendedor').reset();
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const data = { usuarios: { estados: 'activo', roles: ['atiendecitas', 'administrador'] } };
    // si seleccionan un tipo de cita se agrega propiedad procedimientos para filtrar usuarios con este filtro procedimientos
    if(tipoCita !== '' && tipoCita !== null){
      data.usuarios['procedimientos'] = tipoCita;
    }

    this.usersSearchSubs = this.userService.getUsersSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          if (response.data.length > 0) {
            this.users = response.data;
            // pre selecciono el primer registro
            this.validationsForm.patchValue({usuarioAtendedor: this.users[0]._id});
            this.getDataAppointments();
          } else {
            // se notifica al usuario que no existen usuarios atiende citas y se manda a crear usuario
            this.toolsService.presentToast(this.constants.mensajesInformativos.noUsersAttendAppointmentsNewAppointmentClient.mensaje, this.constants.iconsSweetalert.warning);
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.getDataAppointments();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  // onDateSelection(date: NgbDate) {
  //   if (!this.fromDate && !this.toDate) {
  //     console.log('ingrese if');
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
  //     console.log('ingrese else if');
  //     this.toDate = date;
  //   } else {
  //     console.log('ingrese else');
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  //   this.getDataAppointments();
  // }

  // isHovered(date: NgbDate) {
  //   return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  // }

  // isInside(date: NgbDate) {
  //   return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  //   return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  // }

  goToViewCreateAndEditAppointment(dataAppointment: any = null) {
    let data = {};
    let urlRedirect = '/admin/citas/crear';
    if (dataAppointment) {
      data = {
        _id: dataAppointment.usuario._id,
        nombres: dataAppointment.usuario.nombres,
        apellidos: dataAppointment.usuario.apellidos,
        identificacion: dataAppointment.usuario.identificacion,
        _idAppointment: dataAppointment.citas._id,
        procedimientos: dataAppointment.citas.procedimientos._id,
        usuario_atendedor: dataAppointment.citas.usuario_atendedor._id,
        fecha: dataAppointment.citas.fecha,
        nota: dataAppointment.citas.nota
      };
      urlRedirect = '/admin/citas/editar';
    }
    this.router.navigate([urlRedirect, data]);
  }

  getDateCalendarFormat(date: any) {
    if (date === null) return '';
    const ano = date.year;
    const mes = date.month <= 9 ? `0${date.month}` : date.month;
    const dia = date.day <= 9 ? `0${date.day}` : date.day;
    return `${ano}-${mes}-${dia}`;
  }

  async getDataAppointments() {
    this.staticTabsTypeAppointment.tabs[0].active = true; // activo el primer tab por default
    this.appointmentsScheduled = [];
    this.appointmentsConfirmed = [];
    this.appointmentsCanceled = [];
    this.pageSkipScheduled = 0;
    this.pageSkipConfirmed = 0;
    this.pageSkipCanceled = 0;
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const tipoDeCitas = (tipoCita === '') ? [] : [tipoCita];
    const usuarioAtendedor = this.validationsForm.get('usuarioAtendedor').value;
    if (usuarioAtendedor === '' || usuarioAtendedor === null) return;
    const dateStart = moment(this.getDateCalendarFormat(this.fromDate)).format('YYYY-MM-DD');
    // const dateStart = moment('2018-01-01').format('YYYY-MM-DD');
    const dateHourStart = moment('000', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampStart = moment(`${dateStart}T${dateHourStart}`).valueOf();

    const toDate = (this.toDate !== null) ? this.toDate : this.fromDate;
    const dateEnd = moment(this.getDateCalendarFormat(toDate)).format('YYYY-MM-DD');
    // const dateEnd = moment('2021-04-01').format('YYYY-MM-DD');
    const dateHourEnd = moment('2359', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampEnd = moment(`${dateEnd}T${dateHourEnd}`).valueOf();
    const data = JSON.stringify(
      {
        procedimientos: tipoDeCitas,
        atendedor: usuarioAtendedor,
        fecha_ini: dateTimestampStart,
        fecha_fin: dateTimestampEnd,
        data: false,
      }
    );
    this.dashboardSubs = await this.dashboardService.getDashboardInfo(data)
    // this.dashboardSubs = this.userService.getUsersSearchAppointment(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.dataDashboard.scheduled = response.data.scheduled;
          this.dataDashboard.confirmed = response.data.confirmed;
          this.dataDashboard.canceled = response.data.canceled;
          // llamo las citas confirmadas
          this.getUsersSearchAppointmentScheduled();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se hace llamado al web services que busca usuarios tipo cliente que tengan citas por confirmar
   * asignadas a un atiende citas y tipo de cita
   */
  async getUsersSearchAppointmentScheduled(event = null) {
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const tipoDeCitas = (tipoCita === '') ? [] : [tipoCita];
    const usuarioAtendedor = this.validationsForm.get('usuarioAtendedor').value;
    const dateStart = moment(this.getDateCalendarFormat(this.fromDate)).format('YYYY-MM-DD');
    // const dateStart = moment('2018-01-01').format('YYYY-MM-DD');
    const dateHourStart = moment('000', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampStart = moment(`${dateStart}T${dateHourStart}`).valueOf();

    const toDate = (this.toDate !== null) ? this.toDate : this.fromDate;
    const dateEnd = moment(this.getDateCalendarFormat(toDate)).format('YYYY-MM-DD');
    // const dateEnd = moment('2021-04-01').format('YYYY-MM-DD');
    const dateHourEnd = moment('2359', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampEnd = moment(`${dateEnd}T${dateHourEnd}`).valueOf();

    // console.log(moment(dateTimestampStart).format('YYYY-MM-DD HH:mm'));
    // console.log(moment(dateTimestampEnd).format('YYYY-MM-DD HH:mm'));

    const data = JSON.stringify(
      {
        usuarios: {
          estados: ['activo'],
          roles: ['paciente'],
          // texto: this.searchUsers
        },
        citas: {
          estados: ['agendada'],
          fecha_ini: dateTimestampStart,
          fecha_fin: dateTimestampEnd,
          procedimientos: tipoDeCitas,
          usuario_atendedor: usuarioAtendedor
        },
        paginador: {
          limit: {
            skip: this.pageSkipScheduled,
            size: this.pageSizeScheduled
          },
          order: {
            'citas.fecha': -1
          }
        }
      });
    this.usersSearchAppointmentCheduledSubs = await this.userService.getUsersSearchAppointment(data)
      .subscribe((response: any) => {
        this.isSearchUsersChangeScheduled = true;
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.appointmentsScheduled = this.appointmentsScheduled.concat(response.data);
          this.pageSkipScheduled = this.appointmentsScheduled.length;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        } else if (event && typeResponse === this.constants.services.types.success && lengthData === 0) {
          this.toolsService.presentToast('No se encontraron más citas', this.constants.iconsSweetalert.info);
        }
        // else if (event === null) {
        //   this.appointmentsScheduled = null;
        // }
        // si es llamado desde el evento del infinite-scroll
        if (event) {
          event.target.complete();
          // this.ionInfiniteScroll.disabled = false;
        }
        this.usersSearchAppointmentCheduledSubs?.unsubscribe();
      }, err => {
        this.toolsService.statusService(err);
        this.usersSearchAppointmentCheduledSubs?.unsubscribe();
      }, () => {
      });
  }

  /**
   * @description se activa en el tab de citas y solo se llama si no existen registros en array this.appointmentsConfirmed
   */
  getAppointmentConfirmed(){
    if(this.appointmentsConfirmed.length === 0){
      this.getUsersSearchAppointmentConfirmed();
    }
  }

  /**
   * @description Se hace llamado al web services que busca usuarios tipo cliente que tengan citas confirmadas
   * asignadas a un atiende citas y tipo de cita
   */
  async getUsersSearchAppointmentConfirmed(event = null) {
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const tipoDeCitas = (tipoCita === '') ? [] : [tipoCita];
    const usuarioAtendedor = this.validationsForm.get('usuarioAtendedor').value;
    const dateStart = moment(this.getDateCalendarFormat(this.fromDate)).format('YYYY-MM-DD');
    // const dateStart = moment('2018-01-01').format('YYYY-MM-DD');
    const dateHourStart = moment('000', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampStart = moment(`${dateStart}T${dateHourStart}`).valueOf();

    const toDate = (this.toDate !== null) ? this.toDate : this.fromDate;
    const dateEnd = moment(this.getDateCalendarFormat(toDate)).format('YYYY-MM-DD');
    // const dateEnd = moment('2021-04-01').format('YYYY-MM-DD');
    const dateHourEnd = moment('2359', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampEnd = moment(`${dateEnd}T${dateHourEnd}`).valueOf();

    // console.log(moment(dateTimestampStart).format('YYYY-MM-DD HH:mm'));
    // console.log(moment(dateTimestampEnd).format('YYYY-MM-DD HH:mm'));

    const data = JSON.stringify(
      {
        usuarios: {
          estados: ['activo'],
          roles: ['paciente'],
          // texto: this.searchUsers
        },
        citas: {
          estados: ['confirmada'],
          fecha_ini: dateTimestampStart,
          fecha_fin: dateTimestampEnd,
          procedimientos: tipoDeCitas,
          usuario_atendedor: usuarioAtendedor
        },
        paginador: {
          limit: {
            skip: this.pageSkipConfirmed,
            size: this.pageSizeConfirmed
          },
          order: {
            'citas.fecha': -1
          }
        }
      });
    this.usersSearchAppointmentConfirmedSubs = await this.userService.getUsersSearchAppointment(data)
      .subscribe((response: any) => {
        this.isSearchUsersChangeScheduled = true;
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.appointmentsConfirmed = this.appointmentsConfirmed.concat(response.data);
          this.pageSkipConfirmed = this.appointmentsConfirmed.length;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        } else if (event && typeResponse === this.constants.services.types.success && lengthData === 0) {
          this.toolsService.presentToast('No se encontraron más citas', this.constants.iconsSweetalert.info);
        }
        // else if (event === null) {
        //   this.appointmentsConfirmed = null;
        // }
        // si es llamado desde el evento del infinite-scroll
        if (event) {
          event.target.complete();
          // this.ionInfiniteScroll.disabled = false;
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description se activa en el tab de citas y solo se llama si no existen registros en array this.appointmentsConfirmed
   */
   getAppointmentCanceled(){
    if(this.appointmentsCanceled.length === 0){
      this.getUsersSearchAppointmentCanceled();
    }
  }

  /**
   * @description Se hace llamado al web services que busca usuarios tipo cliente que tengan citas canceladas
   * asignadas a un atiende citas y tipo de cita
   */
  async getUsersSearchAppointmentCanceled(event = null) {
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const tipoDeCitas = (tipoCita === '') ? [] : [tipoCita];
    const usuarioAtendedor = this.validationsForm.get('usuarioAtendedor').value;
    const dateStart = moment(this.getDateCalendarFormat(this.fromDate)).format('YYYY-MM-DD');
    // const dateStart = moment('2018-01-01').format('YYYY-MM-DD');
    const dateHourStart = moment('000', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampStart = moment(`${dateStart}T${dateHourStart}`).valueOf();

    const toDate = (this.toDate !== null) ? this.toDate : this.fromDate;
    const dateEnd = moment(this.getDateCalendarFormat(toDate)).format('YYYY-MM-DD');
    // const dateEnd = moment('2021-04-01').format('YYYY-MM-DD');
    const dateHourEnd = moment('2359', 'hmm').format('HH:mm:ss.SSS');
    const dateTimestampEnd = moment(`${dateEnd}T${dateHourEnd}`).valueOf();

    // console.log(moment(dateTimestampStart).format('YYYY-MM-DD HH:mm'));
    // console.log(moment(dateTimestampEnd).format('YYYY-MM-DD HH:mm'));

    const data = JSON.stringify(
      {
        usuarios: {
          estados: ['activo'],
          roles: ['paciente'],
          // texto: this.searchUsers
        },
        citas: {
          estados: ['cancelada'],
          fecha_ini: dateTimestampStart,
          fecha_fin: dateTimestampEnd,
          procedimientos: tipoDeCitas,
          usuario_atendedor: usuarioAtendedor
        },
        paginador: {
          limit: {
            skip: this.pageSkipCanceled,
            size: this.pageSizeCanceled
          },
          order: {
            'citas.fecha': -1
          }
        }
      });
    this.usersSearchAppointmentCanceledSubs = await this.userService.getUsersSearchAppointment(data)
      .subscribe((response: any) => {
        this.isSearchUsersChangeScheduled = true;
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.appointmentsCanceled = this.appointmentsCanceled.concat(response.data);
          this.pageSkipCanceled = this.appointmentsCanceled.length;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        } else if (event && typeResponse === this.constants.services.types.success && lengthData === 0) {
          this.toolsService.presentToast('No se encontraron más citas', this.constants.iconsSweetalert.info);
        }
        // else if (event === null) {
        //   this.appointmentsCanceled = null;
        // }
        // si es llamado desde el evento del infinite-scroll
        if (event) {
          event.target.complete();
          // this.ionInfiniteScroll.disabled = false;
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se abre ventana modal para mostrar detalle de cita con opcion de cancelar cita
   */
   openModalCancelAppointment(dataAppointment: any, statusAppointment: string) {
    const horaFin = (dataAppointment.citas.fecha_fin) ? ` a ${moment(dataAppointment.citas.fecha_fin).format('h:mm A')}` : '';
    Swal.fire({
      title: `<span class="text-primary">Detalle de la cita</span>`,
      html: `<p class="text-left">
              <strong>Cliente:</strong> ${dataAppointment.usuario.nombres} ${dataAppointment.usuario.apellidos}<br>
              <strong>Fecha:</strong> <span class="text-capitalize">${ this.toolsService.parseDates(dataAppointment.citas.fecha, 1)} ${horaFin}</span><br>
              <strong>Tipo de cita:</strong> ${dataAppointment.citas.procedimientos.nombre}<br>
              <strong>Atendido por:</strong> ${dataAppointment.citas.usuario_atendedor.nombres} ${dataAppointment.citas.usuario_atendedor.apellidos}<br>
              <strong>Cita creada por:</strong> ${dataAppointment.citas.usuario_agendador.nombres} ${dataAppointment.citas.usuario_agendador.apellidos}<br>
              <strong>Nota:</strong> ${dataAppointment.citas.nota}
            </p>`,
      // icon: 'success',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      confirmButtonText: 'Cancelar cita',
      allowOutsideClick: false, // permite que solo se cierre el modal con un evento o accion js
      input: 'textarea',
      inputLabel: 'Motivo de cancelación',
      inputValue: '',
      inputValidator: (value) => {
        const messageCancelAppointment = value;
        if (messageCancelAppointment === '') {
          return this.constants.validationMessages.descriptionCancelAppointment[0].message;
        } else {
          this.cancelAppointment(dataAppointment.citas._id, messageCancelAppointment, statusAppointment);
        }
      }
    });
  }

  /**
  * @description Se hace llamado al web services que cancela la cita
  */
  cancelAppointment(idAppointmentCancel: string ,messageCancelAppointment: string, statusAppointment:string) {
    const data = { nota: messageCancelAppointment };
    this.appointmentService.putCancelAppointment(idAppointmentCancel, data)
      .subscribe(response => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          this.toolsService.presentToast(this.constants.appointment.notificationCancel);
          // valido el estado desde el que se llamo para llamar de nuevo al servicio y refrescar los datos
          switch (statusAppointment) {
            case this.constants.stateAppointments.agendada:
              this.getUsersSearchAppointmentScheduled();
              break;
            case this.constants.stateAppointments.confirmada:
              this.getUsersSearchAppointmentConfirmed();
              break;
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          //  se valida desde que estado fue llamado para eliminar el objeto del array de citas por estado de cita
          switch (statusAppointment) {
            case this.constants.stateAppointments.agendada:
              this.appointmentsScheduled = this.appointmentsScheduled.filter(appointment => appointment.citas._id !== idAppointmentCancel);
              break;
            case this.constants.stateAppointments.confirmada:
              this.appointmentsConfirmed = this.appointmentsConfirmed.filter(appointment => appointment.citas._id !== idAppointmentCancel);
              break;
            case this.constants.stateAppointments.cancelada:
              this.appointmentsCanceled = this.appointmentsCanceled.filter(appointment => appointment.citas._id !== idAppointmentCancel);
              break;
          }
          setTimeout(() => {
            this.toolsService.presentToast('No se pudo notificar al cliente, pero si se cancelo la cita, adquiere más notificaciones.', 7000);
          }, 3000);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }
}
