<div class="row">
  <div class="col-12">
    <h6>Notificaciones</h6>
  </div>
  <!-- <div class="col-6 col-lg-3">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class=""><span class="lstick"></span></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Tipo de plan</h6>
                      <h3 class="m-t-0">Pruebas</h3></div>
              </div>
          </div>
      </div>
  </div> -->
  <div class="col-6 col-lg-3">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class="m-r-20 align-self-center"><span class="lstick bg-success m-r-20"></span><i class="text--lgxs ti-comment"></i></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Disponibles</h6>
                      <h3 class="m-t-0">{{ dataPlanDetailOrganization?.plan_vigencia.notificaciones_disponibles }}</h3></div>
              </div>
          </div>
      </div>
  </div>
  <div class="col-6 col-lg-3">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class="m-r-20 align-self-center"><span class="lstick bg-danger m-r-20"></span><i class="text--lgxs ti-comment-alt"></i></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Consumidas</h6>
                      <h3 class="m-t-0">{{ dataPlanDetailOrganization?.plan_vigencia.notificaciones_consumidas }}</h3></div>
              </div>
          </div>
      </div>
  </div>
  <div class="col-6 col-lg-3">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class="m-r-20 align-self-center"><span class="lstick bg-warning m-r-20"></span><i class="text--lgxs ti-comments"></i></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Sobre cupo</h6>
                      <h3 class="m-t-0">{{ dataPlanDetailOrganization?.plan_vigencia.notificaciones_consumidas_sobrecupo }}</h3></div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-6">
      <div class="card">
            <div class="card-body pb-0">
              <h5 class="card-title fs-5">Tipo de plan</h5>
              <h6 class="card-subtitle mt-2 mb-0 fw-normal">{{ dataPlanDetail?.nombre }}</h6>
              <!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="float-left text-left"><span class="text-primary">Fecha de inicio</span><br><span class="text-muted">{{ parseDatePlan(dataPlanDetailOrganization?.plan_vigencia.fecha_inicio) }}</span></span>
                <span class="float-right text-right"><span class="text-primary">Próximo corte</span><br><span class="text-muted">{{ parseDatePlan(dataPlanDetailOrganization?.plan_vigencia.fecha_fin) }}</span></span>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                <div class="col-1"><i class="ti-comment text-primary"></i></div>
                <div class="col-9"><span class="text-primary">Total de notificaciones</span></div>
                <div class="col-2 text-right">{{ dataPlanDetailOrganization?.plan_vigencia.notificaciones_totales }}</div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-1"><i class="ti-comments text-primary"></i></div>
                  <div class="col-9"><span class="text-primary">Sobre cupo</span><br><span class="text-muted">Notificaciones adicionales a las del plan</span></div>
                  <div class="col-2 text-right">{{ dataPlanDetailOrganization?.plan_vigencia.sobrecupo ? 'Sí' : 'No' }}</div>
                </div>
              </li>
            </ul>
      </div>
  </div>
</div>
