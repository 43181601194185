<div class="">
  <button aria-label="Crear nuevo usuario" class="btn btn-sm btn-primary right-side-toggle waves-effect waves-light btn-inverse btn-circle  pull-right m-l-10"
          [tooltip]="constants.tooltips.btns.users.create"
          placement="top"
          type="button"
          (click)="goToViewCreateAndEditUsers()">
    <i class="ti-plus text-white"></i>
  </button>
</div>
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <!-- .left-right-aside-column-->
      <div class="contact-page-aside">
        <!-- .left-aside-column-->
        <!-- QUITAR LA CLASE d-none CUANDO YA ESTEN DISPONIBLES LOS TOTALES DE USUARIO Y EN EL SCSS QUITAR EL COMENTARIO DE LA CLASE right-aside -->
        <div class="left-aside bg-light-part d-none" *ngIf="users">
          <!-- <ul class="list-style-none">
            <li class="box-label"><a href="javascript:void(0)">Total de usuarios <span>123</span></a></li>
            <li class="divider"></li>
            <li><a href="javascript:void(0)">Clientes <span>103</span></a></li>
            <li><a href="javascript:void(0)">Atiende citas <span>19</span></a></li>
            <li><a href="javascript:void(0)">Asistentes <span>623</span></a></li>
          </ul> -->
        </div>
        <!-- /.left-aside-column-->
        <div class="right-aside ">
          <div class="right-page-header">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                <label for="textB" class="mb-0"><small>Buscar:</small></label>
                <input type="text" id="textB" placeholder="Nombre o número de identificación"
                  class="form-control" [(ngModel)]="searchUsers"
                  (keyup)="searchUsersChange($event)">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <button aria-label="Crear nuevo usuario" type="button" class="btn btn-primary btn-rounded pull-right mt-3" (click)="goToViewCreateAndEditUsers()">
                  <i class="ti-plus"></i> Crear nuevo usuario
                </button>
              </div>
            </div>
          </div>

          <ng-template [ngIf]="users === null">
            <p lines="none">
              No se encontraron resultados
            </p>
          </ng-template>

          <ng-template [ngIf]="users">
            <div class="table-responsive">
              <table id="demo-foo-addrow" class="table vm m-t-30 table-hover no-wrap" data-page-size="10">
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Identificación</th>
                    <th class="text-center">Email</th>
                    <th class="text-center">Teléfono</th>
                    <th class="text-center">Tipo de usuario</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of users; let i = index">
                    <td class="text-center">{{ (i + 1) }}</td>
                    <td>
                        <!-- <img loading="lazy" src="../assets/images/users/1.jpg" alt="user" class="img-circle" /> -->
                        {{ user.nombres }} {{ user.apellidos }}
                    </td>
                    <td>{{ user.tipo_identificacion.abreviatura }}: {{user.identificacion }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.celulares }}</td>
                    <td>{{ user.roles. nombre}}</td>
                    <td class="text-center">
                      <a href="JavaScript:void(0)" class="btn link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [tooltip]="constants.tooltips.btns.actions" placement="top">
                        <i class="fas fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="goToViewCreateAndEditUsers(user);"><i class="far fa-edit mr-1"></i> Editar usuario</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="toolsService.messageWhatsapp(user.celulares);" ><i class="fab fa-whatsapp mr-1"></i> Mensaje</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="toolsService.openCall(user.celulares)" ><i class="ti-mobile mr-1"></i>Llamar</a>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td colspan="7" class="text-center">
                      <button aria-label="Crear nuevo usuario" type="button" class="btn btn-primary btn-rounded" (click)="goToViewCreateAndEditUsers()">
                        <i class="ti-plus"></i> Crear nuevo usuario
                      </button>
                    </td>
                  </tr> -->
                </tbody>
              </table>
              <div class="row">
                <div class="col-12 text-center mb-3">
                  <button aria-label="Cargar más" type="button" class="btn btn-primary btn-rounded" (click)="getUsersSearch(true)">
                    <i class="ti-plus"></i> Cargar más
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- .left-aside-column-->
        </div>
        <!-- /.left-right-aside-column-->
      </div>
    </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End PAge Content -->
<!-- ============================================================== -->
