import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { Constants } from '../../../providers/constants/constants';
import { ToolsService } from '../../services/tools.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-verifyphonenumber',
  templateUrl: './verifyphonenumber.component.html',
  styleUrls: ['./verifyphonenumber.component.scss']
})
export class VerifyphonenumberComponent implements OnInit {
  constants = Constants;
  showCard = false;
  dataUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user));
  idUserSesion = this.dataUser._id;
  rolIdUSer = this.dataUser.roles._id;
  nameUser = this.dataUser.nombres;
  phoneNumberUser = this.dataUser.celulares[0];
  phoneVerification = this.dataUser.celular_verificado;

  constructor(
    private toolsService: ToolsService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    if (this.phoneVerification === false && this.rolIdUSer === this.constants.roles.administrador) {
      this.showCard = true;
    }
  }

  hiddenCard() {
    this.showCard = false;
  }

  presentAlertEditPhoneNumberUser() {
    Swal.fire({
      title: `<span class="text-primary">Editar número de teléfono</span>`,
      html: `<p class="text-left">Hola <strong>${this.nameUser}</strong>, ingresa tu número de teléfono celular para editarlo y proceder a enviar el código de verificación.</p>`,
      // icon: 'success',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      confirmButtonText: 'Editar',
      allowOutsideClick: false, // permite que solo se cierre el modal con un evento o accion js
      input: 'text',
      inputLabel: 'Ingresa tu número de teléfono celular',
      inputValue: this.phoneNumberUser,
      inputValidator: (value) => {
        const phoneNumber = value;
        if (!phoneNumber) {
          return this.constants.validationMessages.celulares[0].message;
        }

        if (!this.toolsService.validateNumber(phoneNumber)) {
          // this.toolsService.presentToast(this.constants.validationMessages.isNAn[0].message);
          return this.constants.validationMessages.isNAn[0].message;
        }

        if (phoneNumber.length === 10 && !isNaN(parseInt(phoneNumber, 10))) {
          this.editPhoneNumberUser(phoneNumber);
        } else {
          // this.toolsService.presentToast(this.constants.validationMessages.celulares[1].message);
          return this.constants.validationMessages.celulares[1].message;
        }
      }
    });
  }

  editPhoneNumberUser(phoneNumber) {
    const dataUSer = {
      celulares: [phoneNumber.trim()],
    };
    this.userService.putUpdateUser(this.idUserSesion, dataUSer)
      .subscribe(response => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          this.phoneNumberUser = phoneNumber;

          this.dataUser.celulares[0] = phoneNumber.toString();
          localStorage.setItem(this.constants.localStorage.user, JSON.stringify(this.dataUser));

          this.toolsService.presentToast(this.constants.users.notificationUpdate);
          this.sendVerificationCodePhoneNumber();
        } else {
          this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  sendVerificationCodePhoneNumber() {
    const data = {
      id: this.idUserSesion,
      celular: this.phoneNumberUser,
      type: 'send'
    };
    this.userService.postUserCellVerification(data).subscribe(response => {
      const typeResponse = response['type'];
      if (typeResponse === this.constants.services.types.success) {
        this.presentAlertVerifyPhoneNumber();
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
      }
    }, err => {
    }, () => {
    });
  }

  presentAlertVerifyPhoneNumber() {
    Swal.fire({
      title: `<span class="text-primary">Vamos a verificar tu número de teléfono</span>`,
      html: `<p class="text-left">${this.nameUser}, Agéndate App envío un mensaje SMS al <strong>${this.phoneNumberUser}</strong> con un código para verificar tu número de teléfono.</p>`,
      // icon: 'success',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      confirmButtonText: 'Verificar',
      allowOutsideClick: false, // permite que solo se cierre el modal con un evento o accion js
      input: 'text',
      inputLabel: 'Ingresa el código de verificación',
      // inputValue: '',
      inputValidator: (value) => {
        const codeVerification = value;
        if (codeVerification.length === 6) {
          this.verificationCodePhoneNumber(codeVerification);
        } else {
          // this.toolsService.presentToast(this.constants.verificationCodePhoneNumber.minAndMaxCode, this.constants.iconsSweetalert.error);
          return this.constants.verificationCodePhoneNumber.minAndMaxCode;
        }
      }
    });
  }

  verificationCodePhoneNumber(codeVerification: string) {
    const data = {
      id: this.idUserSesion,
      codigo: codeVerification,
      type: 'verify'
    };
    this.userService.postUserCellVerification(data).subscribe(response => {
      const typeResponse = response['type'];
      if (typeResponse === this.constants.services.types.success) {
        this.hiddenCard();
        this.toolsService.presentToast('Número de teléfono verificado.');
        // Actuaizo en local stire que se actualizo el usuario
        this.dataUser.celular_verificado = false;
        localStorage.setItem(this.constants.localStorage.user, JSON.stringify(this.dataUser));
      } else if (typeResponse === this.constants.services.types.error) {
        this.presentAlertVerifyPhoneNumber();
        this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
      }
    }, err => {
    }, () => {
    });
  }
}
