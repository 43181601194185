import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PlanService {

  constructor(public Api: ApiService) { }

  getPlanes() {
    // return this.Api.get(`/type?grupo=${param_type}`);
    return this.Api.get(`/plan/`);
  }

  getPlanesById(id: string) {
    // return this.Api.get(`/type?grupo=${param_type}`);
    return this.Api.get(`/plan/${id}`);
  }
}
