<div class="">
  <button aria-label="Crear nuevo procedimiento"
    class="btn btn-sm btn-primary right-side-toggle waves-effect waves-light btn-inverse btn-circle  pull-right m-l-10"
    [tooltip]="constants.tooltips.btns.procedures.create" placement="top" type="button" (click)="goToViewCreateAndEditProcedures()">
    <i class="ti-plus text-white"></i>
  </button>
</div>
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
      <!-- .left-right-aside-column-->
      <div class="contact-page-aside">
        <div class="right-aside ">
          <div class="right-page-header">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="textB" class="mb-0"><small>Buscar:</small></label>
                  <input type="text" id="textB" placeholder="Nombre del procedimiento" class="form-control"
                    [(ngModel)]="searchProcedures" (keyup)="searchProceduresChange($event)">
                </div>
              </div>
              <div class="col-12 col-md-8">
                <button aria-label="Crear nuevo procedimiento" type="button" class="btn btn-primary btn-rounded pull-right mt-3" (click)="goToViewCreateAndEditProcedures()">
                  <i class="ti-plus"></i> Crear nuevo procedimiento
                </button>
              </div>
            </div>
          </div>

          <ng-template [ngIf]="typesProcedures === null">
            <p lines="none">
              No se encontraron resultados
            </p>
          </ng-template>

          <ng-template [ngIf]="typesProcedures">
            <div class="table-responsive">
              <table id="demo-foo-addrow" class="table vm m-t-30 table-hover no-wrap" data-page-size="10">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 50px;">No</th>
                    <th class="text-left">Nombre</th>
                    <th class="text-center" style="width: 100px;">Duración</th>
                    <th class="text-center" style="width: 100px;">Precio</th>
                    <th class="text-center" style="width: 100px;">Estado</th>
                    <th class="text-center" style="width: 100px;">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let typeProcedure of typesProcedures; let i = index">
                    <td class="text-center">{{ (i + 1) }}</td>
                    <td>
                      {{ typeProcedure.nombre }}
                    </td>
                    <td class="text-center">
                      <span *ngIf="typeProcedure.tiempo_estimado && typeProcedure.tiempo_estimado < 60"><i class="ti-time"></i> {{ moment.duration(typeProcedure.tiempo_estimado, 'minutes').format("m[m]") }}</span>
                      <span *ngIf="typeProcedure.tiempo_estimado && typeProcedure.tiempo_estimado >= 60"><i class="ti-time"></i> {{ moment.duration(typeProcedure.tiempo_estimado, 'minutes').format('HH:mm') }}</span>
                    </td>
                    <td class="text-center">
                      <span *ngIf="typeProcedure?.caracteristicas">{{ typeProcedure?.caracteristicas.valor_servicio.valor | currency:'COP':'$':'1.0-0':'es-ES' }}</span>
                    </td>
                    <td class="text-center">{{ typeProcedure.estados.nombre }}</td>
                    <td class="text-center">
                      <ng-template
                        [ngIf]="typeProcedure.organizaciones !== '' && typeProcedure.organizaciones !== undefined">
                        <a href="JavaScript:void(0)" class="btn link" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false" [tooltip]="constants.tooltips.btns.actions" placement="top">
                          <i class="fas fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="javascript:void(0)"
                            (click)="goToViewCreateAndEditProcedures(typeProcedure);"><i class="far fa-edit mr-1"></i>
                            Editar procedimiento</a>
                        </div>
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
          <!-- .left-aside-column-->
        </div>
        <!-- /.left-right-aside-column-->
      </div>
    </div>
  </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End PAge Content -->
<!-- ============================================================== -->
