import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Constants } from '../../../../../providers/constants/constants';
import { OrganizationService } from '../../../../services/organization.service';
import { ToolsService } from '../../../../services/tools.service';
import { PlanService } from '../../../../services/plan.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-plandetail',
  templateUrl: './plandetail.component.html',
  styleUrls: ['./plandetail.component.scss']
})
export class PlandetailComponent implements OnInit {
  constants = Constants;
  idOrganization = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones._id;
  planDetailOrganizationSubs: Subscription;
  dataPlanDetailOrganization: any;
  planDetailSubs: Subscription;
  dataPlanDetail: any;

  constructor(
    private organizationService: OrganizationService,
    private toolsService: ToolsService,
    private planService: PlanService
  ) { }

  ngOnInit() {
    this.getPlanDetailOrganization();
  }

  ngOnDestroy() {
    this.planDetailOrganizationSubs?.unsubscribe();
    this.planDetailSubs?.unsubscribe();
  }

  getPlanDetailOrganization() {
    this.planDetailOrganizationSubs = this.organizationService.getOrganization(this.idOrganization)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.dataPlanDetailOrganization = response.data;
          this.getDetailPlan();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  getDetailPlan() {
    this.planDetailSubs = this.planService.getPlanesById(this.dataPlanDetailOrganization.planes)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.dataPlanDetail = response.data;
          // console.log(this.dataPlanDetail);
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  parseDatePlan(dateParse: string) {
    // se formatea fecha de creacion a formato solicitado en requerimiento ejem: Mié 15 de abr. 2020 | 11:55 AM
    // .lang.('es')
    // const dia = moment(dateParse, 'YYYY-MM-DDThh:mm').locale('es').format('dddd');
    const diaMes = moment(dateParse).format('D');
    const mes = moment(dateParse).locale('es').format('MMM');
    const ano = moment(dateParse).format('YYYY');
    // const hora = moment(dateParse).format('hh:mm A');
    return `${diaMes} de ${mes.replace(/^./, mes[0].toUpperCase())} ${ano}`;
  }
}
