<div class="sidebar-login">
  <div class="container-fluid px-0">
    <div class="row mx-0">
      <div [ngStyle]="{'background-image': 'url('+imgFondo+')'}" class="col-sm-6 col-md-5 d-none d-sm-flex align-content-between flex-wrap px-0 h-vh-100 bg-custom-agendar-citas">
        <div class="row mx-0"></div>
        <div class="row mx-0 mt-3">
          <figure class="col-12 text-center">
            <img loading="lazy" *ngIf="logoClienteSecundario == ''" src="./assets/img/logos/logo-agendate-blanco.svg" class="logo-cliente-panel-izquierdo" border="0">
            <img loading="lazy" *ngIf="logoClienteSecundario !== ''" [src]="logoClienteSecundario" class="logo-cliente-panel-izquierdo" border="0">
          </figure>
          <h5 class="col-12 text-center text-white text--extrabold text--lh-1" [innerHTML]="tituloPublicidad"></h5>
          <p class="col-12 px-5 text-center text-white text--lh-normal text--normal" [innerHTML]="descripcionPublicidad"></p>
        </div>
        <div class="row mx-0 w-100 justify-content-center pb-4 networks">
          <span class="col-12 text-white mb-2 text--normal text-center">
            Siguenos en nuestras redes sociales
          </span>
          <app-social-networks-icons></app-social-networks-icons>
        </div>
      </div>
      <div class="col-sm-6 col-md-7 px-0 h-vh-100 scroll">
        <!-- Form agendar cita -->
        <div class="row mx-0 d-flex align-content-between flex-wrap">
          <div class="col-12 d-flex justify-content-between align-items-center px-4 my-3">
            <div class="float-left">
              <a [routerLink]="['/']" *ngIf="logoCliente == ''" class="text-left">
                <figure class="mb-0">
                  <picture>
                    <source media="(max-width: 991px)" srcset="./assets/img/logos/logoLogin.svg">
                    <source media="(min-width: 992px)" srcset="./assets/img/logos/logoLogin.svg">
                    <img loading="lazy" class="w-50" src="./assets/img/logos/logoLogin.svg" border="0" />
                  </picture>
                </figure>
              </a>
              <figure *ngIf="logoCliente !== '' && dataOrganization.organization_id !== constants.idOrganizacionAgendate" class="text-left mb-0">
                <picture>
                  <source media="(max-width: 991px)" [srcset]="logoCliente">
                  <source media="(min-width: 992px)" [srcset]="logoCliente">
                  <img loading="lazy" class="logoCliente" [src]="logoCliente" border="0" />
                </picture>
              </figure>
            </div>
            <!-- logo de agendate, solo sale si existe un logo de cliente -->
            <div class="float-right" *ngIf="logoCliente !== ''">
              <a [routerLink]="['/']" class="text-right">
                <figure class="mb-0">
                  <picture>
                    <source media="(max-width: 991px)" srcset="./assets/img/logos/logoLogin.svg">
                    <source media="(min-width: 992px)" srcset="./assets/img/logos/logoLogin.svg">
                    <img loading="lazy" class="w-50" src="./assets/img/logos/logoLogin.svg" border="0" />
                  </picture>
                </figure>
              </a>
            </div>
          </div>
          <div class="d-flex col-11 col-sm-11 col-md-12 col-lg-10 col-xl-8 mx-auto">


            <div class="row mx-0 mb-2 w-100">
              <div class="col-12 mt-4 mt-md-3 px-0 titulos">¡Agéndate ya!</div>
              <form class="col-12 px-0 forms" [formGroup]="pasoUnoForm">
                <div class="text-primary mt-2 mb-3">
                  Por favor diligencie los datos a continuación para agendar tu cita o reserva!
                </div>

                <!-- contenedor paso 1 del registro -->
                <div [ngClass]="{ 'd-none' : stepRegistration === 2 }">
                  <div class="titulos text-secondary">Paso 1 de 2</div>
                  <div class="titulos mb-3">Datos personales</div>
                  <div class="row">

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="selectTipoIdentificacion"><span
                          class="icon-label-solid icon-businessCards"></span> Tipo de documento</label>
                      <ng-select formControlName="tipoIdentificacion" class="text--xl" [dropdownPosition]="'bottom'"
                        appearance="outline" labelForId="selectTipoIdentificacion" [multiple]="false" [searchable]="false"
                        placeholder="Seleccione un tipo" (change)="focusoutConsultarUsuarioCliente($event)">
                        <ng-option selected disabled value="">Seleccione un tipo</ng-option>
                        <ng-option *ngFor="let item of objSelectTypesOfIdentification" value="{{item._id}}">
                          {{item.nombre}}</ng-option>
                      </ng-select>

                      <ng-container *ngFor="let validation of constants.validationMessages.tipoIdentificacion">
                        <div class="error-message"
                          *ngIf="pasoUnoForm.get('tipoIdentificacion').hasError(validation.type) && (pasoUnoForm.get('tipoIdentificacion').dirty || pasoUnoForm.get('tipoIdentificacion').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="textIdentificacion"><span class="icon-label-solid icon-businessCards"></span>
                        Número de documento</label>
                      <input type="text" id="textIdentificacion" formControlName="identificacion" (focusout)="focusoutConsultarUsuarioCliente($event)"
                        class="form-control text--xm" placeholder="Número de documento">

                      <ng-container *ngFor="let validation of constants.validationMessages.identificacion">
                        <div class="error-message"
                          *ngIf="pasoUnoForm.get('identificacion').hasError(validation.type) && (pasoUnoForm.get('identificacion').dirty || pasoUnoForm.get('identificacion').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="textNombres"><span class="icon-label-solid icon-user"></span> Nombres</label>
                      <input type="text" id="textNombres" formControlName="nombres" class="form-control text--xm"
                        placeholder="Nombres completos">

                      <ng-container *ngFor="let validation of constants.validationMessages.nombres">
                        <div class="error-message"
                          *ngIf="pasoUnoForm.get('nombres').hasError(validation.type) && (pasoUnoForm.get('nombres').dirty || pasoUnoForm.get('nombres').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="textApellidos"><span class="icon-label-solid icon-user"></span>
                        Apellidos</label>
                      <input type="text" id="textApellidos" formControlName="apellidos" class="form-control text--xm"
                        placeholder="Apellidos completos">
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="dateFechaNacimiento"><span class="icon-label-solid icon-calendar"></span>
                        Fecha de nacimiento</label>
                      <input type="date" id="dateFechaNacimiento" formControlName="nacimiento" (change)="validarFechaNacimiento()" class="form-control calendar text--xm">
                      <ng-container>
                        <div class="error-message" *ngIf="fechaMenorQuelaActual != ''">
                          <i class="fas fa-info-circle"></i> {{ fechaMenorQuelaActual }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="textCorreoElectronico"><span class="icon-label-solid icon-envelope"></span>
                        Correo electrónico</label>
                      <input type="email" id="textCorreoElectronico" formControlName="email" class="form-control text--xm"
                        placeholder="Ingresa tu eMail de contacto">

                      <ng-container *ngFor="let validation of constants.validationMessages.email">
                        <div class="error-message"
                          *ngIf="pasoUnoForm.get('email').hasError(validation.type) && (pasoUnoForm.get('email').dirty || pasoUnoForm.get('email').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-3">
                      <label class="labels" for="textCelulares"><span class="icon-label-solid icon-phone"></span> Teléfono
                        celular</label>
                      <input type="tel" id="textCelulares" formControlName="celulares" class="form-control text--xm"
                        placeholder="Ingresa tu número móvil">

                      <ng-container *ngFor="let validation of constants.validationMessages.celulares">
                        <div class="error-message"
                          *ngIf="pasoUnoForm.get('celulares').hasError(validation.type) && (pasoUnoForm.get('celulares').dirty || pasoUnoForm.get('celulares').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
                      <button aria-label="Continuar" type="button" [disabled]="!pasoUnoForm.valid" (click)="nextStepRegistration(2)" class="btn btn-lg btn-primary font-weight-bold rounded-pill">Continuar</button>
                    </div>
                  </div>
                </div><!-- fin del condetenor del paso 1-->
              </form>

              <form class="col-12 px-0 forms" [formGroup]="pasoDosForm" (ngSubmit)="validateCustomerCreation()">
                <!-- contenedor paso 2 del registro -->
                <div [ngClass]="{ 'd-none' : stepRegistration === 1 }">
                  <div class="titulos text-secondary">Paso 2 de 2</div>
                  <div class="titulos mb-3">Datos de la cita</div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                      <label class="labels" for="selectTipoCita"><span class="icon-label-solid icon-user"></span> Tipo de
                        cita</label>
                      <ng-select formControlName="tipoCita" (change)="getUsersSearch();changeConsultarDisponivilidadCitas();" class="text--xl" [dropdownPosition]="'bottom'"
                        appearance="outline" labelForId="selectTipoCita" [multiple]="false" [searchable]="true"
                        placeholder="Seleccione un tipo de cita">
                        <ng-option selected disabled value="">Seleccione un tipo de cita</ng-option>
                        <ng-option *ngFor="let procedure of procedures" value="{{procedure._id}}">{{procedure.nombre}}
                        </ng-option>
                      </ng-select>

                      <ng-container *ngIf="valorDelProcedimiento">
                        <label class="labels">Valor: {{ valorDelProcedimiento | currency:'COP':'$':'1.0-0':'es-ES' }}</label>
                        <!-- <label class="labels ml-2">Duración: {{ moment.duration(tiempoProcedimiento, 'minutes').locale('es').humanize() }}</label> -->
                        <label class="labels ml-2">Duración: {{ moment.duration(tiempoProcedimiento, 'minutes').format('HH:mm') }}</label>
                      </ng-container>
                      <ng-container *ngIf="descripcionProcedimiento">
                        <br>
                        <p class="parrafos text-primary text--xl">{{ descripcionProcedimiento }}</p>
                      </ng-container>

                      <ng-container *ngFor="let validation of constants.validationMessages.tipoCita">
                        <div class="error-message"
                          *ngIf="pasoDosForm.get('tipoCita').hasError(validation.type) && (pasoDosForm.get('tipoCita').dirty || pasoDosForm.get('tipoCita').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                      <label class="labels" for="selectUsuarioAtendedor"><span class="icon-label-solid icon-user"></span>
                        Atendido por</label>
                      <ng-select formControlName="usuarioAtendedor" (change)="changeConsultarDisponivilidadCitas();" class="text--xl" [dropdownPosition]="'bottom'"
                        appearance="outline" labelForId="selectUsuarioAtendedor" [multiple]="false" [searchable]="false"
                        placeholder="Selecciona una opción">
                        <ng-option selected disabled value="">Selecciona una opción</ng-option>
                        <ng-option *ngFor="let user of users" value="{{user._id}}">{{user.nombres}} {{user.apellidos}}
                        </ng-option>
                      </ng-select>

                      <ng-container *ngFor="let validation of constants.validationMessages.usuarioAtendedor">
                        <div class="error-message"
                          *ngIf="pasoDosForm.get('usuarioAtendedor').hasError(validation.type) && (pasoDosForm.get('usuarioAtendedor').dirty || pasoDosForm.get('usuarioAtendedor').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3 form-group">
                      <label class="labels d-block">
                        <span class="icon-label-solid icon-calendar"></span>
                        Selecciona la fecha en la que deseas agendarte
                      </label>

                      <label class="convenciones text-secondary mt-2">
                        <span><span class="icon-circulo-azul mr-1"></span> Día seleccionado</span><br>
                        <span><span class="icon-circulo-gris mr-1"></span> Días disponible</span>
                      </label>

                      <ngb-datepicker #dpAppointment name="piker" formControlName="fechaCita" (navigate)="navegacionFechas($event.next)" [dayTemplate]="customDay" class="mt-2"></ngb-datepicker>

                      <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
                        let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.diaActual]="selected"
                          [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled" [class.has-task]="hasTask(date)"
                          (click)="showTasks(date)">
                          {{ date.day }}
                        </span>
                      </ng-template>

                      <ng-container *ngFor="let validation of constants.validationMessages.fecha">
                        <div class="error-message"
                          *ngIf="pasoDosForm.get('fechaCita').hasError(validation.type) && (pasoDosForm.get('fechaCita').dirty || pasoDosForm.get('fechaCita').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3 form-group">
                      <label class="labels" for="selectTipoIdentificacion">
                        <span class="icon-label-solid icon-clock"></span>
                        Selecciona la hora en la que deseas agendarte
                      </label>
                    <div class="row mt-3">
                      <!-- horas disponibles de citas por fecha seleccionada en el calendario -->
                      <ng-container *ngIf="horasDisponiblesPorDia.length > 0">
                        <ng-container *ngFor="let horaDisponible of horasDisponiblesPorDia">
                          <!-- en el ngIf se valida que el formato de hora sea valido-->
                          <div class="col-4 col-lg-6 col-xl-4 mb-2 px-1" *ngIf="toolsService.parseDates(horaDisponible, 0)" (click)="seleccionarHoraDisponibleCita(horaDisponible)">
                            <div class="cont-hora" [ngClass]="{ 'seleccionada' : horaDisponibleSeleccionada === horaDisponible }">
                              {{ toolsService.parseDates(horaDisponible, 2) }}</div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="horasDisponiblesPorDia.length === 0">
                        <div class="col-12 mb-2">
                          <div class="alert alert-warning" role="alert">
                            <div class="media">
                              <!-- <img loading="lazy" src="..." class="mr-3" alt="..."> -->
                              <span class="icon-icon-info-ama text--xlg mr-3"></span>
                              <div class="media-body">
                                <p class="text--xxs text--gray_darken_1 mb-0">No hay citas disponibles para la fecha seleccionada.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                      <label class="labels" for="textareaNota">
                        <span class="icon-label-solid icon-note"></span> Por favor describa su solicitud
                      </label>
                      <textarea class="form-control text--xm" id="textareaNota" formControlName="nota"
                        rows="3"></textarea>

                      <ng-container *ngFor="let validation of constants.validationMessages.nota">
                        <div class="error-message"
                          *ngIf="pasoDosForm.get('nota').hasError(validation.type) && (pasoDosForm.get('nota').dirty || pasoDosForm.get('nota').touched)">
                          <i class="fas fa-info-circle"></i> {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                      <label class="labels" for="selectComoTeEnteraste"><span
                          class="icon-label-solid icon-agendaConfirmadaCita"></span> Cómo te enteraste de nosotros</label>
                      <ng-select formControlName="comoTeEnteraste" class="text--xl" [dropdownPosition]="'bottom'"
                        appearance="outline" labelForId="selectComoTeEnteraste" [multiple]="true" [searchable]="false"
                        placeholder="Seleccione un opción">
                        <ng-option selected disabled value="">Selecciona una o varias opciones</ng-option>
                        <ng-option *ngFor="let parameter of parameters" value="{{parameter}}">{{ parameter }}</ng-option>
                      </ng-select>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                      <div class="form-check custom-checkbox checkbox-negro">
                        <input type="checkbox" id="checkAceptedTerms" formControlName="aceptedTerms"
                          class="custom-control-input">
                        <label class="text--xm pt-1 custom-control-label text-primary" for="checkAceptedTerms">
                          Deseo voluntariamente solicitar el agendamiento de una cita o reserva y doy autorización para
                          que se pongan en contacto conmigo al teléfono y email suministrado por mí en este formulario.
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
                      <button aria-label="Atrás" type="button" (click)="nextStepRegistration(1)" class="btn btn-lg btn-primary font-weight-bold rounded-pill mr-3">Atrás</button>
                      <button aria-label="Solicitar cita o reserva" type="submit" [disabled]="!pasoDosForm.valid"
                        class="btn btn-lg btn-primary rounded-pill font-weight-bold">Solicitar cita o
                        reserva</button>
                    </div>
                  </div>
                </div><!-- Fin del contenedor del paso 2 -->
                <!-- metodos de pago -->
                <div class="row pt-3 border-top" *ngIf="dataPersonalization?.metodos_pago">
                  <div class="col-12">
                    <div class="cont-metodospago">
                      <div class="titulos mb-3">Metodos de pago aceptados en {{ dataOrganization?.organization }}</div>
                      <img *ngFor="let metodoDePago of dataPersonalization?.metodos_pago" [src]="metodoDePago.url" [alt]="metodoDePago.nombre" loading="lazy">
                    </div>
                  </div>
                </div>
                <!-- Fin metodos de pago -->
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-primary text--normal text--xm text-center mb-lg-3">
            <span *ngIf="dataPersonalization?.texto_destacado">{{ dataPersonalization.texto_destacado }}<br></span>
          </div>
        </div>
        <div class="row mx-0 w-100 justify-content-center pb-4 networks d-md-none d-lg-none d-xl-none">
          <div class="col-12 text-primary mb-2 text--normal text--xm text-center">
            Siguenos en nuestras redes sociales
          </div>
          <app-social-networks-icons></app-social-networks-icons>
        </div>
      </div>
    </div>
  </div>
</div>
