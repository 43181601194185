<div class="content-wrapper-area p-4 bg-white d-flex justify-content-between align-items-center">
  <a class="logo" [routerLink]="['/']">
    <img loading="lazy" src="/assets/img/logos/logoLogin.svg" alt="Logo Agéndate">
  </a>

  <div class="main-content pt-4">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-6">
          <ng-container *ngIf="verifiedAccount">
            <h4 class="titulos">Hola, {{ fullNameUser }}.</h4>
            <h5 class="titulos">Bienvenido a Agéndate App.</h5>
            <p class="parrafos">Tu confirmación de cuenta ha sido registrada exitosamente, desde ya puedes ingresar a la aplicación Agéndate App con tu usuario y contraseña.</p>
          </ng-container>

          <ng-container *ngIf="!verifiedAccount">
            <h4 class="titulos">Hola, Bienvenido a Agéndate App.</h4>
            <p class="parrafos">La url a la que estas ingresando no es valida, verifica la url que fue enviada al correo electrónico.</p>
            <p class="parrafos">Atentamente.<br>Equipo de Agéndate App</p>
          </ng-container>
        </div>
        <div class="col-12 col-md-5 mb-5 mb-md-0"><img loading="lazy" src="/assets/svg/personCalendar.svg" alt="Confirmación cuenta"></div>
      </div>
    </div>
  </div>

  <div class="footer_bottom text-center">
    <p>Para mayores informes <a [routerLink]="['/']">clic aquí.</a></p>
    <app-social-networks-icons></app-social-networks-icons>
  </div>
</div>
