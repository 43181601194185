<div>
  <div class="container-fluid px-0">
    <div class="row mx-0">
      <div class="col-sm-6 col-md-5 d-none d-sm-flex align-content-between flex-wrap px-0 h-vh-100 bg-custom-login">
        <div class="row mx-0"></div>
        <div class="row mx-0 mt-3">
          <figure class="col-12 text-center"><img loading="lazy" src="./assets/img/logos/logo-agendate-blanco.svg" class="w-50" border="0"></figure>
          <h6 class="col-12 text-center text-white text--extrabold text--lh-2 my-2">Un mensaje en el momento oportuno,<br>Hace la diferencia.</h6>
          <p class="col-12 px-5 text-center text-white text--lh-normal text--normal text--xl">Mejora el agendamiento de citas o reservas<br> de una manera mucho más ágil,<br> práctica y confiable.</p>
          <figure class="col-12 text-center"><img loading="lazy" src="./assets/img/iconos/icon-calendario-citas.png" class="w-50" border="0"></figure>
          <p class="col-12 px-5 text-center text-white text--lh-normal text--normal text--xl">Mejora la comunicación con tus clientes<br>a través del envío de recordatorios 24<br>horas antes de sus citas o eventos.</p>
        </div>
        <div class="row mx-0 w-100 justify-content-center pb-4 networks">
          <span class="col-12 text-white mb-2 text--normal text--xm text-center">
            Siguenos en nuestras redes sociales
          </span>
          <app-social-networks-icons></app-social-networks-icons>
          <!-- <div  class="col-12 d-flex justify-content-center align-items-center social-icons social-icons-login" *ngIf="menus.SIGUENOS">
            <div class="mx-2" *ngFor="let item of menus.SIGUENOS.items">
              <a [href]="item.url" target="_blank" class="bg-opacity-white ico rounded-circle d-flex justify-content-center align-items-center">
                <i [class]="item.clase_css"></i>
              </a>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-sm-6 col-md-7 px-0 h-vh-100 scroll">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
