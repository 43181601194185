<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Datos de la cita</h6>
        <!-- <h6 class="card-subtitle">Just add class to the form that's it.</h6> -->
        <form class="forms m-t-40" [formGroup]="validationsForm" (ngSubmit)="createAndEditAppointment()">
          <div class="row">

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
              <label class="labels" for="selectNombreCliente"><span class="icon-label icon-user"></span> Nombre del
                cliente</label>
              <!-- Es informativo y sale solo si van a editar la cita -->
              <ng-container *ngIf="editAppointment">
                <input type="text" class="form-control text--xm" disabled [value]="nameUserClient">
              </ng-container>

               <!-- sale solo si es una nueva cita -->
              <ng-container *ngIf="!editAppointment">
                <ng-select
                  [items]="users$ | async"
                  formControlName="usuarioCliente"
                  bindValue="_id"
                  bindLabel="nombre_completo"
                  [multiple]="false"
                  [hideSelected]="true"
                  [minTermLength]="2"
                  [loading]="usersLoading"
                  [dropdownPosition]="'bottom'"
                  appearance="outline"
                  labelForId="selectNombreCliente"
                  placeholder="Buscar por nombre o número de identificación"
                  typeToSearchText="Ingresa 2 o más caracteres" [typeahead]="usersInput$">
                </ng-select>
              </ng-container>

              <ng-container *ngFor="let validation of constants.validationMessages.nombres">
                <div class="error-message"
                  *ngIf="validationsForm.get('usuarioCliente').hasError(validation.type) && (validationsForm.get('usuarioCliente').dirty || validationsForm.get('usuarioCliente').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
              <label class="labels" for="selectTipoCita"><span class="icon-label icon-user"></span> Tipo de
                cita</label>
              <ng-select formControlName="tipoCita"
                (change)="getUsersSearchAttendsAppointment();changeConsultarDisponivilidadCitas();" class="text--xl"
                [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTipoCita" [multiple]="false"
                [searchable]="true" placeholder="Seleccione un tipo de cita">
                <ng-option selected disabled value="">Seleccione un tipo de cita</ng-option>
                <ng-option *ngFor="let procedure of procedures" value="{{procedure._id}}">{{procedure.nombre}}
                </ng-option>
              </ng-select>

              <ng-container *ngIf="valorDelProcedimiento">
                <label class="labels">Valor: {{ valorDelProcedimiento | currency:'COP':'$':'1.0-0':'es-ES' }}</label>
              </ng-container>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoCita">
                <div class="error-message"
                  *ngIf="validationsForm.get('tipoCita').hasError(validation.type) && (validationsForm.get('tipoCita').dirty || validationsForm.get('tipoCita').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
              <label class="labels" for="selectUsuarioAtendedor"><span class="icon-label icon-user"></span>
                Atendido por</label>
              <ng-select formControlName="usuarioAtendedor" (change)="changeConsultarDisponivilidadCitas();"
                class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectUsuarioAtendedor"
                [multiple]="false" [searchable]="true" placeholder="Selecciona una opción">
                <ng-option selected disabled value="">Selecciona una opción</ng-option>
                <ng-option *ngFor="let user of usersAttendsAppointment" value="{{user._id}}">{{user.nombres}}
                  {{user.apellidos}}
                </ng-option>
              </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.usuarioAtendedor">
                <div class="error-message"
                  *ngIf="validationsForm.get('usuarioAtendedor').hasError(validation.type) && (validationsForm.get('usuarioAtendedor').dirty || validationsForm.get('usuarioAtendedor').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
              <label class="labels" for="textareaNota">
                <span class="icon-label icon-note"></span> Nota
              </label>
              <textarea class="form-control text--xm" id="textareaNota" formControlName="nota" rows="3"
                placeholder="Ingresa una nota."></textarea>

              <ng-container *ngFor="let validation of constants.validationMessages.nota">
                <div class="error-message"
                  *ngIf="validationsForm.get('nota').hasError(validation.type) && (validationsForm.get('nota').dirty || validationsForm.get('nota').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <!-- Es informativo y se quita solo si van a editar la fecha o hora de la cita -->
            <ng-container *ngIf="editAppointment && !editDateAppointment">
              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
                <label class="labels">
                  <span class="icon-label icon-calendar"></span> Fecha y hora de la cita
                </label>
                <label class="text-capitalize">{{ toolsService.parseDates(dataActivatedRoute.fecha, 1) }}</label>
                <label>Si desea cambiar la fecha y hora de la cita <span class="text-negrilla text--red cursor-pointer" (click)="dateAppointmentEdit()">presione aquí.</span></label>
              </div>
            </ng-container>

            <!-- sale solo si es una nueva cita o van a editar la fecha o hora de la cita -->
            <ng-container *ngIf="!editAppointment || editDateAppointment">
              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
                <label class="labels">
                  <span class="icon-label icon-calendar"></span> Selecciona la fecha en la que deseas agendar la cita
                </label>

                <label class="convenciones text-secondary mt-2">
                  <span><span class="icon-circulo-azul mr-1"></span> Día seleccionado</span> <span> <span
                      class="icon-circulo-gris mx-1"></span>Días disponible</span>
                </label>

                <div class="w-100">
                <ngb-datepicker #dpAppointment name="piker" formControlName="fechaCita" (navigate)="navegacionFechas($event.next)"
                  [dayTemplate]="customDay" class="mt-2"></ngb-datepicker>

                <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                  let-disabled="disabled" let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.diaActual]="selected"
                    [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
                    [class.has-task]="hasTask(date)" (click)="showTasks(date)">
                    {{ date.day }}
                  </span>
                </ng-template>
                </div>

                <ng-container *ngFor="let validation of constants.validationMessages.fecha">
                  <div class="error-message"
                    *ngIf="validationsForm.get('fechaCita').hasError(validation.type) && (validationsForm.get('fechaCita').dirty || validationsForm.get('fechaCita').touched)">
                    <i class="fas fa-info-circle"></i> {{ validation.message }}
                  </div>
                </ng-container>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
                <label class="labels" for="selectTipoIdentificacion">
                  <span class="icon-label icon-clock"></span> Selecciona la hora en la que deseas agendar la cita
                </label>
                <div class="row mt-3">
                  <!-- horas disponibles de citas por fecha seleccionada en el calendario -->
                  <ng-container *ngIf="horasDisponiblesPorDia.length > 0">
                    <ng-container *ngFor="let horaDisponible of horasDisponiblesPorDia">
                      <!-- en el ngIf se valida que el formato de hora sea valido-->
                      <div class="col-4 col-lg-6 col-xl-4 mb-2 px-1" *ngIf="toolsService.parseDates(horaDisponible, 0)" (click)="seleccionarHoraDisponibleCita(horaDisponible)">
                        <div class="cont-hora" [ngClass]="{ 'seleccionada' : horaDisponibleSeleccionada === horaDisponible }">
                          {{ toolsService.parseDates(horaDisponible, 2) }}</div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="horasDisponiblesPorDia.length === 0">
                    <div class="col-12 mb-2">
                      <div class="alert alert-warning" role="alert">
                        <div class="media">
                          <!-- <img loading="lazy" src="..." class="mr-3" alt="..."> -->
                          <span class="icon-icon-info-ama text--xlg mr-3"></span>
                          <div class="media-body">
                            <p class="text--xxs text--gray_darken_1 mb-0">No hay citas disponibles para la fecha
                              seleccionada.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <button aria-label="Guardar" type="submit" [disabled]="!validationsForm.valid"
                class="btn btn-lg btn-primary text-uppercase font-weight-bold">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
