import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesAdminWebRoutingModule } from './pages/admin-web/admin-web.routes';
import { WebAgendateappComponent } from './web-agendateapp/web-agendateapp.component';
import { CuentaComponent } from './pages/cuenta/cuenta.component';
import { LoginComponent } from './pages/cuenta/login/login.component';
import { RegisterAccountComponent } from './pages/cuenta/register-account/register-account.component';
import { RecoverpasswordComponent } from './pages/cuenta/recoverpassword/recoverpassword.component';
import { ThankYouRegistrationComponent } from './pages/cuenta/thank-you-registration/thank-you-registration.component';
import { AccountComponent } from './components/confirmation/account/account.component';
import { AppointmentComponent } from './components/confirmation/appointment/appointment.component';
import { AgendarCitaClienteComponent } from './pages/agendar-cita-cliente/agendar-cita-cliente.component';
import { Error404Component } from './pages/errores/error404/error404.component';
import { DashboardApiComponent } from './pages/admin-web/api/dashboard-api/dashboard-api.component';
import { PrecioProcedimientosClientesComponent } from './pages/precio-procedimientos-clientes/precio-procedimientos-clientes.component';
import { Constants } from '../providers/constants/constants';
import { ListaClientesCumpleanosComponent } from './pages/lista-clientes-cumpleanos/lista-clientes-cumpleanos.component';
import { TerminosYCondicionesComponent } from './pages/terminos-y-condiciones/terminos-y-condiciones.component';
import { PoliticaTratamientoDeDatosComponent } from './pages/politica-tratamiento-de-datos/politica-tratamiento-de-datos.component';
import { EliminarCuentaComponent } from './pages/eliminar-cuenta/eliminar-cuenta.component';

const routes: Routes = [
  { path: '', component: WebAgendateappComponent, data: Constants.metaDataModulos.compWebAgendate/*{ titlePage: 'Agendamiento de citas online.'}*/ },
  { path: 'confirmation/account/:idCode', component: AccountComponent, data: { title: 'Confirmar cuenta.'} },
  { path: 'confirmation/appointment/:id', component: AppointmentComponent, data: { title: 'Confirmar cita.'} },
  {
    path: 'cuenta',
    component: CuentaComponent,
    children: [
      { path: 'ingresar', component: LoginComponent, data: { title: 'Iniciar sesión.'} },
      { path: 'registrarme', component: RegisterAccountComponent, data: { title: 'Registrar cuenta.'} },
      { path: 'registrarme/:idReferido', component: RegisterAccountComponent, data: { title: 'Registrar cuenta.'} },
      { path: 'recoverpassword', component: RecoverpasswordComponent, data: { title: 'Recuperar contraseña.'} },
      { path: 'registro-gracias', component: ThankYouRegistrationComponent, data: { title: 'Registro exitoso, gracias.'} },
      { path: '**', pathMatch: 'full', redirectTo: '/cuenta/ingresar' }
    ]
  },
  { path: 'citas/:clientSubDomain', component: AgendarCitaClienteComponent, data: { title: 'Autoagendamiento de citas.'} },
  { path: 'servicios/:clientSubDomain', component: PrecioProcedimientosClientesComponent, data: { title: 'Conoce nuestros servicios.'} },
  { path: 'cumpleanos-clientes/:clientSubDomain', component: ListaClientesCumpleanosComponent, data: { title: 'Listado de clientes que cumplen años.'} },
  { path: 'api/dashboard/external', component: DashboardApiComponent, data: { title: 'Analitica de datos API.'} },
  { path: 'terminosycondiciones', component: TerminosYCondicionesComponent, data: { title: 'Terminos y condiciones'} },
  { path: 'politica-de-privacidad', component: PoliticaTratamientoDeDatosComponent, data: { title: 'Política De Tratamiento De Datos Personales'} },
  { path: 'eliminar-cuenta', component: EliminarCuentaComponent, data: { title: 'Eliminar cuenta'} },
  { path: '404', component: Error404Component, data: { title: '404 página no encontrada.'} },
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

// es el oficial se prueba integracion de curso angular avanzado con el export const
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    PagesAdminWebRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// export const APP_ROUTES = RouterModule.forRoot( routes, { useHash: true } );
// export const APP_ROUTES = RouterModule.forRoot( routes );

