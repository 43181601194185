import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import 'moment-duration-format';

import { Constants } from '../../../../../providers/constants/constants';
import { ToolsService } from '../../../../services/tools.service';
import { ProcedureService } from '../../../../services/procedure.service';
@Component({
  selector: 'app-searchtypesofprocedures',
  templateUrl: './searchtypesofprocedures.component.html',
  styleUrls: ['./searchtypesofprocedures.component.scss']
})
export class SearchtypesofproceduresComponent implements OnInit {
  moment = moment;
  constants = Constants;
  skeletonList = this.constants.skeletonList;
  tipoServicio = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones.tipo_servicio;
  searchProcedures = '';
  typesProcedures: any = [];
  pageSkip = 0;
  pageSize = 15;
  private timeoutSearch: any;
  constructor(
    private router: Router,
    private toolsService: ToolsService,
    private procedureService: ProcedureService,
  ) { }

  ngOnInit(): void {
    this.typesProcedures = [];
    this.pageSkip = 0;
    this.getProceduresSearch();
  }

  /**
   * @description se activa cuando cambia el change del buscador de procedimientos
   */
  searchProceduresChange(event: any) {
    this.searchProcedures = event.target.value;
    if(this.searchProcedures.length > 1 && this.searchProcedures.length < 3) return;
    clearTimeout(this.timeoutSearch);
    this.typesProcedures = [];
    this.pageSkip = 0;
    this.timeoutSearch = setTimeout(() => {
      this.getProceduresSearch();
    }, 300);
  }

  /**
   * @description Se hace llamado al web services que busca los tipos de procedimientos
   */
  getProceduresSearch(event = null) {
    this.typesProcedures = [];
    // esta es la guia de la data con buscador
    // const data = JSON.stringify(
    //   {
    //     usuarios: { estados: ['activo', 'inactivo'], texto: this.searchUsers },
    //     paginador: {
    //       limit: {
    //         skip: this.pageSkip,
    //         size: this.pageSize
    //       },
    //       order: {
    //         nombres: 1,
    //         apellidos: 1
    //       }
    //     }
    //   }
    // );
    const data = JSON.stringify({ tipos: [this.tipoServicio], nombre: this.searchProcedures });
    this.procedureService.getProcedureSearch(data)
      .subscribe(response => {
        const typeResponse = response['type'];
        const lengthData = response['data'].length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.typesProcedures = this.typesProcedures.concat(response['data']);
          this.pageSkip = this.typesProcedures.length;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response['message'].messageDescription);
        } else if (event === null) {
          this.typesProcedures = null;
        }
        // si es llamado desde el evento del infinite-scroll
        if (event) {
          event.target.complete();
          // this.ionInfiniteScroll.disabled = false;
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  goToViewCreateAndEditProcedures(procedure: any = null) {
    let idProcedure = {};
    let urlRedirect = '/admin/tiposdeprocedimientos/crear';
    if (procedure) {
      idProcedure = { _id: procedure._id };
      urlRedirect = '/admin/tiposdeprocedimientos/editar';
    }
    this.router.navigate([urlRedirect, idProcedure]);
  }
}
