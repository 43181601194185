import { Component, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Constants } from '../../../providers/constants/constants';
import { Subscription } from 'rxjs';

// Redux
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';

@Component({
  selector: 'app-social-networks-icons',
  templateUrl: './social-networks-icons.component.html',
  styleUrls: ['./social-networks-icons.component.scss']
})
export class SocialNetworksIconsComponent implements OnInit, OnDestroy {
  constants = Constants;
  dataPersonalizationClientSubs: Subscription;
  dataPersonalizationClient: any;
  dataRedesSociales: any = this.constants.socialMedia;
  // datos interfaz personalización cliente
  colorInfoAgendateApp = this.constants.colors.info;
  constructor(
    private store: Store<AppState>,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.dataPersonalizationClientSubs = this.store.select('personalizationClient').subscribe(({data}) => {
      if(data !== null){
        this.dataPersonalizationClient = data;
        this.dataRedesSociales = data.redes_sociales;
        this.initPersonalization();
      }
    });
  }

  ngOnDestroy() {
    this.dataPersonalizationClientSubs?.unsubscribe();
  }

  initPersonalization(){
    const colorInfo = (this.dataPersonalizationClient.color_principal !== '') ? `#${this.dataPersonalizationClient.color_principal}` : this.colorInfoAgendateApp;
    this.elementRef.nativeElement.style.setProperty('--info', colorInfo);
  }
}
