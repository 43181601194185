import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { Constants } from '../../../../providers/constants/constants';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  constants = Constants;
  verifiedAccount = false;
  idCode: string;
  fullNameUser: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
    // obtengo los datos que lleguen por parametro
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.idCode) {
        this.idCode = params.idCode;
        this.confirmationAccount();
      }
    });
  }

  confirmationAccount() {
    this.accountService.postConfirmationAccount({ param: this.idCode }).subscribe((response: any) => {
      const typeResponse = response.type;
      const dataResponse = response.data;
      if (typeResponse === this.constants.services.types.success && dataResponse) {
        const name = dataResponse.nombres;
        const lastName = dataResponse.apellido;
        this.fullNameUser = (lastName !== null && lastName !== '') ? name : `${name} ${lastName}`;
        this.verifiedAccount = true;
      } else if (typeResponse === this.constants.services.types.error) {
        this.verifiedAccount = false;
      }
    }, err => {
      console.error(err);
      this.verifiedAccount = false;
    }, () => {
    });
  }

}
