import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Constants } from '../../../../providers/constants/constants';
import { PasswordValidator } from '../../../validators/password.validator';
import { AuthService } from '../../../services/auth.service';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.scss']
})
export class RecoverpasswordComponent implements OnInit {
  constants = Constants;
  // Group de validaciones para el formulario de login
  validationFormEmailRecoverPassword: FormGroup;
  validationFormChangePassword: FormGroup;
  matching_passwords_group: FormGroup;
  constructor(
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private toolsService: ToolsService,
  ) { }

  ngOnInit() {
    this.validatorFormEmailRecoverPassword();
    this.validatorFormChangePassword();
  }

  validatorFormEmailRecoverPassword() {
    this.validationFormEmailRecoverPassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  validatorFormChangePassword() {
    this.matching_passwords_group = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup);
    });

    this.validationFormChangePassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.required]],
      password: this.matching_passwords_group
    });
  }

  /**
   * @description servicio que valida el email ingresado y envia código
   */
  emailRecoverPassword() {
    const self = this;
    this.authService.recoverPassword(this.validationFormEmailRecoverPassword.value)
      .subscribe(response => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          // notifico al usuario del envio del código de recuperacion
          this.toolsService.alertNotificacionSimple(this.constants.mensajesInformativos.notificationEmailRecoverPassword);
          // seteo email a el formulario de restablecer contraseña
          const email = { email: this.validationFormEmailRecoverPassword.get('email').value };
          this.validationFormChangePassword.patchValue(email);
          this.validationFormEmailRecoverPassword.reset();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        self.validationFormEmailRecoverPassword.get('email').reset();
      }, () => {
      });
  }

  changePassword() {
    const self = this;
    const data = {
      email: this.validationFormChangePassword.get('email').value.trim(),
      code: this.validationFormChangePassword.get('code').value.trim(),
      password: this.validationFormChangePassword.get('password.password').value
    };
    this.authService.changePassword(data)
      .subscribe(response => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          // notifico al usuario del envio del código de recuperacion
          this.toolsService.alertNotificacionSimple(this.constants.mensajesInformativos.notificationChangePasswordSuccess, '/cuenta/ingresar');
          this.validationFormChangePassword.reset();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        self.validationFormChangePassword.reset();
      }, () => {
      });
  }
}
