import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Constants } from '../../../../providers/constants/constants';
import { AuthService } from '../../../services/auth.service';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constants = Constants;
  // Group de validaciones para el formulario de login
  validationFormLogin: FormGroup;
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    public authService: AuthService,
    public toolsService: ToolsService,
    // public userService: UserService
  ) { }

  ngOnInit() {
    this.validatorFormLogin();
    this.validateActiveSession();
    // Pruebas
    // const dataUser = {
    //   email: 'jmanuelviverosm@gmail.com',
    //   password: '1qaz2wsX'
    // };
    // this.validationFormLogin.patchValue(dataUser);
  }

  ionViewDidEnter() {
  }

  /**
   * @description valiudaciones para el formulario de login
   */
  validatorFormLogin() {
    this.validationFormLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  validateActiveSession() {
    // si existe un token de sesion pasa al home
    if (localStorage.getItem(this.constants.localStorage.token) && localStorage.getItem(this.constants.localStorage.token) !== 'undefined') {
      const idOrganization = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones._id;
      if(idOrganization === '5ed433d811831630f2006e57'){
        this.router.navigate(['/admin/api/dashboard']);
      } else {
        this.router.navigate(['/admin/citas']);
      }
    }
  }

  /**
   * @description llamado al web service que hace el login
   */
  async login() {
    const self = this;
    this.authService.login(this.validationFormLogin.value).subscribe((response: any) => {
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        localStorage.setItem(this.constants.localStorage.token, response.data.token);
        localStorage.setItem(this.constants.localStorage.user, JSON.stringify(response.data.user));
        this.toolsService.addAndRemoveCssAdminWeb();
        // setTimeout(() => {
          this.validationFormLogin.reset();
          if(response.data.user.organizaciones._id == '5ed433d811831630f2006e57'){
            this.router.navigate(['/admin/api/dashboard']);
          } else {
            this.router.navigate(['/admin/citas']);
          }

        // }, 200);
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.supportTeam.nameKeyTeam);
      }
    }, err => {
      self.validationFormLogin.get('password').reset();
      this.toolsService.statusService(err);
    }, () => {
    });
  }
}
