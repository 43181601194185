import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {

  constructor(public Api: ApiService) { }

  getDashboardInfo(data: any) {
    return this.Api.post('/dashboard/info', data);
  }
}
