<ul class="list-inline social-icon">
  <li class="list-inline-item" *ngIf="dataRedesSociales?.instagram">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.instagram" target="_blank">
      <i class="fab fa-instagram"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.facebook">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.facebook" target="_blank">
      <i class="fab fa-facebook-f"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.whatsapp">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.whatsapp" target="_blank">
      <i class="fab fa-whatsapp"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.youtube">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.youtube" target="_blank">
      <i class="fab fa-youtube"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.linkedin">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.linkedin" target="_blank">
      <i class="fab fa-linkedin-in"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.twitter">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.twitter" target="_blank">
      <i class="fab fa-twitter"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.googleBusiness">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.googleBusiness" target="_blank">
      <i class="fas fa-map-marked-alt"></i>
    </a>
  </li>
  <li class="list-inline-item" *ngIf="dataRedesSociales?.tiktok">
    <a class="{{ constants.gtm.css.contact }}" [href]="dataRedesSociales?.tiktok" target="_blank">
      <i class="fab fa-tiktok"></i>
    </a>
  </li>
</ul>
