import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
// Redux
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: []
})
export class BreadcrumbsComponent implements OnDestroy {
  public dataSeoPage$: Observable<any> = this.store.select( (state) => state.dataSeoPage.data );

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnDestroy(): void {
  }

}
