import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
// Modulos
import { SharedModule } from '../../shared/shared.module';

import { AdminWebComponent } from './admin-web.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminusersComponent } from './users/adminusers/adminusers.component';
import { CreateandeditusersComponent } from './users/createandeditusers/createandeditusers.component';
import { SearchtypesofproceduresComponent } from './typesofprocedures/searchtypesofprocedures/searchtypesofprocedures.component';
import { CreateandeditproceduresComponent } from './typesofprocedures/createandeditprocedures/createandeditprocedures.component';
import { GestionarCitasComponent } from './citas/gestionar-citas/gestionar-citas.component';
import { CreateAppointmentComponent } from './citas/create-appointment/create-appointment.component';
import { PlandetailComponent } from './account/plandetail/plandetail.component';
import { DashboardApiComponent } from './api/dashboard-api/dashboard-api.component';
import { BusinesshoursComponent } from './businesshours/businesshours.component';

@NgModule({
  declarations: [
    AdminWebComponent,
    DashboardComponent,
    AdminusersComponent,
    CreateandeditusersComponent,
    SearchtypesofproceduresComponent,
    CreateandeditproceduresComponent,
    GestionarCitasComponent,
    CreateAppointmentComponent,
    PlandetailComponent,
    DashboardApiComponent,
    BusinesshoursComponent,
  ],
  exports: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    NgbModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})

export class AdminWebModule { }
