import { createAction, props } from '@ngrx/store';
import { IPersonalizationClient } from '../../interfaces/IPersonalizationClient';

export const triggerPersonalizationClientInit = createAction(
  '[Personalizacion Cliente] => triggerPersonalizationClientInit'
);

export const triggerPersonalizationClientSuccess = createAction(
  '[Personalizacion Cliente] => triggerPersonalizationClientSuccess',
  props<{ personalizationCliente: IPersonalizationClient }>()
);


