<div class="content-wrapper-area p-4 bg-white d-flex justify-content-between align-items-center">
   <!-- <a class="logo" [routerLink]="['/']"> -->
    <img loading="lazy" class="logo" [src]="logoCliente" alt="Logo empresa {{appointmentData.nameOrganization}}">
  <!-- </a> -->

  <div class="main-content pt-2">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-6 p-0">
          <ng-container *ngIf="verifiedAppointment">
            <h4 class="titulos">Hola, {{ appointmentData.nameClient }}.</h4>
            <h5 class="titulos">Bienvenido a {{ dataOrganizacion?.organization }}.</h5>
            <p class="parrafos">Tu cita para <span class="titulos text-uppercase"
                id="contProcedures">{{ appointmentData.procedures }}</span>, fue programada de forma exitosa.<br>A
              continuación, te entregamos toda la
              información que necesitas para asistir.</p>
            <p class="parrafos text-left">
              <strong class="text-primary">Nombre del Afiliado: </strong>
              {{ appointmentData.nameClient | uppercase }}<br>
              <strong class="text-primary">Fecha de la cita: </strong> {{ appointmentData.dateAppointment }}<br>
              <strong class="text-primary">Hora de la cita: </strong> {{ appointmentData.hourAppointment }}<br>
              <strong class="text-primary">Servicio: </strong> {{ appointmentData.procedures }}<br>
              <ng-container *ngIf="descripcionProcedimiento">{{ descripcionProcedimiento }}<br></ng-container>
              <ng-container *ngIf="valorDelProcedimiento"><strong class="text-primary">Valor: </strong> {{ valorDelProcedimiento | currency:'COP':'$':'1.0-0':'es-ES' }}<br></ng-container>
              <ng-container *ngIf="tiempoProcedimiento"><strong class="text-primary">Duración: </strong> {{ moment.duration(tiempoProcedimiento, 'minutes').format('HH:mm') }}<br></ng-container>
              <!-- <ng-container *ngIf="tiempoProcedimiento"><strong class="text-primary">Duración: </strong> {{ moment.duration(tiempoProcedimiento, 'minutes').locale('es').humanize() }}<br></ng-container> -->
              <strong class="text-primary">Profesional a cargo: </strong>
              {{ appointmentData.nameProfessional | uppercase }}<br>
              <strong class="text-primary">Lugar para tu atención: </strong>
              {{ appointmentData.nameOrganization | uppercase }}, {{ appointmentData.addressOrganization | uppercase }}
              <br>
              <strong class="text-primary">Celular: </strong> {{ appointmentData.mobileOrganization }}<br>
              <strong class="text-primary">Teléfono: </strong> {{ appointmentData.phoneOrganization }}<br>
              <strong class="text-primary">Nota: </strong> {{ appointmentData.note }}<br>
            </p>

            <p *ngIf="isNotificationAppointmentConfirmed" class="titulos text-center">
              <img loading="lazy" src="/assets/svg/good.svg" class="mb-3" width="50px"><br>
              Tu cita fue confirmada exitosamente.
            </p>

            <p *ngIf="isNotificationAppointmentCanceled" class="titulos text-center text-danger">
              <img loading="lazy" src="/assets/svg/good.svg" class="mb-3" width="50px"><br>
              Tu cita fue cancelada exitosamente.
            </p>

            <p *ngIf="isNotificationAppointmentStateCanceled" class="titulos text-center text-danger">
              <img loading="lazy" src="/assets/svg/support.svg" class="mb-3" width="50px"><br>
              Tu cita está cancelada, contactanos para recibir más información.
            </p>
            <!--contenedor info confirmar o cancelar cita-->
            <div *ngIf="showInfoConfirmationCancelAppointment" class="mb-5">
              <p class="parrafos">
                Una cita es un compromiso tuyo y nuestro, si no puedes asistir es importante que nos
                avises, recuerda que hay un profesional esperando y otra persona podría
                necesitarla.
              </p>

              <p class="parrafos text-center">De clic para confirmar o cancelar la cita:</p>
              <div class="text-center">
                <button aria-label="Confirmar" type="button" (click)="confirmationAppointment()" *ngIf="showBtnConfirmationAppointment"
                  class="btn btn-primary btn-lg rounded-pill mr-3">Confirmar</button>
                <button aria-label="Cancelar" type="button" (click)="activeCancelAppointment()" *ngIf="!contCancelAppointment"
                  class="btn btn-outline-danger btn-lg rounded-pill">Cancelar</button>
              </div>

              <div *ngIf="contCancelAppointment" class="parrafos w-100" style="margin-top: 25px;">
                <form style="margin-top: 3rem;" [formGroup]="formCancelAppointment" (ngSubmit)="cancelAppointment()">
                  Campo obligatorio *
                  <textarea class="mb-3" formControlName="nota" cols="30" rows="10"
                    placeholder="Motivo por el cual cancela la cita"></textarea>
                  <div class="w-100 text-center">
                    <button aria-label="Cancelar cita" class="btn btn-outline-danger btn-lg rounded-pill" type="submit"
                      [disabled]="!formCancelAppointment.valid">
                      Cancelar cita
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <p *ngIf="!contAdditionalInformationAppointment" class="parrafos">
              Si se te presenta algún imprevisto, recuerda notificarlo con un mínimo de 2 horas de anticipación.
              <br>
              Recuerda llegar entre 15 y 20 minutos antes y presentarte en el punto de atención.
            </p>

            <!-- metodos de pago -->
            <div class="row py-3 border-top" *ngIf="dataPersonalizacion?.metodos_pago">
              <div class="col-12">
                <div class="cont-metodospago">
                  <div class="titulos mb-3">Metodos de pago aceptados en {{ dataOrganizacion?.organization }}</div>
                  <img *ngFor="let metodoDePago of dataPersonalizacion?.metodos_pago" [src]="metodoDePago.url" [alt]="metodoDePago.nombre" loading="lazy">
                </div>
              </div>
            </div>
            <!-- Fin metodos de pago -->
          </ng-container>

          <ng-container *ngIf="!verifiedAppointment">
            <h4 class="titulos">Hola, Bienvenido a Agéndate App.</h4>
            <p class="parrafos">La url a la que estas ingresando no es valida, verifica la url que fue enviada al correo
              electrónico.</p>
            <p class="parrafos">Atentamente.<br>Equipo de Agéndate App</p>
          </ng-container>
        </div>
        <div class="col-12 col-md-5 p-0"><img loading="lazy" class="ilustracionPerson" src="/assets/svg/personCalendar.svg"
            alt="Confirmación cita"></div>
      </div>
    </div>
  </div>

  <div class="footer_bottom text-center text-primary text--normal text--xl">
    <span class="" *ngIf="dataPersonalizacion?.texto_destacado">{{ dataPersonalizacion.texto_destacado }}</span>
    <p class="mb-2">Siguenos en nuestras redes sociales</p>
    <app-social-networks-icons></app-social-networks-icons>
  </div>
</div>
