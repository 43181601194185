<div style="background: #f4f6f9;">
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row">
  <div class="col-12">
    <form class="col-12 px-0 forms" [formGroup]="validationsForm">
      <div class="card bg-dark-gray-2">
        <div class="p-2">
          <div class="d-flex">
            <div>
              <!-- <h4 class="card-title"><span class="lstick"></span>Projects of the Month</h4> -->
            </div>
            <div class="ml-auto">
              <select formControlName="dateType" (change)="changeDateType()" class="custom-select b-0">
                <option *ngFor="let filterDate of constants.filtersDate" [value]="filterDate.value">{{ filterDate.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-12 col-lg-4 px-0">
    <div class="col-6 col-md-4 col-lg-12 d-inline-block">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class="m-r-20 align-self-center"><span class="lstick bg-primary m-r-20"></span><i class="text--lgxs ti-comments"></i></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Total notificaciones</h6>
                      <h3 class="m-t-0">{{ dataGeneralConsumptions?.creditos?.totales }}</h3></div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-6 col-md-4 col-lg-12 d-inline-block">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class="m-r-20 align-self-center"><span class="lstick bg-success m-r-20"></span><i class="text--lgxs ti-comment"></i></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Disponibles</h6>
                      <h3 class="m-t-0">{{ dataGeneralConsumptions?.creditos?.disponibles }}</h3></div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-6 col-md-4 col-lg-12 d-inline-block">
      <div class="card">
          <div class="card-body">
              <div class="d-flex no-block">
                  <div class="m-r-20 align-self-center"><span class="lstick bg-danger m-r-20"></span><i class="text--lgxs ti-comment-alt"></i></div>
                  <div class="align-self-center">
                      <h6 class="text-muted text--sm m-t-10 m-b-0">Consumidas</h6>
                      <h3 class="m-t-0">{{ dataGeneralConsumptions?.creditos?.consumidas }}</h3></div>
              </div>
          </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div><h5 class="card-title"><span class="lstick bg-primary"></span>Notificaciones con enlaces</h5></div>
          <div class="ml-auto"></div>
        </div>
        <div class="col align-self-center">
          <div id="contChartNotificationsWithLinks" style="display: block; width: 250px; height: 300px;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div><h5 class="card-title"><span class="lstick bg-primary"></span>Estado de notificaciones </h5></div>
          <div class="ml-auto"></div>
        </div>
        <div class="col align-self-center">
          <div id="contChartFailedAndSuccessfulNotifications" style="display: block; width: 250px; height: 300px;"></div>
          <!-- <highcharts-chart [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartOptions"
            style="display: block; width: 250px; height: 300px;"></highcharts-chart> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div><h5 class="card-title"><span class="lstick bg-primary"></span>Notificaciones enviadas</h5></div>
          <div class="ml-auto"></div>
        </div>
        <!-- <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="textB" class="mb-0"><small>Buscar:</small></label>
              <input type="text" id="textB" placeholder="Mensaje" class="form-control" [(ngModel)]="searchConsumptions" (keyup)="searchConsumptionsChange($event)">
            </div>
          </div>
        </div> -->
        <ng-template [ngIf]="dataConsumptions.length == 0">
          <p lines="none">
            No se encontraron notificaciones enviadas
          </p>
        </ng-template>

        <ng-template [ngIf]="dataConsumptions.length > 0">
          <div class="table-responsive">
            <table id="demo-foo-addrow" class="table vm table-hover " data-page-size="10">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Fecha creación</th>
                  <th class="text-center">Número</th>
                  <th class="text-center">Mensaje</th>
                  <!-- <th class="text-center">Estado</th> -->
                  <th class="text-center">Abierto</th>
                  <th class="text-center">Créditos</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let consumption of dataConsumptions; let i = index" [ngClass]="{'table-success': consumption?.estadisticas?.abierto > 0}">
                  <td class="text-center">{{ (i + 1) }}</td>
                  <td class="text-center">
                    {{ moment(consumption.creado_en).format('YYYY-MM-DD hh:mm A') }}
                    <!-- {{ consumption.creado_en | date:"dd/mm/yyyy"}} -->
                  </td>
                  <td class="text-center">{{ consumption.identificador }}</td>
                  <td>{{ consumption.contenido.message }}</td>
                  <!-- <td class="text-center">{{ consumption.estado }}</td> -->
                  <td class="text-center" tooltip="Cantidad de veces que ha sido abierto el enlace" placement="top">{{ consumption?.estadisticas?.abierto }}</td>
                  <td *ngIf="consumption?.estado == '200'" class="text-center" tooltip="Cantidad de creditos consumidos de esta notificación">{{ consumption.unidades }}</td>
                  <td *ngIf="consumption?.estado != '200'" class="text-center" tooltip="Cantidad de creditos consumidos de esta notificación">0</td>
                </tr>

                    <!--
                    <td>{{ user.email }}</td>
                    <td>{{ user.celulares }}</td>
                    <td>{{ user.roles. nombre}}</td>
                    <td class="text-center">
                      <a href="JavaScript:void(0)" class="btn link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [tooltip]="constants.tooltips.btns.actions" placement="top">
                        <i class="fas fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="goToViewCreateAndEditUsers(user);"><i class="far fa-edit mr-1"></i> Editar usuario</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="toolsService.messageWhatsapp(user.celulares);" ><i class="fab fa-whatsapp mr-1"></i> Mensaje</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="toolsService.openCall(user.celulares)" ><i class="ti-mobile mr-1"></i>Llamar</a>
                      </div>
                    </td>
                  </tr>-->
              </tbody>
            </table>
            <div class="row">
              <div class="col-12 text-center mb-3">
                <button aria-label="Cargar más" type="button" class="btn btn-primary btn-rounded" (click)="getConsumptions(true)">
                  <i class="ti-plus"></i> Cargar más
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End PAge Content -->
<!-- ============================================================== -->
</div>
