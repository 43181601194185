<div class="row align-items-center bd-highlight py-2 px-3 my-3 my-md-0">
  <div class="col-6 col-md-2 col-lg-3">
    <a [routerLink]="['/']">
      <img loading="lazy" src="./assets/img/logos/logoLogin.svg" class="d-inline-block logoCliente" width="150" alt="">
    </a>
  </div>
  <div class="col-12 col-md-8 col-lg-6 order-12 order-md-1">
    <h1 class="titulos text-center">Solicitud de eliminación de cuenta</h1>
  </div>
</div>

<section class="bg-dark-gray-2 pt-3 h-vh-100">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h5 class="text-primary">Cómo solicitar la eliminación de tu cuenta en Agéndate App</h5>

        <p class="text-justify">Si deseas eliminar tu cuenta en Agéndate App, sigue estos pasos para realizar la solicitud correctamente:</p>

        <h5 class="text-primary">1.	Envía un correo de solicitud</h5>
        <p class="text-justify">
          Desde la dirección de correo electrónico registrada en la plataforma, envía un mensaje a <strong>gerencia.agendate@gmail.com</strong> con el asunto: <strong>Solicitud de eliminación de cuenta.</strong>
        </p>

        <p class="text-justify">En el cuerpo del mensaje, indica tu nombre y confirma tu deseo de eliminar la cuenta.</p>

        <h5 class="text-primary">2.	Validación de identidad</h5>

        <p class="text-justify">El equipo de soporte verificará que la cuenta existe y que eres el propietario.</p>

        <h5 class="text-primary">3.	Tiempo de respuesta</h5>

        <p class="text-justify">La solicitud será procesada en un plazo de <strong>3 días hábiles.</strong></p>

        <h5 class="text-primary">4.	Confirmación de eliminación</h5>

        <p class="text-justify">Si la validación es exitosa, tu cuenta será eliminada y recibirás una confirmación por correo.</p>

        <p class="text-justify"><strong>Nota importante:</strong> Una vez eliminada la cuenta, se borrarán de forma permanente todos los registros asociados, incluyendo clientes, citas, servicios, procedimientos, horarios y demás datos vinculados a la cuenta y sus usuarios. Esta acción es irreversible.</p>

        <p class="text-justify">Asegúrate de enviar la solicitud desde el mismo correo con el que te registraste en Agéndate App para evitar demoras en el proceso.</p>
      </div>
    </div>
  </div>
</section>
