import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(public Api: ApiService) { }

  getNotificationbirthday(data: any) {
    return this.Api.post('/system/get/notificationbirthday', data);
  }
}
