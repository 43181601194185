import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { Router } from '@angular/router';
import * as moment from 'moment';
var Highcharts = require('highcharts');
import { Constants } from '../../../../../providers/constants/constants';
import { ConsumptionsService } from '../../../../services/api/consumptions.service';
import { ToolsService } from '../../../../services/tools.service';
import { SNotificationService } from '../../../../services/sockets/s-notification.service';
import { Subscription } from 'rxjs';
// Redux
import { Store } from '@ngrx/store';
import * as consumptionReportApiActions from '../../../../store/actions/consumptionReportApi.actions';
import { AppState } from '../../../../store/app.reducers';

export function getAlertConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'top',
    container: 'body',
    delay: 500
  });
}
@Component({
  selector: 'app-dashboard-api',
  templateUrl: './dashboard-api.component.html',
  styleUrls: ['./dashboard-api.component.scss'],
  providers: [{ provide: TooltipConfig, useValue: undefined }]
})
export class DashboardApiComponent implements OnInit, OnDestroy {
  constants = Constants;
  moment = moment;
  validationsForm: FormGroup;
  consumptionsSubs: Subscription;
  generalConsumptionsSubs: Subscription;
  notificationsApiSubs: Subscription;
  idOrganization = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones._id;
  dateTimestampStart: number;
  dateTimestampEnd: number;
  dataConsumptions: any = [];
  dataGeneralConsumptions: any;
  searchConsumptions: string = '';
  pageSkip: number = 0;
  pageSize: number = 15;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    public formBuilder: FormBuilder,
    private consumptionsService: ConsumptionsService,
    private toolsService: ToolsService,
    private sNotificationService: SNotificationService,
  ) {
  }

  ngOnInit(): void {
    this.sNotificationService.connectRoom(this.idOrganization);

    if(this.router.url === '/api/dashboard/external'){
      this.toolsService.addAndRemoveCssAdminWeb();
    }

    this.pageSkip = 0;
    this.validatorsFormDates();
    this.getNotificationApi();
  }

  ngOnDestroy() {
    try {
      this.generalConsumptionsSubs?.unsubscribe();
      this.consumptionsSubs?.unsubscribe();
      this.notificationsApiSubs?.unsubscribe();
    } catch (error) {
      console.log('Error en el ngOnDestroy dasboard API');
      console.log(error);
    }
  }

  getNotificationApi(){
    this.notificationsApiSubs = this.sNotificationService.getMessageNotificationApi().subscribe((response: any) => {
      console.log(response);
      if(response?.total){
        this.toolsService.presentToast(`Se acaban de enviar ${response.total} notificaciones.`);
        this.getDataAllWs();
      }

      if(response?.nuevo == "true"){
        this.toolsService.presentToast(`Se acaba de abrir un enlace de una notificación.`);
        this.getDataAllWs();
      } else {
        if(response?.id){
          const index = this.dataConsumptions.findIndex(consumptions => consumptions._id === response.id.replace(/"/g, ""));
          // si encuentra el registro actualiza el total de abiertos
          if(index !== -1){
            this.toolsService.presentToast(`Se acaba de abrir un enlace de una notificación.`);
            this.dataConsumptions[index].estadisticas.abierto = response.total_abierto;
          }
        }
      }

    });
  }

  validatorsFormDates() {
    this.validationsForm = this.formBuilder.group({
      dateType: ['', [Validators.required]],
    });
    this.validationsForm.controls.dateType.setValue(30);
    this.changeDateType();
  }

  changeDateType(){
    const dateHourStart = moment('000', 'hmm').format('HH:mm:ss.SSS');
    const dateHourEnd = moment('2359', 'hmm').format('HH:mm:ss.SSS');
    const currentDate = moment().format('YYYY-MM-DD');
    const dateType = parseInt(this.validationsForm.get('dateType').value);
    this.dateTimestampStart = moment(`${currentDate}T${dateHourStart}`).subtract(dateType, 'days').valueOf();
    this.dateTimestampEnd = moment(`${currentDate}T${dateHourEnd}`).valueOf();
    // console.log(this.dateTimestampStart);
    // console.log(this.dateTimestampEnd);
    // console.log(moment(this.dateTimestampStart).format('YYYY-MM-DD HH:mm'));
    // console.log(moment(this.dateTimestampEnd).format('YYYY-MM-DD HH:mm'));
    this.getDataAllWs();
  }

  getDataAllWs(){
    this.pageSkip = 0;
    this.dataConsumptions = [];
    this.getGeneralConsumptions();
  }

  /**
   * @description se activa cuando cambia el change del buscador de notificaciones enviadas
   */
   searchConsumptionsChange(event: any) {
    this.searchConsumptions = event.target.value;
    this.dataConsumptions = [];
    this.pageSkip = 0;
    setTimeout(() => {
      this.getConsumptions();
    }, 300);
  }

  getGeneralConsumptions(){
    this.store.dispatch(consumptionReportApiActions.consumptionReportApiInit());
    const data = JSON.stringify(
      {
        tipo_notificacion: 'SMS',
        fechas: {
          fecha_ini: this.dateTimestampStart,
          fecha_fin: this.dateTimestampEnd
        }
      }
    );
    this.generalConsumptionsSubs = this.consumptionsService.getGeneralConsumptions(this.idOrganization, data).subscribe((response: any) => {
        const typeResponse = response.type;
        // const lengthData = response['data'].length;
        if (typeResponse === this.constants.services.types.success) {
          this.dataGeneralConsumptions = response.data;
          this.store.dispatch(consumptionReportApiActions.consumptionReportApiSuccess({ consumptionReportApi: response.data }));
          this.chartNotificationsWithLinks();
          this.chartFailedAndSuccessfulNotifications();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
        this.getConsumptions();
      });
  }

  getConsumptions(event = null){
    const data = JSON.stringify(
      {
        // EL DE USUARIOS ES UNA GUIA PARA EL BUSCADOR EN LA PROPIEDAD TEXTO
        // usuarios: { estados: ['activo', 'inactivo'], texto: this.searchConsumptions },
        // tipo_notificacion: "SMS",
        // identificador: "3024664249",
        // tipo_contenido: "",
        // identificador_externo: "",
        paginador: {
          limit: {
            skip: this.pageSkip,
            size: this.pageSize
          },
          order: {
            creado_en: -1
          }
        },
        fechas: {
          fecha_ini: this.dateTimestampStart,
          fecha_fin: this.dateTimestampEnd
        }
      }
    );
    this.consumptionsSubs = this.consumptionsService.getConsumptions(this.idOrganization, data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response['data'].length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.dataConsumptions = this.dataConsumptions.concat(response['data']);
          this.pageSkip = this.dataConsumptions.length;
          // setTimeout(() => {
          //   this.dataConsumptions[0].estadisticas.abierto = 7;
          // }, 3000);
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        } else if (event && typeResponse === this.constants.services.types.success && lengthData === 0) {
          this.toolsService.presentToast('No se encontraron más notificaciones enviadas', this.constants.iconsSweetalert.info);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  chartNotificationsWithLinks() {
    // Build the chart
    Highcharts.chart('contChartNotificationsWithLinks', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: ['#28a745', '#0d233a'],
          dataLabels: {
            format: '<b>{point.percentage:.1f} %',
            enabled: true,
            distance: -50
          },
          showInLegend: true
        }
      },
      series: [{
        name: 'Totales',
        colorByPoint: true,
        data: [{
          name: 'Con enlaces abiertos',
          y: this.dataGeneralConsumptions.notificaciones.con_enlaces_abiertos,
          sliced: true,
          selected: true
        },
        {
          name: 'Con enlaces sin abir',
          y: (this.dataGeneralConsumptions.notificaciones.totales - this.dataGeneralConsumptions.notificaciones.con_enlaces_abiertos),
          sliced: true,
          selected: true
        }]
      }],
      credits: {
        enabled: false
      }
    });
  }

  chartFailedAndSuccessfulNotifications() {
    // Build the chart
    Highcharts.chart('contChartFailedAndSuccessfulNotifications', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: ['#28a745', '#FF0000'],
          dataLabels: {
            format: '<b>{point.percentage:.1f} %',
            enabled: true,
            distance: -50
          },
          showInLegend: true
        }
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'middle'
      // },
      series: [{
        name: 'Totales',
        colorByPoint: true,
        data: [{
          name: 'Existosas',
          y: this.dataGeneralConsumptions.notificaciones.exitosas,
          sliced: true,
          selected: true
        }, {
          name: 'Fallidas',
          y: this.dataGeneralConsumptions.notificaciones.fallidas
        }]
      }],
      credits: {
        enabled: false
      }
    });
  }
}
