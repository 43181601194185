import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../providers/constants/constants';
import { ToolsService } from '../../services/tools.service';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  constants = Constants;
  nameUSer = '';
  emailUser = '';

  constructor(
    private toolsService: ToolsService,
  ) { }

  ngOnInit() {
    this.getDataUser();

    var set = function() {
      (window.innerWidth > 0 ? window.innerWidth : this.screen.width) < 1170 ? ($("body").addClass("mini-sidebar"),
      $(".navbar-brand span").hide(), $(".sidebartoggler i").addClass("ti-menu")) : ($("body").removeClass("mini-sidebar"),
      $(".navbar-brand span").show());
      var height = (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;
      (height -= 0) < 1 && (height = 1), height > 0 && $(".page-wrapper").css("min-height", height + "px");
  };
  $(window).ready(set), $(window).on("resize", set), $(".sidebartoggler").on("click", function() {
      $("body").hasClass("mini-sidebar") ? ($("body").trigger("resize"), $("body").removeClass("mini-sidebar"),
      $(".navbar-brand span").show()) : ($("body").trigger("resize"), $("body").addClass("mini-sidebar"),
      $(".navbar-brand span").hide());
  }), $(".nav-toggler").click(function() {
      $("body").toggleClass("show-sidebar"), $(".nav-toggler i").toggleClass("ti-menu"),
      $(".nav-toggler i").addClass("ti-close");
  }), $(".search-box a, .search-box .app-search .srh-btn").on("click", function() {
      $(".app-search").toggle(200);
  }), $(".right-side-toggle").click(function() {
      $(".right-sidebar").slideDown(50), $(".right-sidebar").toggleClass("shw-rside");
  }), $(".floating-labels .form-control").on("focus blur", function(e) {
      $(this).parents(".form-group").toggleClass("focused", "focus" === e.type || this.value.length > 0);
  }).trigger("blur"), $(function() {
      for (var url = window.location, element = $("ul#sidebarnav a").filter(function() {
          return this.href == url;
      }).addClass("active").parent().addClass("active"); ;) {
          if (!element.is("li")) break;
          element = element.parent().addClass("in").parent().addClass("active");
      }
  })
  }

  getDataUser() {
    if (localStorage.getItem(this.constants.localStorage.token)) {
      this.nameUSer = `${JSON.parse(localStorage.getItem(this.constants.localStorage.user)).nombres}`;
      this.emailUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).email;
    }
  }

  openPage(page: any) {
    this.toolsService.openPage(page);
  }
}
