import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgwWowModule } from 'ngx-wow';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
// import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
// SET LANGUAGE

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CO';
import localeEsExtra from '@angular/common/locales/extra/es-CO';

import { FacebookModule } from 'ngx-facebook';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSpinnerModule } from 'ngx-spinner';

// redux
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './store/app.reducers';
// socket
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// interceptors
import { InterceptorService } from './interceptors/interceptor.service';

import { AppRoutingModule } from './app-routing.module';
// import { APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebAgendateappComponent } from './web-agendateapp/web-agendateapp.component';
import { AccountComponent } from './components/confirmation/account/account.component';
import { AppointmentComponent } from './components/confirmation/appointment/appointment.component';
import { CuentaComponent } from './pages/cuenta/cuenta.component';
import { LoginComponent } from './pages/cuenta/login/login.component';
import { RegisterAccountComponent } from './pages/cuenta/register-account/register-account.component';
import { SocialNetworksIconsComponent } from './components/social-networks-icons/social-networks-icons.component';
import { Error404Component } from './pages/errores/error404/error404.component';
import { AgendarCitaClienteComponent } from './pages/agendar-cita-cliente/agendar-cita-cliente.component';
import { RecoverpasswordComponent } from './pages/cuenta/recoverpassword/recoverpassword.component';
import { ThankYouRegistrationComponent } from './pages/cuenta/thank-you-registration/thank-you-registration.component';
import { ChatFacebookComponent } from './components/chat-facebook/chat-facebook.component';
import { BotonWhatsappComponent } from './components/boton-whatsapp/boton-whatsapp.component';
import { PrecioProcedimientosClientesComponent } from './pages/precio-procedimientos-clientes/precio-procedimientos-clientes.component';
import { ListaClientesCumpleanosComponent } from './pages/lista-clientes-cumpleanos/lista-clientes-cumpleanos.component';
// modulos
import { SharedModule } from './shared/shared.module';
import { AdminWebModule } from './pages/admin-web/admin-web.module';
// import * as $ from 'jquery';
import { environment } from '../environments/environment';
import { TerminosYCondicionesComponent } from './pages/terminos-y-condiciones/terminos-y-condiciones.component';
import { PoliticaTratamientoDeDatosComponent } from './pages/politica-tratamiento-de-datos/politica-tratamiento-de-datos.component';
// declare var $: any;
const config: SocketIoConfig = { url: environment.serverSocket, options: {} };
// REGISTRAR IDIOMA ESPAÑOL EN ANGULAR
registerLocaleData(localeEs, 'es-ES', localeEsExtra);
@NgModule({
  declarations: [
    AppComponent,
    WebAgendateappComponent,
    AccountComponent,
    AppointmentComponent,
    CuentaComponent,
    LoginComponent,
    RegisterAccountComponent,
    SocialNetworksIconsComponent,
    Error404Component,
    AgendarCitaClienteComponent,
    RecoverpasswordComponent,
    ThankYouRegistrationComponent,
    ChatFacebookComponent,
    BotonWhatsappComponent,
    PrecioProcedimientosClientesComponent,
    ListaClientesCumpleanosComponent,
    TerminosYCondicionesComponent,
    PoliticaTratamientoDeDatosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // APP_ROUTES,
    SharedModule,
    AdminWebModule,
    HttpClientModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    SocketIoModule.forRoot(config),
    // SocketIoModule,
    HighchartsChartModule,
    NgwWowModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    NgbModule,
    Angulartics2Module.forRoot(),
    FacebookModule.forRoot()
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
