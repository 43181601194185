import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(public Api: ApiService) { }

  getUsers() {
    return this.Api.get('/user/');
  }

  getUsersSearchPipe(data: any, token: string = null) {
    return this.Api.post('/dashboard/search', data, token).pipe(
      // tap( (res: any) => {
      //   if (res.renew) {
      //     localStorage.setItem(this.constants.localStorage.token, res.token);
      //   }
      // }),
      map( (res: any) => {
        return res.data;
      }),
      catchError( error => of(false))
    );
  }

  getUsersSearch(data: any, token: string = null) {
    // data = encodeURI(data);
    // return this.Api.get(`/account/user/read/${data}`);
    return this.Api.post('/dashboard/search', data, token);
  }

  getUsersSearchAppointment(data: any) {
    return this.Api.post('/dashboard/searchappointment', data);
  }

  postCreateUser(data: any, token: string = null) {
    return this.Api.post('/account/user/create', data, token);
  }

  putUpdateUser(iduser, data: any) {
    return this.Api.put(`/account/user/update/${iduser}`, data);
  }

  deleteUser(iduser) {
    return this.Api.delete(`/account/user/delete/${iduser}`);
  }

  /**
   * @description permite enviar código de verificacion de numero de telefo y tambien permite validar el código
   * @param data data con el numero de telefeno a verificar
   */
  postUserCellVerification(data: any) {
    return this.Api.post('/user/check', data);
  }
}
