<div class="row mx-0 d-flex align-content-between flex-wrap">
  <div class="col-12 d-flex justify-content-between align-items-center px-4 my-3">

    <div>
      <a [routerLink]="['/']" class="">
        <figure class="mb-0">
          <picture>
            <source media="(max-width: 991px)" srcset="./assets/img/logos/logoLogin.svg">
            <source media="(min-width: 992px)" srcset="./assets/img/logos/logoLogin.svg">
            <img loading="lazy" class="w-50" src="./assets/img/logos/logoLogin.svg" border="0" />
          </picture>
        </figure>
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="d-none d-md-flex mr-3 text--normal text--xm text-secondary">¿No tienes una cuenta?</div>
      <a [routerLink]="['/cuenta/registrarme']"
        class="btn text--upper text--gray text--extrabold text--xl btn-primary rounded-pill">Regístrate</a>
    </div>


  </div>
  <div class="d-flex col-11 col-sm-11 col-md-12 col-lg-10 col-xl-8 mx-auto mt-5">


    <div class="row mx-0 mb-4 w-100">
      <div class="col-12 mt-4 mt-md-3 px-0 titulos">Inicia sesión</div>
      <div class="col-12 px-0 text-secondary text--xl my-3">Ingresa tus datos en el siguiente formulario.</div>
      <!-- <div class="col-12 px-0 mb-3">
        <div class="row mx-0 d-flex align-items-center justify-content-md-start justify-content-center">
          <div class="d-flex justify-content-center mr-3">
            <button class="px-4 px-md-5 py-2 btn-blue-fb border-0 border-radius-0 bs-none outline-0 text-center text-white text--xm text--bold text-center">
              <i class="text-white fa-md fab fa-facebook-f mr-2"></i> Facebook
            </button>
          </div>
          <div class="d-flex justify-content-center">
            <button class="px-4 px-md-5 py-2 btn-red-google border-0 border-radius-0 bs-none outline-0 text-center text-white text--xm text--bold text-center">
              <i class="text-white fa-md fab fa-google mr-2"></i> Google+
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 text--blue text--xl mb-4">O utiliza tu correo electrónico</div> -->

      <form class="col-12 col-sm-12 col-md-8 px-0 forms" [formGroup]="validationFormLogin" (ngSubmit)="login()">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 mb-3">
            <label class="labels" for="textCorreoElectronico"><span class="icon-label icon-envelope"></span> Email</label>
            <input type="email" id="textCorreoElectronico" formControlName="email" class="form-control text--xm"
              placeholder="Ingresa tu email">

            <ng-container *ngFor="let validation of constants.validationMessages.email">
              <div class="error-message"
                *ngIf="validationFormLogin.get('email').hasError(validation.type) && (validationFormLogin.get('email').dirty || validationFormLogin.get('email').touched)">
                <i class="fas fa-info-circle"></i> {{ validation.message }}
              </div>
            </ng-container>
          </div>

          <div class="col-12 col-sm-12 col-md-12 mb-3">
            <label class="labels" for="textPassword"><span class="icon-label icon-key"></span> Contraseña</label>
            <input type="password" id="textPassword" formControlName="password" class="form-control text--xm"
              placeholder="Ingresa tu contraseña">

            <ng-container *ngFor="let validation of constants.validationMessages.password">
              <div class="error-message"
                *ngIf="validationFormLogin.get('password').hasError(validation.type) && (validationFormLogin.get('password').dirty || validationFormLogin.get('password').touched)">
                <i class="fas fa-info-circle"></i> {{ validation.message }}
              </div>
            </ng-container>
          </div>

          <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
            <button aria-label="Iniciar sesión" type="submit" [disabled]="!validationFormLogin.valid"
              class="btn btn-lg btn-primary font-weight-bold rounded-pill">Iniciar sesión</button>
          </div>

          <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
              <label class="text--xm pt-1">
                <a [routerLink]="['/cuenta/recoverpassword']" class="text-primary">¿Olvidaste tu contraseña?</a>
              </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
