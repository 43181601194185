<app-verifyphonenumber></app-verifyphonenumber>
<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row page-titles">
  <div class="col-md-6 align-self-center">
    <h4 class="text-primary">{{ (dataSeoPage$ | async)?.title }}</h4>
  </div>
  <div class="col-md-6 align-self-center">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="javascript:void(0)">Inicio</a>
      </li>
      <!-- <li class="breadcrumb-item">pages</li> -->
      <li class="breadcrumb-item active">{{ (dataSeoPage$ | async)?.title }}</li>
    </ol>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
