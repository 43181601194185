<div class="row align-items-center bd-highlight py-2 px-3 my-3 my-md-0">
  <div class="col-6 col-md-2 col-lg-3">
    <img loading="lazy" src="./assets/img/logos/logoLogin.svg" class="d-inline-block logoCliente" width="150" alt="">
  </div>
  <div class="col-12 col-md-8 col-lg-6 order-12 order-md-1">
    <h1 class="titulos text-center">Conoce nuestros servicios</h1>
  </div>
  <div class="col-6 col-md-2 col-lg-3 order-md-2">
    <img *ngIf="dataOrganization?.organization_id !== constants.idOrganizacionAgendate" loading="lazy" [src]="logoCliente" class="d-inline-block float-right logoCliente" alt="">
  </div>
</div>

<section class="bg-dark-gray-2 pt-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="textB" class="mb-0">Buscar:</label>
          <input type="text" id="textB" placeholder="Nombre del servicio" class="form-control" (keyup)="searchProceduresChange($event)">
        </div>
      </div>
    </div>

    <ng-template [ngIf]="typesProcedures === null">
      <p lines="none">
        No se encontraron resultados
      </p>
    </ng-template>

    <ng-template [ngIf]="typesProcedures">
      <div class="row mx-0">

        <div *ngFor="let typeProcedure of typesProcedures; let i = index" class="col-12 bg-white p-2 shadow-sm mb-3 rounded">
          <div class="row mx-0">
            <div class="col-6 col-lg-3 px-0 align-items-center">
              <span class="font-weight-bold">Servicio</span>
              <br>
              <span class="font-weight-light">{{ typeProcedure.nombre }}</span>
            </div>
            <div class="col-12 col-lg-7 mt-2 mt-lg-0 px-0 align-items-center order-12 order-lg-1">
              <span class="font-weight-bold">Detalle</span>
              <br>
              <span class="font-weight-light">{{ typeProcedure.descripcion }}</span>
            </div>
            <div class="col-3 col-lg-1 px-0 align-items-center order-lg-2">
              <span class="font-weight-bold">Duración</span>
              <br>
              <span class="font-weight-light" *ngIf="typeProcedure.tiempo_estimado && typeProcedure.tiempo_estimado < 60"><i class="ti-time"></i> {{ moment.duration(typeProcedure.tiempo_estimado, 'minutes').format("m[m]") }}</span>
              <span *ngIf="typeProcedure.tiempo_estimado && typeProcedure.tiempo_estimado >= 60"><i class="ti-time"></i> {{ moment.duration(typeProcedure.tiempo_estimado, 'minutes').format('HH:mm') }}</span>
            </div>
            <div class="col-3 col-lg-1 px-0 align-items-center order-lg-3">
              <span class="font-weight-bold">Precio</span>
              <br>
              <span class="font-weight-light" *ngIf="typeProcedure?.caracteristicas">{{ typeProcedure?.caracteristicas.valor_servicio.valor | currency:'COP':'$':'1.0-0':'es-ES' }}</span>
            </div>
          </div>
        </div>

      </div>
      <!-- <div class="table-responsive">
        <table id="demo-foo-addrow" class="table vm m-t-30 table-hover no-wrap" data-page-size="10">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px;">No</th>
              <th class="text-left">Nombre</th>
              <th class="text-center" style="width: 100px;">Duración</th>
              <th class="text-center" style="width: 100px;">Precio</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let typeProcedure of typesProcedures; let i = index">
              <td class="text-center">{{ (i + 1) }}</td>
              <td>
                <strong>{{ typeProcedure.nombre }}</strong><br>
                <small>{{ typeProcedure.descripcion }}</small>
              </td>
              <td class="text-center">
                <span *ngIf="typeProcedure.tiempo_estimado && typeProcedure.tiempo_estimado < 60"><i class="ti-time"></i> {{ moment.duration(typeProcedure.tiempo_estimado, 'minutes').format("m[m]") }}</span>
                <span *ngIf="typeProcedure.tiempo_estimado && typeProcedure.tiempo_estimado >= 60"><i class="ti-time"></i> {{ moment.duration(typeProcedure.tiempo_estimado, 'minutes').format('HH:mm') }}</span>
              </td>
              <td class="text-center">
                <span *ngIf="typeProcedure?.caracteristicas">{{ typeProcedure?.caracteristicas.valor_servicio.valor | currency:'COP':'$':'1.0-0':'es-ES' }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </ng-template>

    <!-- metodos de pago -->
    <div class="row pt-3 border-top" *ngIf="dataPersonalization?.metodos_pago">
      <div class="col-12">
        <div class="cont-metodospago">
          <div class="titulos mb-3">Metodos de pago aceptados en {{ dataOrganization?.organization }}</div>
          <img *ngFor="let metodoDePago of dataPersonalization?.metodos_pago" [src]="metodoDePago.url" [alt]="metodoDePago.nombre" loading="lazy">
        </div>
      </div>
    </div>
    <!-- Fin metodos de pago -->

    <div class="row">
      <div class="col-12 text-primary text--normal text--xm text-center mb-lg-3">
        <span *ngIf="dataPersonalization?.texto_destacado">{{ dataPersonalization.texto_destacado }}<br></span>
      </div>
    </div>
    <div class="row mx-0 w-100 justify-content-center pb-4 networks">
      <div class="col-12 text-primary mb-2 text--normal text--xm text-center">
        Siguenos en nuestras redes sociales
      </div>
      <app-social-networks-icons></app-social-networks-icons>
    </div>
  </div>
</section>
