<div class="row align-items-center bd-highlight py-2 px-3 my-3 my-md-0">
  <div class="col-6 col-md-2 col-lg-3">
    <a [routerLink]="['/']">
      <img loading="lazy" src="./assets/img/logos/logoLogin.svg" class="d-inline-block logoCliente" width="150" alt="">
    </a>
  </div>
  <div class="col-12 col-md-8 col-lg-6 order-12 order-md-1">
    <h1 class="titulos text-center">Terminos y condiciones de uso</h1>
  </div>
</div>

<section class="bg-dark-gray-2 pt-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="text-justify">
          Al registrarse en el sistema de Agéndate App (en adelante “Agéndate App” o la “EMPRESA” o el
          “CONTRATISTA”) damos cumplimiento a la Ley 1581 de 2.012 y su Decreto Reglamentario 1377 de
          2013, le informamos que los datos personales de su propiedad, serán tratados, almacenados,
          usados y procesados en nuestra base de datos, cuya finalidad es gestionar el envío de notificaciones
          para su empresa o sus clientes y hacerle partícipe de nuestros eventos promocionales. Agéndate
          App garantiza el ejercicio de los derechos de acceso, rectificación, cancelación y oposición de los
          datos facilitados. Si desea consultar o eliminar sus datos, puedo hacerlo mediante comunicación
          escrita dirigida al correo electrónico gerencia.agendate@gmail.com
        </p>

        <p class="text-justify">
          Las presentes Condiciones de uso establecen el acceso y utilización como Usuario (en adelante,
        “EL CONTRATANTE”) de nuestra Web y aplicación móvil. Si usted se registra en nuestra Web, App
        móvil o simplemente navega, modifica, confirma, cambia o cancela citas a través de nuestra
        plataforma, significa que acepta sin excepción las presentes condiciones de uso. Si no está de
        acuerdo con estas condiciones abstenerse de navegar y/o utilizar la web o app, ya que al hacerlo
        está aceptando estas condiciones de uso.
        </p>

        <h5 class="text-primary">Pagos</h5>

        <p class="text-justify">
          El CONTRATANTE pagará un plan mensual, trimestral, semestral o anual según su elección a
        través de los medios de pago aprobados por el CONTRATISTA, como tarjeta de crédito,
        transferencia Tipo PSE, tarjeta débito, consignación bancaria o transferencia electrónica, Pago vía
        Baloto o efecty. El CONTRATANTE deberá enviar al CONTRATISTA el respectivo comprobante
        como prueba del pago para activar los servicios contratados.
        </p>

        <p class="text-justify">
          Si el plan contratado no es pagado, no se prestarán los servicios ofrecidos por el CONTRATISTA;
        por lo que el plan deberá estar pagado en su totalidad y confirmado a fin de obtener la prestación de
        los servicios, lo anterior deduce que lo que garantiza la prestación del servicio es el pago y
        confirmación del mismo por parte del CONTRATISTA. La falta de pago por parte del CONTRATANTE
        constituye un incumplimiento y libera al CONTRATISTA de cualquier responsabilidad.
        </p>

        <h5 class="text-primary">Cancelación del plan</h5>

        <p class="text-justify">
          El CONTRATANTE podrá cancelar el plan contratado con un límite de 48 horas previas a que
        finalice la vigencia del plan, sin generar ningún costo para el CONTRATANTE, notificando al
        CONTRATISTA de la respectiva novedad. En el caso de no realizarse la cancelación del plan en el
        límite de 48 horas previas, las notificaciones consumidas serán cobradas en su totalidad sólo si el
        plan que tiene contratado esta sin restricción de créditos (La restricción de créditos permite que al
        consumirse el total de notificaciones del plan no se puedan crear, modificar o reenviar notificaciones
        de citas agendadas para sus clientes).
        </p>

        <p class="text-justify">
          El CONTRATISTA generará la respectiva factura por los servicios contratados en el plan, a su vez
        el CONTRATANTE acepta que esta factura le sea enviada por correo electrónico o que esté
        disponible para su visualización o descarga en su cuenta de usuario en la web o app móvil.
        </p>

        <p class="text-justify">
          Cordialmente,<br>
        Equipo de Agéndate App<br>
        <strong>Email:</strong> gerencia.agendate@gmail.com<br>
        <strong>Página web: </strong><a class="text-primary" [routerLink]="['/']">https://agendateapp.com</a><br>
        <strong><a class="text-primary" [routerLink]="['/politica-de-privacidad']">Ver política de tratamiento de datos personales</a></strong>
        </p>
      </div>
    </div>
  </div>
</section>
