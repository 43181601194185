import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ToolsService } from '../services/tools.service';
import { Constants } from '../../providers/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constants = Constants;
  constructor(
    private toolsService: ToolsService,
    // private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    // if (localStorage.getItem(this.constants.localStorage.token) && localStorage.getItem(this.constants.localStorage.token) !== 'undefined') {
    //   if (localStorage.getItem(this.constants.localStorage.token)) {
    //     return true;
    //   } else {
    //   console.log('ingrese 3');

    //     return false;
    //   }
    // } else {
    //   this.toolsService.clearDataRedirectLogin();
    //   // return false;
    // }

    return this.authService.renewToken()
      .pipe(
        tap( estaAutenticado => {
          if(!estaAutenticado){
            this.toolsService.clearDataRedirectLogin();
          }
        })
      );
  }

}
