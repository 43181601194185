import { Injectable } from '@angular/core';

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable({
  providedIn: 'root'
})
export class I18nService {
  language = 'es';
}
