import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import 'moment-duration-format';

// Redux
import { Store } from '@ngrx/store';
import * as personalizationClientActions from '../../store/actions/personalizationClient.actions';
import { AppState } from '../../store/app.reducers';

import { Constants } from 'src/providers/constants/constants';
import { ClientService } from 'src/app/services/client.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ToolsService } from 'src/app/services/tools.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-lista-clientes-cumpleanos',
  templateUrl: './lista-clientes-cumpleanos.component.html',
  styleUrls: ['./lista-clientes-cumpleanos.component.scss']
})
export class ListaClientesCumpleanosComponent implements OnInit {
  moment = moment;
  constants = Constants;
  clientSubDomain: any;
  dataOrganization: any;
  dataPersonalization: any;
  listClientesCumpleanos: any = [];
  // datos interfaz personalización cliente
  colorPrimaryAgendateApp = this.constants.colors.primary;
  logoCliente = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private clientService: ClientService,
    private procedureService: ProcedureService,
    public toolsService: ToolsService,
    private elementRef: ElementRef,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {
    this.listClientesCumpleanos = [];
    // se cambia el color primario solo mientras carga el ws de personalizacion y asi no mostrar el color principal de agendate
    this.elementRef.nativeElement.style.setProperty('--primary', '#FFFFFF');
    // obtengo los datos que lleguen por parametro
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.clientSubDomain) {
        this.clientSubDomain = params.clientSubDomain;
        this.validateClientOrganization();
      } else {
        this.router.navigate(['/404']);
      }
    });
  }

  validateClientOrganization() {
    this.store.dispatch(personalizationClientActions.triggerPersonalizationClientInit());
    this.clientService.postClientOrganizationValidate({ sub_dominio: this.clientSubDomain })
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success) {
          this.dataOrganization = response.data;
          this.dataPersonalization = response.data.personalization;
          // si no existe personalizacion no se aplican los cambios
          if (this.dataPersonalization !== '' && this.dataPersonalization !== undefined) {
            this.store.dispatch(personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: this.dataPersonalization }));
            this.setPersonalization(true);
          } else {
            this.setPersonalization(false);
          }
          this.getListaClientesCumpleanos();
        } else if (typeResponse === this.constants.services.types.error) {
          this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimaryAgendateApp);
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description obtinene los datos de personalizacion de la empresa y los aplica
   */
  setPersonalization(personalizar: boolean = false) {
    if (personalizar) {
      // se aplica la personalizacion que venga del ws
      // this.tituloPublicidad = this.dataPersonalization.texto_principal !== '' ? this.dataPersonalization.texto_principal : this.tituloPublicidad;
      // this.descripcionPublicidad = this.dataPersonalization.texto_secundario !== '' ? this.dataPersonalization.texto_secundario : this.descripcionPublicidad;
      this.logoCliente = (this.dataPersonalization.logo !== '') ? this.dataPersonalization.logo : '';
      // this.logoClienteSecundario = (this.dataPersonalization.logo_secundario !== '') ? this.dataPersonalization.logo_secundario : '';
      // this.imgFondo = (this.dataPersonalization.fondo !== '') ? this.dataPersonalization.fondo : '/assets/img/bg-agendar-citas.jpg';
      const colorPrimary = (this.dataPersonalization.color_principal !== '') ? `#${this.dataPersonalization.color_principal}` : this.colorPrimaryAgendateApp;
      this.elementRef.nativeElement.style.setProperty('--primary', colorPrimary);
      // console.log(this.dataPersonalization);
      return;
    }
    this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimaryAgendateApp);
  }



  /**
 * @description Se hace llamado al web services que lista los ciemtes que cumplen años
 */
  getListaClientesCumpleanos() {
    const data = JSON.stringify({ organizacion: this.dataOrganization.organization_id });
    this.systemService.getNotificationbirthday(data)
      .subscribe(response => {
        const typeResponse = response['type'];
        const lengthData = response['data'].length;
        if (typeResponse === this.constants.services.types.success) {
          this.listClientesCumpleanos = response['data'];
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response['message'].messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }
}
