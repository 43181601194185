import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class RolesService {

  constructor(public Api: ApiService) { }

  getRolesTypesByParams(data: any) {
    return this.Api.get(`/role/params/${data}`);
  }
}
