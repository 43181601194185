import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Constants } from '../../providers/constants/constants';
import { ToolsService } from './tools.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constants = Constants;
  constructor(
    public Api: ApiService,
    // private toolsService: ToolsService
  ) { }

  login(credentials) {
    return this.Api.post('/auth/login', credentials);
  }

  logout(credentials) {
    return this.Api.post('/auth/logout', credentials);
  }

  renewToken(){
    // return this.Api.get('/auth/renew');
    return this.Api.get('/auth/renew').pipe(
      tap( (res: any) => {
        if (res.renew) {
          localStorage.setItem(this.constants.localStorage.token, res.token);
        }
      }),
      map( (res: any) => {
        return true;
      }),
      catchError( error => of(false))
    );
  }

  recoverPassword(email) {
    return this.Api.post('/auth/recover', email);
  }

  changePassword(data) {
    return this.Api.post('/auth/change/password', data);
  }

}
