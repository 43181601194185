import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../providers/constants/constants';
import { ToolsService } from '../../services/tools.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  constants = Constants;
  nameUSer = '';
  emailUser = '';
  idOrganization = '';
  public appPages = [
    // {
    //   title: 'Citas',
    //   url: '/home',
    //   icon: 'calendar-outline',
    //   logout: false
    // },
    {
      title: 'Citas',
      url: '/admin/citas',
      icon: 'mdi mdi-calendar-clock',
      logout: false
    },
    {
      title: 'Usuarios',
      url: '/admin/usuarios',
      icon: 'mdi mdi-account-multiple',
      logout: false
    },
    {
      title: 'Tipos de procedimientos',
      url: '/admin/tiposdeprocedimientos',
      icon: 'mdi mdi-clipboard-outline',
      logout: false
    },
    {
      title: 'Horarios de atención',
      url: '/admin/horariodeatencion',
      icon: 'mdi mdi-alarm-multiple',
      logout: false
    },
    {
      title: 'Mi plan actual',
      url: '/admin/cuenta/plan',
      icon: 'mdi mdi-clipboard-text',
      logout: false
    },
    // {
    //   title: 'Cerrar sesión',
    //   url: '/login',
    //   icon: '',
    //   logout: true
    // }
  ];
  constructor(
    private toolsService: ToolsService,
  ) { }

  ngOnInit() {
    this.getDataUser();

    $(function() {
      $("#sidebarnav").AdminMenu();
    });
  }

  getDataUser() {
    if (localStorage.getItem(this.constants.localStorage.token)) {
      this.nameUSer = `${JSON.parse(localStorage.getItem(this.constants.localStorage.user)).nombres}`;
      this.emailUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).email;
      this.idOrganization = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones._id;
    }
  }

  openPage(page: any) {
    this.toolsService.openPage(page);
  }

}
