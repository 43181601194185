<div class="row align-items-center bd-highlight py-2 px-3 my-3 my-md-0">
  <div class="col-6 col-md-2 col-lg-3">
    <img loading="lazy" src="./assets/img/logos/logoLogin.svg" class="d-inline-block logoCliente" width="150" alt="">
  </div>
  <div class="col-12 col-md-8 col-lg-6 order-12 order-md-1">
    <h1 class="titulos text-center">Clientes que cumplen años <br>la próxima semana</h1>
  </div>
  <div class="col-6 col-md-2 col-lg-3 order-md-2">
    <img *ngIf="dataOrganization.organization_id !== constants.idOrganizacionAgendate" loading="lazy" [src]="logoCliente" class="d-inline-block float-right logoCliente" alt="">
  </div>
</div>

<section class="bg-dark-gray-2 pt-3">
  <div class="container">

    <ng-template [ngIf]="listClientesCumpleanos">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-lg-8 text-center">
          <p>Sabemos lo importante que son tus clientes para ti y tu negocio.</p>
          <p>
            A continuación te presentamos los clientes que cumplen años del <strong class="text-primary">{{ listClientesCumpleanos?.rango?.dia_inicial }}</strong> de <strong class="text-primary">{{ listClientesCumpleanos?.rango?.mes_inicial }}</strong> al <strong class="text-primary">{{ listClientesCumpleanos?.rango?.dia_final }}</strong> de <strong class="text-primary">{{ listClientesCumpleanos?.rango?.mes_final }}.</strong><br>
            Actualmente, tienes <strong class="text-primary">{{ listClientesCumpleanos?.clientes_total }}</strong> clientes, de los cuales <strong class="text-primary">{{ listClientesCumpleanos?.sin_nacimiento }}</strong> no tienen registrada la fecha de nacimiento.
          </p>
        </div>
        <div *ngFor="let clienteCumpleanos of listClientesCumpleanos?.clientes; let i = index" class="col-12 bg-white p-2 shadow-sm mb-3 rounded">
          <div class="row mx-0">
            <div class="col-12 col-lg-4 px-0 align-items-center">
              <span class="font-weight-bold">Nombre</span>
              <br>
              <span class="font-weight-light">{{ clienteCumpleanos?.usuario.nombres }} {{ clienteCumpleanos?.usuario.apellidos }}</span>
            </div>
            <div class="col-8 col-lg-3 px-0 align-items-center">
              <span class="font-weight-bold">Fecha de cumpleaños</span>
              <br>
              <span class="font-weight-light">{{ clienteCumpleanos?.usuario.nacimiento | date:"d MMMM":'':'es-ES'}}</span>
            </div>
            <div class="col-4 col-lg-2 px-0 align-items-center">
              <span class="font-weight-bold">Celular</span>
              <br>
              <span><a class="text-primary text-decoration-none" href="https://api.whatsapp.com/send?phone=57{{ clienteCumpleanos?.usuario.celulares[0] }}" target="_blank">{{ clienteCumpleanos?.usuario.celulares[0] }}</a></span>
            </div>
            <div class="col-12 col-lg-3 px-0 align-items-center">
              <span class="font-weight-bold">Email</span>
              <br>
              <span class="font-weight-light"><a class="text-primary text-decoration-none" href="mailto:{{ clienteCumpleanos?.usuario.email }}">{{ clienteCumpleanos?.usuario.email }}</a></span>
            </div>
          </div>
        </div>

      </div>
    </ng-template>

    <!-- metodos de pago -->
    <div class="row pt-3 border-top" *ngIf="dataPersonalization?.metodos_pago">
      <div class="col-12">
        <div class="cont-metodospago">
          <div class="titulos mb-3">Metodos de pago aceptados en {{ dataOrganization?.organization }}</div>
          <img *ngFor="let metodoDePago of dataPersonalization?.metodos_pago" [src]="metodoDePago.url" [alt]="metodoDePago.nombre" loading="lazy">
        </div>
      </div>
    </div>
    <!-- Fin metodos de pago -->

    <div class="row">
      <div class="col-12 text-primary text--normal text--xm text-center mb-lg-3">
        <span *ngIf="dataPersonalization?.texto_destacado">{{ dataPersonalization.texto_destacado }}<br></span>
      </div>
    </div>
    <div class="row mx-0 w-100 justify-content-center pb-4 networks">
      <div class="col-12 text-primary mb-2 text--normal text--xm text-center">
        Siguenos en nuestras redes sociales
      </div>
      <app-social-networks-icons></app-social-networks-icons>
    </div>
  </div>
</section>
