/**
 * @description Archivo que contiene los llamados a los web services para manejar el registro de cuentas
 * @author Juan Manuel Viveros M
 * @copyright JMV
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Constants } from '../../../../providers/constants/constants';
import { PasswordValidator } from '../../../validators/password.validator';
import { ToolsService } from '../../../services/tools.service';
import { TypesService } from '../../../services/types.service';
import { PlanService } from '../../../services/plan.service';
import { RegisteraccountService } from '../../../services/registeraccount.service';
import { ParameterService } from '../../../services/parameter.service';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss']
})
export class RegisterAccountComponent implements OnInit {
  constants = Constants;
  validationsForm: FormGroup;
  matchingPasswordsGroup: FormGroup;
  objSelectTypesOfIdentification: any;
  objSelectTypesOfService: any;
  objSelectPlan: any;
  parameters: any = [];
  stepRegistration = 1;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toolsService: ToolsService,
    public typesService: TypesService,
    public planService: PlanService,
    public registeraccountService: RegisteraccountService,
    private parameterService: ParameterService,
    public formBuilder: FormBuilder,
  ) { }

  /**
   * @description metodo nativo de angular, se ejecuta apenas entran a la vista
   */
  ngOnInit(): void  {
    this.validatorsFormCreateAccount();
    this.getDatosUrl();
    this.getTypesOfIdentification();
    this.getParameters();
    // this.getPlanes();
  }

  /**
   * @description obtengo los datos que lleguen por parametro
   */
  getDatosUrl(){
    this.activatedRoute.params.subscribe((params: any) => {
      if (params?.idReferido) {
        this.validationsForm.patchValue({ referidos: params.idReferido });
        // this.validationsForm.get('referidos').patchValue(params.idReferido);
      }
    });
  }

  /**
   * @description se utiliza para cambiar el paso a paso del registro
   * @param step paso a activar en el formulario
   */
  nextStepRegistration(step: number){
    // SCROLL TOP
    window.scrollTo(0, 0);
    this.stepRegistration = step;
  }

  validatorsFormCreateAccount() {
    this.matchingPasswordsGroup = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup);
    });

    this.validationsForm = this.formBuilder.group({
      nombres: new FormControl('', Validators.compose([
        // Validators.maxLength(25),
        Validators.minLength(4),
        // Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
        Validators.required
      ])),
      apellidos: [''],
      email: ['', [Validators.required, Validators.email]],
      tipoIdentificacion: ['', [Validators.required]],
      identificacion: ['', [Validators.required]],
      matching_passwords: this.matchingPasswordsGroup,
      celulares: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      telefonos: ['', [Validators.minLength(7), Validators.maxLength(7)]],
      nombreEmpresa: ['', [Validators.required]],
      nitEmpresa: [''],
      direccionEmpresa: ['', [Validators.required]],
      tipoServicioEmpresa: ['', [Validators.required]],
      // tipoDePlan: ['', [Validators.required]],
      celularesEmpresa: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      telefonosEmpresa: ['', [Validators.minLength(7), Validators.maxLength(7)]],
      aceptedTerms: [false, Validators.requiredTrue],
      referidos: [''],
      comoTeEnteraste: ['']
    });
  }

  /**
   * @description trae la lista de tipos de identificación existentes
   */
  getTypesOfIdentification() {
    this.typesService.getGroupTypes('Identificacion')
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.objSelectTypesOfIdentification = response.data;
          this.getTypesOfService();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
          this.getTypesOfIdentification();
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description trae la lista de items de donde se entero de agendate
   */
  getParameters(){
    const data = JSON.stringify({llave:'enterate'});
    this.parameterService.getParameterByParams(data)
    .subscribe((response: any) => {
      const typeResponse = response.type;
      const lengthData = response.data.length;
      if (typeResponse === this.constants.services.types.success && lengthData > 0) {
        this.parameters = response.data[0].propiedades;
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
      }
    }, err => {
      this.toolsService.statusService(err);
    }, () => {
    });
  }

  /**
   * @description trae la lista de tipos de servicios que presta la empresa
   */
  getTypesOfService() {
    this.typesService.getGroupTypes('Servicio')
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.objSelectTypesOfService = response.data;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
          this.getTypesOfService();
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  getPlanes() {
    this.planService.getPlanes()
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.objSelectPlan = response.data;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Si el usuario usuario acepta los terminos se activa el checbox
   */
  confirmacionTerminosYCondiciones() {
    const aceptedTerms = (this.validationsForm.get('aceptedTerms').value) ? false : true;
    this.validationsForm.get('aceptedTerms').patchValue(aceptedTerms);
  }

  /**
   * @description Se hace llamado al web services que crea la cuenta
   */
  createAccount() {
    const data = {
      usuario: {
        nombres: this.validationsForm.get('nombres').value.trim(),
        apellidos: this.validationsForm.get('apellidos').value.trim(),
        email: this.validationsForm.get('email').value.trim(),
        credencial: this.validationsForm.get('matching_passwords.password').value,
        tipo_identificacion: this.validationsForm.get('tipoIdentificacion').value,
        identificacion: this.validationsForm.get('identificacion').value.trim(),
        celulares: [this.validationsForm.get('celulares').value.trim()],
        telefonos: [this.validationsForm.get('telefonos').value.trim()],
        terminos_condiciones: this.validationsForm.get('aceptedTerms').value,
        idiomas: 'es'
      },
      organizacion: {
        nit: this.validationsForm.get('nitEmpresa').value.trim(),
        nombre: this.validationsForm.get('nombreEmpresa').value.trim(),
        direccion: this.validationsForm.get('direccionEmpresa').value.trim(),
        // planes: this.validationsForm.get('tipoDePlan').value,
        planes: 'plan_prueba',
        tipo_servicio: this.validationsForm.get('tipoServicioEmpresa').value,
        celular: this.validationsForm.get('celularesEmpresa').value.trim(),
        telefono: this.validationsForm.get('telefonosEmpresa').value.trim(),
        referidos: this.validationsForm.get('referidos').value.trim(),
        retroalimentacion: {
          enterate: this.validationsForm.get('comoTeEnteraste').value
        }
      }
    };
    this.registeraccountService.postCreateAccount(data)
      .subscribe(response => {
        // const dataResponse: any = response as Array<any>;
        // if (dataResponse.type === this.constants.services.types.success) {
        this.validationsForm.reset();
        this.router.navigate(['/cuenta/registro-gracias']);
        // }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }
}
