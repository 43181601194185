import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import 'moment-duration-format';
// Redux
import { Store } from '@ngrx/store';
import * as personalizationClientActions from '../../../store/actions/personalizationClient.actions';
import { AppState } from '../../../store/app.reducers';

import { Constants } from '../../../../providers/constants/constants';
import { AppointmentService } from '../../../services/appointment.service';
import { ClientService } from '../../../services/client.service';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  constants = Constants;
  moment = moment;
  formCancelAppointment: FormGroup;
  verifiedAppointment = false;
  idAppointment: string;
  tokenUser: string;
  fullNameUser: string;
  appointmentData: any = {
    nameClient: '',
    dateAppointment: '',
    hourAppointment: '',
    nameProfessional: '',
    procedures: '',
    nameOrganization: '',
    addressOrganization: '',
    mobileOrganization: '',
    phoneOrganization: '',
    note: ''
  };
  isNotificationAppointmentConfirmed = false;
  isNotificationAppointmentCanceled = false;
  isNotificationAppointmentStateCanceled = false;
  contCancelAppointment = false;
  contAdditionalInformationAppointment = false;
  showBtnConfirmationAppointment = true;
  showInfoConfirmationCancelAppointment = true;
  // datos interfaz personalización cliente
  logoCliente: string = '/assets/img/logos/logoLogin.svg';
  colorPrimarioAgendateApp = this.constants.colors.primary;
  dataOrganizacion: any;
  dataPersonalizacion: any;
  valorDelProcedimiento: string = '';
  tiempoProcedimiento: string = '';
  descripcionProcedimiento: string = '';
  constructor(
    private store: Store<AppState>,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public toolsService: ToolsService,
    private appointmentService: AppointmentService,
    private clientService: ClientService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    // se cambia el color primario solo mientras carga el ws de personalizacion y asi no mostrar el color principal de agendate
    this.elementRef.nativeElement.style.setProperty('--primary', '#FFFFFF');
    // obtengo los datos que lleguen por parametro
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.getDataConfirmationAppointment(params.id);
      }
    });
    this.validatorFormCancelAppointment();
  }

  /**
   * @description valiudaciones para el formulario de cancelar cita
   */
  validatorFormCancelAppointment() {
    this.formCancelAppointment = this.formBuilder.group({
      nota: ['', [Validators.required]]
    });
  }

  getDataConfirmationAppointment(codeIdAppointment: string) {
    this.appointmentService.getDataConfirmationAppointment(codeIdAppointment).subscribe((response: any) => {
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        const dataAppointment = response.data.appointment;
        this.idAppointment = dataAppointment._id;
        this.tokenUser = response.data.token;
        const nameClient = dataAppointment.usuario_cliente.nombres;
        const lastNameClient = dataAppointment.usuario_cliente.apellidos;
        const nameProfessional = dataAppointment.usuario_atendedor.nombres;
        const lastNameProfessional = dataAppointment.usuario_atendedor.apellidos;
        const estadoAppointment = dataAppointment.estados._id;

        // se formatea fecha de creacion a formato solicitado en requerimiento ejem: Mié 15 de abr. 2020 | 11:55 AM
        const dia = moment(dataAppointment.fecha).locale('es').format('dddd');
        const diaMes = moment(dataAppointment.fecha).format('D');
        const mes = moment(dataAppointment.fecha).locale('es').format('MMMM');
        const ano = moment(dataAppointment.fecha).format('YYYY');
        const hora = moment(dataAppointment.fecha).format('hh:mm A');

        this.appointmentData.nameClient = (lastNameClient !== null && lastNameClient !== '') ? `${nameClient} ${lastNameClient}` : nameClient;
        this.appointmentData.dateAppointment = `${dia} ${diaMes} de ${mes} ${ano}`;
        this.appointmentData.hourAppointment = `${hora}`;
        this.appointmentData.nameProfessional = (lastNameProfessional !== null && lastNameProfessional !== '') ? `${nameProfessional} ${lastNameProfessional}` : nameProfessional;
        this.appointmentData.procedures = dataAppointment.procedimientos.nombre;
        this.appointmentData.nameOrganization = dataAppointment.organizaciones.nombre;
        this.appointmentData.addressOrganization = dataAppointment.organizaciones.direccion;
        this.appointmentData.mobileOrganization = dataAppointment.organizaciones.celular || '';
        this.appointmentData.phoneOrganization = dataAppointment.organizaciones.telefono || '';
        this.appointmentData.note = dataAppointment.nota;
        this.valorDelProcedimiento = dataAppointment.procedimientos?.caracteristicas ? dataAppointment.procedimientos?.caracteristicas.valor_servicio.valor : '';
        this.tiempoProcedimiento = dataAppointment?.tiempo_estimado ? dataAppointment?.tiempo_estimado : '';
        this.descripcionProcedimiento = dataAppointment.procedimientos?.descripcion ? dataAppointment.procedimientos?.descripcion : '';

        if (estadoAppointment === 'cancelada') {
          this.contAdditionalInformationAppointment = true;
          this.isNotificationAppointmentStateCanceled = true;
          this.showInfoConfirmationCancelAppointment = false;
        } else if (estadoAppointment === 'confirmada') {
          this.showBtnConfirmationAppointment = false;
        }

        this.verifiedAppointment = true;
        // llamo al servicio que me trae la personalizacion de la cuenta
        this.validateClientOrganization(dataAppointment.organizaciones.sub_dominio);
      } else if (typeResponse === this.constants.services.types.error) {
        this.verifiedAppointment = false;
      }
    }, err => {
      console.error(err);
      this.verifiedAppointment = false;
    }, () => {
    });
  }

  activeCancelAppointment() {
    this.contCancelAppointment = true;
    // aqui limpiam campo de texto cancelar cita
  }

  cancelAppointment() {
    localStorage.setItem(this.constants.localStorage.token, this.tokenUser);
    this.appointmentService.putCancelAppointment(this.idAppointment, this.formCancelAppointment.value).subscribe((response: any) => {
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        this.showInfoConfirmationCancelAppointment = false;
        this.contAdditionalInformationAppointment = true;
        this.isNotificationAppointmentCanceled = true;
        this.formCancelAppointment.reset();
        localStorage.setItem(this.constants.localStorage.token, '');
      } else if (typeResponse === this.constants.services.types.error) {
        console.error(`Código de error: ${response.msnCode} Mensaje: ${response.message.messageDescription}`);
      }
    }, err => {
      console.error(err);
    }, () => {
    });
  }

  confirmationAppointment() {
    localStorage.setItem(this.constants.localStorage.token, this.tokenUser);
    this.appointmentService.putConfirmationAppointment(this.idAppointment, null).subscribe((response: any) => {
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        this.isNotificationAppointmentConfirmed = true;
        this.showInfoConfirmationCancelAppointment = false;
        localStorage.setItem(this.constants.localStorage.token, '');
      } else if (typeResponse === this.constants.services.types.error) {
        console.error(`Código de error: ${response.msnCode} Mensaje: ${response.message.messageDescription}`);
      }
    }, err => {
      console.error(err);
    }, () => {
    });
  }

  validateClientOrganization(subDominioCliente: string) {
    this.store.dispatch(personalizationClientActions.triggerPersonalizationClientInit());
    this.clientService.postClientOrganizationValidate({ sub_dominio: subDominioCliente })
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.dataOrganizacion = response.data;
          this.dataPersonalizacion = response.data.personalization;
          // si no existe personalizacion no se aplican los cambios
          if (this.dataPersonalizacion !== '' && this.dataPersonalizacion !== undefined){
            this.store.dispatch(personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: this.dataPersonalizacion }));
            this.setPersonalization(true);
          }else{
            this.setPersonalization(false);
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimarioAgendateApp);
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description obtinene los datos de personalizacion de la empresa y los aplica
   */
   setPersonalization(personalizar: boolean = false) {
    if (personalizar) {
      // se aplica la personalizacion que venga del ws
      this.logoCliente = (this.dataPersonalizacion.logo !== '') ? this.dataPersonalizacion.logo : this.logoCliente;
      const colorPrimary = (this.dataPersonalizacion.color_principal !== '') ? `#${this.dataPersonalizacion.color_principal}` : this.colorPrimarioAgendateApp;
      this.elementRef.nativeElement.style.setProperty('--primary', colorPrimary);
      return;
    }
    this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimarioAgendateApp);
  }
}
