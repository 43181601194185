import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-admin-web',
  templateUrl: './admin-web.component.html',
  styles: [],
  styleUrls: []
})
export class AdminWebComponent implements OnInit {
  currentYear = moment().format('YYYY');
  constructor(
    private toolsService: ToolsService
  ) {
    this.toolsService.addAndRemoveCssAdminWeb();
  }

  ngOnInit() {
  }

}
