import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  constructor(public Api: ApiService) { }

  getAgendaByParams(data: any) {
    return this.Api.get(`/agenda/params/${data}`);
  }

  postCreateAgenda(data: any) {
    return this.Api.post('/agenda', data);
  }

  putUpdateAgenda(idAgenda: string, data: any) {
    return this.Api.put(`/agenda/${idAgenda}`, data);
  }
}
