import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SNotificationService {
  constructor(
    private socket: Socket
  ) { }

  connectRoom(idRoom: string) {
    this.socket.emit('join', idRoom);
  }

  getMessageNotificationApi() {
    return this.socket.fromEvent('notification');
  }
}
