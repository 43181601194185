import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../../providers/constants/constants';

@Component({
  selector: 'app-thank-you-registration',
  templateUrl: './thank-you-registration.component.html',
  styleUrls: ['./thank-you-registration.component.scss']
})
export class ThankYouRegistrationComponent implements OnInit {
  constants = Constants;

  constructor() { }

  ngOnInit(): void {
  }

}
