<div class="row" *ngIf="showCard">
  <div class="col-12">
    <div class="alert alert-warning">
      <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button> -->
      <h3 class="text-warning"><i class="fa fa-exclamation-triangle"></i> Verifica tu número de teléfono</h3>
      <p>Hola <strong>{{ nameUser }}</strong>, Agéndate App requiere verificar tu número de teléfono, para poder enviar
        notificaciones a tus clientes.</p>
      <p>
        Se enviará un mensaje de texto con un código para verificar tu número de teléfono <strong>{{ phoneNumberUser
          }}</strong><br>
        Si es correcto oprima el botón verificar número, de lo contrario oprima el botón editar.
      </p>
      <button aria-label="Editar" type="button" class="btn waves-effect waves-light btn-rounded btn-outline-secondary mr-3"
        (click)="presentAlertEditPhoneNumberUser()">Editar</button>
      <button aria-label="Verificar número" type="button" class="btn waves-effect waves-light btn-rounded btn-outline-secondary"
        (click)="sendVerificationCodePhoneNumber()">Verificar número</button>
    </div>
  </div>
</div>
