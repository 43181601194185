import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionsService {

  constructor(public Api: ApiService) { }

  getConsumptions(id: string, data: any) {
    return this.Api.post(`/consumption/api/${id}`, data);
  }

  getGeneralConsumptions(id: string, data: any) {
    return this.Api.post(`/consumption/api/general/${id}`, data);
  }
}
