<div class="row align-items-center bd-highlight py-2 px-3 my-3 my-md-0">
  <div class="col-6 col-md-2 col-lg-3">
    <a [routerLink]="['/']">
      <img loading="lazy" src="./assets/img/logos/logoLogin.svg" class="d-inline-block logoCliente" width="150" alt="">
    </a>
  </div>
  <div class="col-12 col-md-8 col-lg-6 order-12 order-md-1">
    <h1 class="titulos text-center">Política de tratamiento de datos personales</h1>
  </div>
</div>

<section class="bg-dark-gray-2 pt-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="text-justify">
          Al registrarse en el sistema de Agéndate App (en adelante “Agéndate App” o la “EMPRESA” o el
          “CONTRATISTA”) damos cumplimiento a la Ley 1581 de 2.012 y su Decreto Reglamentario 1377 de
          2013, le informamos que los datos personales de su propiedad, serán tratados, almacenados,
          usados y procesados en nuestra base de datos, cuya finalidad es gestionar el envío de notificaciones
          para su empresa o sus clientes y hacerle partícipe de nuestros eventos promocionales. Agéndate
          App garantiza el ejercicio de los derechos de acceso, rectificación, cancelación y oposición de los
          datos facilitados. Si desea consultar o eliminar sus datos, puedo hacerlo mediante comunicación
          escrita dirigida al correo electrónico gerencia.agendate@gmail.com
        </p>

        <p class="text-justify">
          Las presentes Condiciones de uso establecen el acceso y utilización como Usuario (en adelante,
        “EL CONTRATANTE”) de nuestra Web y aplicación móvil. Si usted se registra en nuestra Web, App
        móvil o simplemente navega, modifica, confirma, cambia o cancela citas a través de nuestra
        plataforma, significa que acepta sin excepción las presentes condiciones de uso. Si no está de
        acuerdo con estas condiciones abstenerse de navegar y/o utilizar la web o app, ya que al hacerlo
        está aceptando estas condiciones de uso.
        </p>

        <h5 class="text-primary">Consentimiento</h5>

        <p class="text-justify">
          El CONTRATANTE da su consentimiento al CONTRATISTA para que use toda la información
        suministrada para la consecución del servicio y para los fines comerciales del CONTRATISTA, la
        cual será tratada de acuerdo a la legislación de tratamiento de datos de nuestro país.
        </p>

        <p class="text-justify">
          En cada cita creada, reenviada o cancelada el cliente del CONTRATANTE recibirá la información
        y datos del profesional que va a atender la cita y datos de la empresa donde se debe dirigir para dar
        cumplimiento a la cita asignada, el CONTRATANTE se comprometen a usar esta información y
        documentación única y exclusivamente para los fines del servicio contratado, y estos no pueden ser
        usados ni compartidos para ningún otro fin. Así mismo el CONTRATISTA se compromete a cumplir
        con la POLÍTICA DE TRATAMIENTO Y PROTECCIÓN DE DATOS PERSONALES de AGÉNDATE
        APP.
        </p>

        <p class="text-justify">
          Agéndate App recolecta los datos personales del CONTRATISTA y clientes del CONTRATISTA con el fin de notificar y recordar citas asignadas por medio de notificaciones (Push, Correo electrónicos, Mensajes de textos y Mensajes de WhatsApp), los datos recolectados son: nombres, apellidos, número de identificación, número celular, fecha de nacimiento, correo electrónico, dirección IP, ID de dispositivo móvil (notificación push).
          <br>
          El uso de los datos recolectados es utilizado exclusivamente para el funcionamiento de la aplicación (recordar y agendar citas), la información recolectada no será compartir con ningún tercero con ningún fin lucrativo, los datos serán compartidos con las autoridades legales del país en caso de requerirse si se comete algún hecho ilícito.
        </p>

        <h5 class="text-primary">Uso indebido de Agéndate App en la web o app móvil.</h5>

        <p class="text-justify">
          Cuando el CONTRATANTE incumpla alguna de las condiciones de uso podrá perder el pago
        realizado, sin impedir que el CONTRATISTA persiga el incumplimiento de las condiciones de uso así
        como cualquier uso indebido de la web www.agendateapp.com o del app, ejerciendo todas las
        acciones jurídicas a las que tenga derecho de acuerdo a la legislación. En todo caso el
        CONTRATANTE se obliga a suministrar información verídica y actualizada, evitando el suministro
        de información incompleta o falta en el uso de la web o app.
        </p>

        <h5 class="text-primary">Cancelación de cuenta</h5>

        <p class="text-justify">
          El CONTRATISTA se reserva el derecho de cancelar cualquier cuenta en caso de que la misma
        sea utilizada en contravención a las presentes condiciones de uso y del servicio. También podrá
        cancelar la cuenta de un usuario, suspender, alterar o eliminar toda o parte de la información
        contenida en su cuenta con o sin notificación; también podrá poner en conocimiento de las
        Autoridades competentes cualquier comportamiento realizado por un usuario sospechoso de ser
        objeto de alguna reclamación, o de incumplir alguno de los apartados de las presentes Condiciones
        de uso; finalmente acatará cualquier requerimiento de una Autoridad competente en relación con
        información contenida en su cuenta. también podrá cancelar cuentas falsas creadas con datos falsos.
        </p>

        <p class="text-justify">
          En el momento en que el CONTRATISTA lo requiera puede solicitar la eliminación de su cuenta y todos los datos relacionados con la misma de sus clientes registrados. Para eliminar la cuenta deberá enviar un correo a gerencia.agendate@gmail.com indicando los motivos por él cuan desea eliminar la cuenta y en un periodo máximo de 15 días hábiles se eliminará la cuenta con previa notificación.
        </p>

        <h5 class="text-primary">Marco legal aplicable</h5>

        <p class="text-justify">
          Para todos los efectos contractuales, legales y fiscales atinentes a estas condiciones de uso, las
        partes acuerdan como domicilio contractual a la ciudad de Santiago de Cali. Las diferencias que
        surjan acerca de la interpretación de este contrato y su ejecución, incumplimiento o consecuencias
        futuras, si no pudiesen arreglar de común acuerdo, se someterán a la decisión en un centro de
        conciliación legalmente establecido en la Ciudad de Santiago de Cali, Colombia, sin ser necesario
        tribunal de arbitramento. Si no se llegare a un arreglo, no se llegare a presentar la contraparte o se
        incumpliere el acuerdo, se dará inicio al proceso para el cumplimiento del contrato.
        </p>

        <p class="text-justify">
          Cordialmente,<br>
        Equipo de Agéndate App<br>
        <strong>Email:</strong> gerencia.agendate@gmail.com<br>
        <strong>Página web: </strong><a class="text-primary" [routerLink]="['/']">https://agendateapp.com</a><br>
        <strong><a class="text-primary" [routerLink]="['/terminosycondiciones']">Ver terminos y condiciones de uso</a></strong>
        </p>
      </div>
    </div>
  </div>
</section>
