import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(public Api: ApiService) { }

  postCreateAccount(data: any) {
    return this.Api.post('/account/create', data);
  }

  postConfirmationAccount(data: any) {
    return this.Api.post('/account/confirmation', data);
  }
}
