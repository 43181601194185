import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';

import { Constants } from '../../../../../providers/constants/constants';
import { UserService } from '../../../../services/user.service';
import { ToolsService } from '../../../../services/tools.service';
declare var $: any;

export function getAlertConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'top',
    container: 'body',
    delay: 500
  });
}
@Component({
  selector: 'app-adminusers',
  templateUrl: './adminusers.component.html',
  styleUrls: ['./adminusers.component.scss'],
  providers: [{ provide: TooltipConfig, useValue: undefined }]
})
export class AdminusersComponent implements OnInit {
  // @ViewChild(IonInfiniteScroll) ionInfiniteScroll: IonInfiniteScroll;

  constants = Constants;
  skeletonList = this.constants.skeletonList;
  users: any = [];
  dataReturned: any;
  searchUsers: string = '';
  pageSkip: number = 0;
  pageSize: number = 15;
  private timeoutSearch: any;

  constructor(
    // public navController: NavController,
    private router: Router,
    // public modalController: ModalController,
    // public keyboard: Keyboard,
    // private actionSheetCtrl: ActionSheetController,
    public toolsService: ToolsService,
    private userService: UserService
  ) { }

  // ionViewWillEnter() {
  // }

    ngOnInit() {
      this.users = [];
      this.pageSkip = 0;
      this.getUsersSearch();
  }

  goToViewCreateAndEditUsers(user: any = null) {
    let idUser = {};
    let urlRedirect = '/admin/usuarios/crear';
    if (user) {
      idUser = { _id: user._id };
      urlRedirect = '/admin/usuarios/editar';
    }
    this.router.navigate([urlRedirect, idUser]);
  }

  // async openModal() {
  //   const modal = await this.modalController.create({
  //     component: UserfilterPage,
  //     componentProps: {
  //       paramID: 123,
  //       paramTitle: 'Test Title'
  //     }
  //   });
  //   modal.onDidDismiss().then((dataReturned) => {
  //     if (dataReturned !== null) {
  //       this.dataReturned = dataReturned.data;
  //       // alert('Modal Sent Data :'+ dataReturned);
  //     }
  //   });
  //   return await modal.present();
  // }

  /**
   * @description se activa cuando cambia el change del buscador de usuarios
   */
  searchUsersChange(event: any) {
    this.searchUsers = event.target.value;
    if(this.searchUsers.length > 1 && this.searchUsers.length < 3) return;
    clearTimeout(this.timeoutSearch);
    this.users = [];
    this.pageSkip = 0;
    this.timeoutSearch = setTimeout(() => {
      this.getUsersSearch();
    }, 300);
  }

  // keyboardHiden() {
  //   this.keyboard.hide();
  // }

  /**
   * @description Se hace llamado al web services que busca usuarios de la cuenta
   */
  getUsersSearch(event = null) {
    const data = JSON.stringify(
      {
        usuarios: { estados: ['activo', 'inactivo'], texto: this.searchUsers },
        paginador: {
          limit: {
            skip: this.pageSkip,
            size: this.pageSize
          },
          order: {
            nombres: 1,
            apellidos: 1
          }
        }
      }
    );
    this.userService.getUsersSearch(data)
      .subscribe(response => {
        const typeResponse = response['type'];
        const lengthData = response['data'].length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.users = this.users.concat(response['data']);
          this.pageSkip = this.users.length;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
        } else if (event && typeResponse === this.constants.services.types.success && lengthData === 0) {
          this.toolsService.presentToast('No se encontraron más usuarios', this.constants.iconsSweetalert.info);
        }
        // else if (event === null) {
        //   this.users = null;
        // }

        // si es llamado desde el evento del infinite-scroll
        if (event) {
          // event.target.complete();
          // this.ionInfiniteScroll.disabled = false;
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  // loadDataInfiniteScroll(event) {
  //   this.getUsersSearch(event);
  //   // this.ionInfiniteScroll.disabled = true;
  // }

  // /**
  //  * @description Se hace llamado al web services que crea o edita al usuario
  //  * @param  any objeto con todos los campos que tiene el formulario
  //  */
  // editUser(datauser: any) {
  //   const idUser = datauser._id;
  //   let estadoUser = datauser.estados._id;
  //   if (estadoUser === this.constants.state.inactive) {
  //     estadoUser = this.constants.state.active;
  //   } else {
  //     estadoUser = this.constants.state.inactive;
  //   }
  //   const user = { estados: estadoUser };
  //   this.userService.putUpdateUser(idUser, user)
  //     .subscribe(response => {
  //       const typeResponse = response['type'];
  //       if (typeResponse === this.constants.services.types.success) {
  //         this.users.forEach((element) => {
  //           if (element._id === idUser) {
  //             element.estados._id = estadoUser;
  //             return false;
  //           }
  //         });
          // this.toolsService.presentToast(this.constants.users.notificationUpdate, this.constants.iconsSweetalert.success);
  //       } else if (typeResponse === this.constants.services.types.error) {
  //         this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
  //       }
  //     }, err => {
  //       this.toolsService.statusService(err);
  //     }, () => {
  //     });
  // }

  // /**
  //  * @description Se hace llamado al web services que elimina al usuario
  //  * @param iduser id del usuario
  //  */
  // deleteUser(datauser) {
  //   const idUser = datauser._id;
  //   this.userService.deleteUser(idUser)
  //     .subscribe(response => {
  //       const typeResponse = response['type'];
  //       if (typeResponse === this.constants.services.types.success) {
  //         this.users.user.forEach((element, index) => {
  //           if (element._id === idUser) {
  //             this.users.user.splice(index, 1);
  //             return false;
  //           }
  //         });
  //         this.toolsService.presentToast(this.constants.users.notificationDelete, this.constants.iconsSweetalert.success);
  //       } else if (typeResponse === this.constants.services.types.error) {
  //         this.toolsService.presentToast(response['message'].messageDescription, this.constants.iconsSweetalert.error);
  //       } else {
  //         this.users = null;
  //       }
  //     }, err => {
  //       this.toolsService.statusService(err);
  //     }, () => {
  //     });
  // }

  // /**
  //  * @description action sheet de las acciones en el bsucador de citas de (Confirmadas, canceladas y agendadas).
  //  * @param dataUser data user y cita
  //  */
  // async actionSheetAdminUsers(dataUser: any) {
  //   const actionSheet = await this.actionSheetCtrl.create({
  //     header: `${dataUser.nombres} ${dataUser.apellidos}`,
  //     mode: 'md',
  //     // backdropDismiss: false,
  //     buttons: [
  //       // {
  //       //   text: 'Detalle de cita',
  //       //   role: 'destructive',
  //       //   icon: 'paper',
  //       //   handler: () => {
  //       //     console.log('Delete clicked');
  //       //   }
  //       // },
  //       {
  //         text: 'Editar usuario',
  //         icon: 'create',
  //         handler: () => {
  //           this.goToViewCreateAndEditUsers(dataUser);
  //           console.log('Share clicked');
  //         }
  //       },
  //       {
  //         text: 'Mensaje',
  //         icon: 'logo-whatsapp',
  //         handler: () => {
  //           this.toolsService.messageWhatsapp(dataUser.celulares);
  //         }
  //       },
  //       {
  //         text: 'Llamar',
  //         icon: 'call',
  //         handler: () => {
  //           this.toolsService.openCall(dataUser.celulares);
  //         }
  //       },
  //       {
  //         text: 'Cancel',
  //         icon: 'close',
  //         role: 'cancel',
  //         cssClass: 'icon-red-actionSheet',
  //         handler: () => {
  //         }
  //       }]
  //   });
  //   await actionSheet.present();
  // }
}

