<div class="row mx-0 d-flex align-content-between flex-wrap">
  <div class="col-12 d-flex justify-content-between align-items-center px-4 my-3">

    <div>
      <a [routerLink]="['/']" class="">
        <figure class="mb-0">
          <picture>
            <source media="(max-width: 991px)" srcset="./assets/img/logos/logoLogin.svg">
            <source media="(min-width: 992px)" srcset="./assets/img/logos/logoLogin.svg">
            <img loading="lazy" class="w-50" src="./assets/img/logos/logoLogin.svg" border="0" />
          </picture>
        </figure>
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="d-none d-md-flex mr-3 text--normal text--xm text-secondary">¿No tienes una cuenta?</div>
      <a [routerLink]="['/cuenta/registrarme']"
        class="btn text--upper text--gray text--extrabold text--xl btn-primary">Regístrate</a>
    </div>


  </div>
  <div class="d-flex col-11 col-sm-11 col-md-12 col-lg-10 col-xl-8 mx-auto mt-5">


    <div class="row mx-0 mb-4 w-100">
      <div class="col-12 mt-4 mt-md-3 text-primary text--xl px-0">Paso 1</div>
      <div class="col-12 px-0 titulos">¿Olvidaste tu contraseña?</div>
      <div class="col-12 px-0 text-secondary text--xl my-3">Ingresa el email asociado a tu cuenta y te enviaremos un código para restablecer tu contraseña.</div>

      <form class="col-12 col-sm-12 col-md-12 px-0 forms" [formGroup]="validationFormEmailRecoverPassword" (ngSubmit)="emailRecoverPassword()">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 mb-3">
            <label class="labels" for="textCorreoElectronico"><span class="icon-label icon-envelope"></span> Email</label>
            <input type="email" id="textCorreoElectronico" formControlName="email" class="form-control text--xm"
              placeholder="Ingresa tu email">

            <ng-container *ngFor="let validation of constants.validationMessages.email">
              <div class="error-message"
                *ngIf="validationFormEmailRecoverPassword.get('email').hasError(validation.type) && (validationFormEmailRecoverPassword.get('email').dirty || validationFormEmailRecoverPassword.get('email').touched)">
                <i class="fas fa-info-circle"></i> {{ validation.message }}
              </div>
            </ng-container>
          </div>

          <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
            <button aria-label="Enviar Código" type="submit" [disabled]="!validationFormEmailRecoverPassword.valid"
              class="btn btn-lg btn-primary text-uppercase font-weight-bold">Enviar Código</button>
          </div>
        </div>
      </form>

      <div class="col-12 mt-4 mt-md-3 text-primary text--xl px-0">Paso 2</div>
      <div class="col-12 px-0 titulos">Restablecer mi contraseña</div>
      <div class="col-12 px-0 text-secondary text--xl my-3">Para proteger mejor tu cuenta, selecciona una contraseña de 5 caracteres como mínimo, que contenga una combinación de mayúsculas, minúsculas y número.</div>

      <form class="col-12 col-sm-12 col-md-12 px-0 forms" [formGroup]="validationFormChangePassword"
        (ngSubmit)="changePassword()">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 mb-3">
            <label class="labels" for="textCorreoElectronicoChangePass"><span class="icon-label icon-envelope"></span>
              Email</label>
            <input type="email" id="textCorreoElectronicoChangePass" formControlName="email" class="form-control text--xm"
              placeholder="Ingresa tu email">

            <ng-container *ngFor="let validation of constants.validationMessages.email">
              <div class="error-message"
                *ngIf="validationFormChangePassword.get('email').hasError(validation.type) && (validationFormChangePassword.get('email').dirty || validationFormChangePassword.get('email').touched)">
                <i class="fas fa-info-circle"></i> {{ validation.message }}
              </div>
            </ng-container>
          </div>

          <div class="col-12 col-sm-12 col-md-6 mb-3">
            <label class="labels" for="textCodigoRecuperacion"><span class="icon-label icon-businessCards"></span> Código de recuperación</label>
            <input type="text" id="textCodigoRecuperacion" formControlName="code" class="form-control text--xm"
              placeholder="Ingresa tu código de recuperación">

            <ng-container *ngFor="let validation of constants.validationMessages.code">
              <div class="error-message" *ngIf="validationFormChangePassword.get('code').hasError(validation.type) && (validationFormChangePassword.get('code').dirty || validationFormChangePassword.get('code').touched)">
                <i class="fas fa-info-circle"></i> {{ validation.message }}
              </div>
            </ng-container>
          </div>

          <ng-container formGroupName="password">
            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textPassword"><span class="icon-label icon-key"></span> Contraseña</label>
              <input type="password" id="textPassword" formControlName="password" class="form-control text--xm" placeholder="Nueva contraseña">

              <ng-container *ngFor="let validation of constants.validationMessages.password">
                <div class="error-message" *ngIf="validationFormChangePassword.get('password').get('password').hasError(validation.type) && (validationFormChangePassword.get('password').get('password').dirty || validationFormChangePassword.get('password').get('password').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>

              <ng-container *ngFor="let validation of constants.validationMessages.matching_passwords">
                <div class="error-message" *ngIf="validationFormChangePassword.get('password').hasError(validation.type) && (validationFormChangePassword.get('password').get('confirm_password').dirty || validationFormChangePassword.get('password').get('confirm_password').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textConfirmPassword"><span class="icon-label icon-key"></span> Confirmar contraseña</label>
              <input type="password" id="textConfirmPassword" formControlName="confirm_password" class="form-control text--xm" placeholder="Confirmar contraseña">

              <ng-container *ngFor="let validation of constants.validationMessages.confirm_password">
                <div class="error-message" *ngIf="validationFormChangePassword.get('password').get('confirm_password').hasError(validation.type) && (validationFormChangePassword.get('password').get('confirm_password').dirty || validationFormChangePassword.get('password').get('confirm_password').touched)">
                  <i class="fas fa-info-circle"></i>  {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </ng-container>

          <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
            <button aria-label="Restablecer contraseña" type="submit" [disabled]="!validationFormChangePassword.valid"
              class="btn btn-lg btn-primary text-uppercase font-weight-bold">Restablecer contraseña</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
