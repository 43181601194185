<div class="">
  <button aria-label="Crear cita"
    class="btn btn-sm btn-primary right-side-toggle waves-effect waves-light btn-inverse btn-circle  pull-right m-l-10"
    [tooltip]="constants.tooltips.btns.appointments.create" placement="top" type="button" (click)="goToViewCreateAndEditAppointment()">
    <i class="ti-plus text-white"></i>
  </button>
</div>

<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row">
  <div class="col-12 col-xl-3 col-lg-4 col-md-4">
    <div class="card">
      <div class="card-body inbox-panel">
        <!-- <h4 class="card-title">Gestión de citas</h4> -->
        <!-- <h6 class="card-subtitle">made with bootstrap elements</h6> -->
        <form class="col-12 px-0 forms" [formGroup]="validationsForm">
          <div class="col-12 mb-3 px-0">
            <label class="labels" for="selectTipoCita"><span class="icon-label icon-user"></span> Tipo de cita</label>
            <ng-select class="text--xl" formControlName="tipoCita" (change)="getUsersSearch();getDataAppointments();" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTipoCita"
              [multiple]="false" [clearable]="false" [searchable]="true" placeholder="Seleccione un tipo de cita">
              <ng-option selected value="">Todos los tipos de cita</ng-option>
              <ng-option *ngFor="let procedure of procedures" value="{{procedure._id}}">{{procedure.nombre}}</ng-option>
            </ng-select>
          </div>

          <div class="col-12 mb-3 px-0">
            <label class="labels" for="selectUsuarioAtendedor">
              <span class="icon-label icon-user"></span> Quien atiende la cita?</label>
            <ng-select class="text--xl" formControlName="usuarioAtendedor" (change)="getDataAppointments();" [dropdownPosition]="'bottom'" appearance="outline"
              labelForId="selectUsuarioAtendedor" [multiple]="false" [clearable]="false" [searchable]="true" placeholder="Selecciona una opción">
              <!-- <ng-option selected disabled value="">Selecciona una opción</ng-option> -->
              <ng-option *ngFor="let user of users" value="{{user._id}}">{{user.nombres}} {{user.apellidos}}
              </ng-option>
            </ng-select>
          </div>

          <div class="col-12 mb-3 px-0">
            <ngb-datepicker #dp (dateSelect)="onDateSelection($event);" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden">
            </ngb-datepicker>

            <ng-template #t let-date let-focused="focused" let-selected="selected">
              <span class="custom-day"
                    [class.diaActual]="selected"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          </form>

        <div class="d-none d-md-block d-lg-block d-xl-block">
          <h5 class="card-title m-t-40">Citas</h5>
          <ul class="list-group list-group-full">
            <li class="list-group-item active">
              <a><span class="fa fa-circle text-primary m-r-10"></span> Agendadas </a> <span class="badge badge-primary ml-auto text--sml">{{ dataDashboard.scheduled }}</span>
            </li>
            <li class="list-group-item">
              <a><span class="fa fa-circle text-success m-r-10"></span> Confirmadas </a> <span class="badge badge-success ml-auto text--sml">{{ dataDashboard.confirmed }}</span>
            </li>
            <li class="list-group-item">
              <a><span class="fa fa-circle text-danger m-r-10"></span> Canceladas </a> <span class="badge badge-danger ml-auto text--sml">{{ dataDashboard.canceled }}</span>
            </li>
            <!-- <li class="list-group-item ">
              <a><span class="fa fa-circle text-info m-r-10"></span> Terminadas </a> <span class="badge badge-info ml-auto text--sml">6</span>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-9 col-lg-8 col-md-8 b-l">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <button aria-label="Crear nueva cita" type="button" class="btn btn-primary btn-rounded float-right" (click)="goToViewCreateAndEditAppointment()">
              <i class="ti-plus"></i> Crear nueva cita
            </button>
          </div>
        </div>
        <div class="nav nav-tabs customtab">
          <tabset class="w-100" #staticTabsTypeAppointment>
            <tab customClass="nav-item">
              <!-- ng-template Titulo del tab  -->
              <ng-template tabHeading>
                Agendadas <span class="badge badge-primary ml-auto text--sml">{{ dataDashboard.scheduled }}</span>
              </ng-template>

              <div class="p-20 table-responsive">
                <table class="table stylish-table">
                  <tbody>
                    <ng-template [ngIf]="appointmentsScheduled">
                      <tr *ngFor="let appointmentScheduled of appointmentsScheduled">
                        <td style="width:100px;" class="text-center align-middle">
                          <span class="text-capitalize">
                            {{ toolsService.parseDates(appointmentScheduled.citas.fecha, 3) }}
                            {{moment(appointmentScheduled.citas.fecha).format('DD')}}<br>
                            {{moment(appointmentScheduled.citas.fecha).format('h:mm A')}}
                          </span>
                        </td>
                        <td>
                          <h6 class="mb-0">{{ appointmentScheduled.usuario.nombres }} {{ appointmentScheduled.usuario.apellidos }} <small class="pull-right">{{ appointmentScheduled.citas.procedimientos.nombre }}</small></h6>
                          <small class="text-muted">{{appointmentScheduled.usuario.tipo_identificacion.abreviatura}}: {{appointmentScheduled.usuario.identificacion}}</small><br>
                          <small class="text-muted">{{moment(appointmentScheduled.citas.fecha).format('h:mm A')}} - {{moment(appointmentScheduled.citas.fecha_fin).format('h:mm A')}} ({{ appointmentScheduled.citas.tiempo_estimado }}Min)</small>
                        </td>
                        <td style="width:50px;" class="text-center align-middle">
                          <a href="JavaScript:void(0)" class="btn link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [tooltip]="constants.tooltips.btns.actions" placement="top">
                            <i class="fas fa-ellipsis-v"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.alertAppointmentDetail(appointmentScheduled)"><i class="fa fa-file-text-o mr-1"></i> Detalle de cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="goToViewCreateAndEditAppointment(appointmentScheduled)"><i class="fa fa-edit mr-1"></i> Editar cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.forwardAppointment(appointmentScheduled.citas._id)" ><i class="fa fa-send-o mr-2"></i>Reenviar cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="openModalCancelAppointment(appointmentScheduled, constants.stateAppointments.agendada)"><i class="mdi mdi-close-circle-outline mr-2"></i>Cancelar cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.messageWhatsapp(appointmentScheduled.usuario.celulares)" ><i class="fab fa-whatsapp mr-2"></i>Mensaje</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.openCall(appointmentScheduled.usuario.celulares)"><i class="ti-mobile mr-2"></i>Llamar</a>
                          </div>
                        </td>
                      </tr>
                    </ng-template>

                    <ng-template [ngIf]="appointmentsScheduled === null || appointmentsScheduled.length === 0">
                      <tr>
                        <td class="text-center">No se encontraron citas Agendadas para la fecha seleccionada</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
                <!-- se muestra solo si existen registros -->
                <ng-template [ngIf]="appointmentsScheduled.length > 0">
                  <div class="row">
                    <div class="col-12 text-center mb-3">
                      <button aria-label="Cargar más" type="button" class="btn btn-primary btn-rounded" (click)="getUsersSearchAppointmentScheduled(true)">
                        <i class="ti-plus"></i> Cargar más
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </tab>
            <tab customClass="nav-item">
              <ng-template tabHeading>
                <span (click)="getAppointmentConfirmed()">
                  Confirmadas <span class="badge badge-success ml-auto text--sml">{{ dataDashboard.confirmed }}</span>
                </span>
              </ng-template>

              <div class="p-20 table-responsive">
                <table class="table stylish-table">
                  <tbody>
                    <ng-template [ngIf]="appointmentsConfirmed">
                      <tr *ngFor="let appointmentConfirmed of appointmentsConfirmed">
                        <td style="width:100px;" class="text-center align-middle">
                          <span class="text-capitalize">
                            {{ toolsService.parseDates(appointmentConfirmed.citas.fecha, 3) }}
                            {{moment(appointmentConfirmed.citas.fecha).format('DD')}}<br>
                            {{moment(appointmentConfirmed.citas.fecha).format('h:mm A')}}
                          </span>
                        </td>
                        <td>
                          <h6 class="mb-0">{{ appointmentConfirmed.usuario.nombres }} {{ appointmentConfirmed.usuario.apellidos }} <small class="pull-right">{{ appointmentConfirmed.citas.procedimientos.nombre }}</small></h6>
                          <small class="text-muted">{{appointmentConfirmed.usuario.tipo_identificacion.abreviatura}}: {{appointmentConfirmed.usuario.identificacion}}</small><br>
                          <small class="text-muted">{{moment(appointmentConfirmed.citas.fecha).format('h:mm A')}} - {{moment(appointmentConfirmed.citas.fecha_fin).format('h:mm A')}} ({{ appointmentConfirmed.citas.tiempo_estimado }}Min)</small>
                        </td>
                        <td style="width:50px;" class="text-center align-middle">
                          <a href="JavaScript:void(0)" class="btn link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [tooltip]="constants.tooltips.btns.actions" placement="top">
                            <i class="fas fa-ellipsis-v"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.alertAppointmentDetail(appointmentConfirmed)"><i class="fa fa-file-text-o mr-1"></i> Detalle de cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="goToViewCreateAndEditAppointment(appointmentConfirmed)"><i class="fa fa-edit mr-1"></i> Editar cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.forwardAppointment(appointmentConfirmed.citas._id)" ><i class="fa fa-send-o mr-2"></i>Reenviar cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="openModalCancelAppointment(appointmentConfirmed, constants.stateAppointments.agendada)"><i class="mdi mdi-close-circle-outline mr-2"></i>Cancelar cita</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.messageWhatsapp(appointmentConfirmed.usuario.celulares)" ><i class="fab fa-whatsapp mr-2"></i>Mensaje</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.openCall(appointmentConfirmed.usuario.celulares)"><i class="ti-mobile mr-2"></i>Llamar</a>
                          </div>
                        </td>
                      </tr>
                    </ng-template>

                    <ng-template [ngIf]="appointmentsConfirmed === null || appointmentsConfirmed.length === 0">
                      <tr>
                        <td class="text-center">No se encontraron citas Confirmadas para la fecha seleccionada</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
                <!-- se muestra solo si existen registros -->
                <ng-template [ngIf]="appointmentsConfirmed.length > 0">
                  <div class="row">
                    <div class="col-12 text-center mb-3">
                      <button aria-label="Cargar más" type="button" class="btn btn-primary btn-rounded" (click)="getUsersSearchAppointmentConfirmed(true)">
                        <i class="ti-plus"></i> Cargar más
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </tab>
            <tab customClass="nav-item">
              <ng-template tabHeading>
                <span (click)="getAppointmentCanceled()">
                  Canceladas <span class="badge badge-danger ml-auto text--sml">{{ dataDashboard.canceled }}</span>
                </span>
              </ng-template>

              <div class="p-20 table-responsive">
                <table class="table stylish-table">
                  <tbody>
                    <ng-template [ngIf]="appointmentsCanceled">
                      <tr *ngFor="let appointmentCanceled of appointmentsCanceled">
                        <td style="width:100px;" class="text-center align-middle">
                          <span class="text-capitalize">
                            {{ toolsService.parseDates(appointmentCanceled.citas.fecha, 3) }}
                            {{moment(appointmentCanceled.citas.fecha).format('DD')}}<br>
                            {{moment(appointmentCanceled.citas.fecha).format('h:mm A')}}
                          </span>
                        </td>
                        <td>
                          <h6 class="mb-0">{{ appointmentCanceled.usuario.nombres }} {{ appointmentCanceled.usuario.apellidos }} <small class="pull-right">{{ appointmentCanceled.citas.procedimientos.nombre }}</small></h6>
                          <small class="text-muted">{{appointmentCanceled.usuario.tipo_identificacion.abreviatura}}: {{appointmentCanceled.usuario.identificacion}}</small><br>
                          <small class="text-muted">{{moment(appointmentCanceled.citas.fecha).format('h:mm A')}} - {{moment(appointmentCanceled.citas.fecha_fin).format('h:mm A')}} ({{ appointmentCanceled.citas.tiempo_estimado }}Min)</small>
                        </td>
                        <td style="width:50px;" class="text-center align-middle">
                          <a href="JavaScript:void(0)" class="btn link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [tooltip]="constants.tooltips.btns.actions" placement="top">
                            <i class="fas fa-ellipsis-v"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.alertAppointmentDetail(appointmentCanceled)"><i class="fa fa-file-text-o mr-1"></i> Detalle de cita</a>
                            <!-- <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="goToViewCreateAndEditAppointment(appointmentCanceled)"><i class="fa fa-edit mr-1"></i> Editar cita</a> -->
                            <!-- <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.forwardAppointment(appointmentCanceled.citas._id)" ><i class="fa fa-send-o mr-2"></i>Reenviar cita</a> -->
                            <!-- <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="openModalCancelAppointment(appointmentCanceled, constants.stateAppointments.agendada)"><i class="mdi mdi-close-circle-outline mr-2"></i>Cancelar cita</a> -->
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.messageWhatsapp(appointmentCanceled.usuario.celulares)" ><i class="fab fa-whatsapp mr-2"></i>Mensaje</a>
                            <a class="dropdown-item text-muted" href="javascript:void(0)" (click)="toolsService.openCall(appointmentCanceled.usuario.celulares)"><i class="ti-mobile mr-2"></i>Llamar</a>
                          </div>
                        </td>
                      </tr>
                    </ng-template>

                    <ng-template [ngIf]="appointmentsCanceled === null || appointmentsCanceled.length === 0">
                      <tr>
                        <td class="text-center">No se encontraron citas Canceladas para la fecha seleccionada</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
                <!-- se muestra solo si existen registros -->
                <ng-template [ngIf]="appointmentsCanceled.length > 0">
                  <div class="row">
                    <div class="col-12 text-center mb-3">
                      <button aria-label="Cargar más" type="button" class="btn btn-primary btn-rounded" (click)="getUsersSearchAppointmentCanceled(true)">
                        <i class="ti-plus"></i> Cargar más
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
