import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/providers/constants/constants';

@Component({
  selector: 'app-boton-whatsapp',
  templateUrl: './boton-whatsapp.component.html',
  styleUrls: ['./boton-whatsapp.component.scss']
})
export class BotonWhatsappComponent implements OnInit {
  constants = Constants;
  dataRedesSociales: any = this.constants.socialMedia;

  constructor() { }

  ngOnInit() {
  }

}
