import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { Constants } from '../../../../../providers/constants/constants';
import { ToolsService } from '../../../../services/tools.service';
import { ProcedureService } from '../../../../services/procedure.service';

@Component({
  selector: 'app-createandeditprocedures',
  templateUrl: './createandeditprocedures.component.html',
  styleUrls: ['./createandeditprocedures.component.scss']
})
export class CreateandeditproceduresComponent implements OnInit {
  constants = Constants;
  validationsForm: FormGroup;
  matching_passwords_group: FormGroup;
  procedure: any = {};
  tipoServicioEmpresa = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones.tipo_servicio;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toolsService: ToolsService,
    public formBuilder: FormBuilder,
    private procedureService: ProcedureService,
  ) { }

  /**
   * @description metodo nativo de ionic, se ejecuta apenas entran a la vista
   **/
  ngOnInit() {
    this.validatorsFormCreateAndEditUser();
    // obtengo los datos que lleguen por parametro y se los asigno a this.user, esto pasa al editar un usuario
    this.activatedRoute.params.subscribe(data => {
      if (data._id) {
        this.procedure = data;
        this.getDataProcedure();
      }
    });
  }

  validatorsFormCreateAndEditUser() {
    this.validationsForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      abreviatura: [''],
      descripcion: ['', [Validators.required]],
      tiempo_estimado: ['', [Validators.required]],
      precio: [''],
      estado: ['', [Validators.required]],
    });
  }

  getDataProcedure() {
    const data = this.procedure._id;
    this.procedureService.getProcedureId(data)
      .subscribe(response => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          this.procedure = response['data'];
          const dataProcedure = {
            nombre: this.procedure.nombre,
            abreviatura: this.procedure.abreviatura,
            descripcion: this.procedure.descripcion,
            tiempo_estimado: this.procedure.tiempo_estimado.toString(),
            precio: this.procedure?.caracteristicas ? this.procedure?.caracteristicas.valor_servicio.valor : '',
            estado: this.procedure.estados
          };
          this.validationsForm.patchValue(dataProcedure);
          this.validationsForm.updateValueAndValidity();
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se hace llamado al web services que crea o edita el procedimiento
   */
  createAndEditProcedure() {
    let idProcedure = null;
    let estadoProcedure = this.constants.state.active;
    // validacion para saber si se esta editando o creando un procedimiento
    if (this.procedure._id) {
      idProcedure = this.procedure._id;
      estadoProcedure = this.procedure.estados;
    }

    // .trim() quita todos los espacios al inicio y final del string
    const dataProcedure = {
      nombre: this.validationsForm.get('nombre').value.trim(),
      abreviatura: this.validationsForm.get('abreviatura').value.trim(),
      descripcion: this.validationsForm.get('descripcion').value.trim(),
      tiempo_estimado: this.validationsForm.get('tiempo_estimado').value,
      caracteristicas: {
        valor_servicio: {
            moneda: '$',
            valor: this.validationsForm.get('precio').value,
        },
        // tiempo_recurrencia: 60
      },
      estados: this.validationsForm.get('estado').value,
      tipos: this.tipoServicioEmpresa,
      organizaciones: true
    };

    if (this.procedure._id) {
      this.procedure = dataProcedure;
      // this.loading.present(this.constants.loading.messages.updatedProcedure, this.constants.loading.spinner);
      this.procedureService.putUpdateProcedure(idProcedure, this.procedure)
        .subscribe(response => {
          // this.loading.dismiss();
          const typeResponse = response['type'];
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.procedures.notificationUpdate);
            this.validationsForm.reset();
            this.router.navigate(['/admin/tiposdeprocedimientos']);
          } else {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          }
        }, err => {
          // this.loading.dismiss();
          this.toolsService.statusService(err);
        }, () => {
          // this.loading.dismiss();
        });
    } else {
      this.procedure = dataProcedure;
      // this.loading.present(this.constants.loading.messages.createdProcedure, this.constants.loading.spinner);
      this.procedureService.postCreateProcedure(this.procedure)
        .subscribe(response => {
          // this.loading.dismiss();
          const typeResponse = response['type'];
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.procedures.notificationCreated);
            this.validationsForm.reset();
            this.router.navigate(['/admin/tiposdeprocedimientos']);
          } else {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          }
        }, err => {
          // this.loading.dismiss();
          this.toolsService.statusService(err);
        }, () => {
          // this.loading.dismiss();
        });
    }
  }

}
