<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Datos personales <!--<span *ngIf="editUser" >(Editar)</span>--></h6>
        <!-- <h6 class="card-subtitle">Just add class to the form that's it.</h6> -->
        <form class="forms m-t-40" [formGroup]="validationsForm" (ngSubmit)="createAndEditUser()">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="selectTipoIdentificacion"><span class="icon-label icon-businessCards"></span>
                Tipo de documento</label>
                <ng-select formControlName="tipoIdentificacion" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTipoIdentificacion" [multiple]="false" [searchable]="true" placeholder="Seleccione un tipo">
                  <ng-option *ngFor="let item of objSelectTypesOfIdentification" value="{{item._id}}">{{item.nombre}}</ng-option>
                </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoIdentificacion">
                <div class="error-message"
                  *ngIf="validationsForm.get('tipoIdentificacion').hasError(validation.type) && (validationsForm.get('tipoIdentificacion').dirty || validationsForm.get('tipoIdentificacion').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textIdentificacion"><span class="icon-label icon-businessCards"></span> Número de identificación</label>
              <input type="text" id="textIdentificacion" formControlName="identificacion" class="form-control text--xm" placeholder="Número de documento">

              <ng-container *ngFor="let validation of constants.validationMessages.identificacion">
                <div class="error-message" *ngIf="validationsForm.get('identificacion').hasError(validation.type) && (validationsForm.get('identificacion').dirty || validationsForm.get('identificacion').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textNombres"><span class="icon-label icon-user"></span> Nombres</label>
              <input type="text" id="textNombres" formControlName="nombres" class="form-control text--xm"
                placeholder="Nombres completos">

              <ng-container *ngFor="let validation of constants.validationMessages.nombres">
                <div class="error-message"
                  *ngIf="validationsForm.get('nombres').hasError(validation.type) && (validationsForm.get('nombres').dirty || validationsForm.get('nombres').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textApellidos"><span class="icon-label icon-user"></span> Apellidos</label>
              <input type="text" id="textApellidos" formControlName="apellidos" class="form-control text--xm"
                placeholder="Apellidos completos">
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="dateFechaNacimiento"><span class="icon-label icon-calendar"></span>
                Fecha de nacimiento</label>
              <input type="date" id="dateFechaNacimiento" formControlName="nacimiento" (change)="validarFechaNacimiento()" class="form-control calendar text--xm">

              <ng-container>
                <div class="error-message" *ngIf="fechaMenorQuelaActual != ''">
                  <i class="fas fa-info-circle"></i> {{ fechaMenorQuelaActual }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="selectTipoRol"><span class="icon-label icon-businessCards"></span>
                Tipo de usuario</label>
                <ng-select formControlName="tipoRol" labelForId="selectTipoRol" placeholder="Seleccione un tipo de usuario" (change)="validatorsChangesRoleType();" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" [multiple]="false" [searchable]="true">
                  <ng-option *ngFor="let item of objSelectTypesOfRoles" value="{{item._id}}">{{item.nombre}}</ng-option>
                </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoRol">
                <div class="error-message"
                  *ngIf="validationsForm.get('tipoRol').hasError(validation.type) && (validationsForm.get('tipoRol').dirty || validationsForm.get('tipoRol').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3"
              *ngIf="validationsForm.get('tipoRol').value === constants.users.types.attendAppointments || validationsForm.get('tipoRol').value === constants.users.types.admin">
              <label class="labels" for="selectTipoCitaAtiende"><span class="icon-label icon-user"></span> Que tipo de citas atiende?</label>
              <ng-select formControlName="procedimientos" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTipoCitaAtiende" [multiple]="true" [searchable]="true" placeholder="Tipo de cita?">
                <ng-option *ngFor="let procedure of procedures" value="{{procedure._id}}">{{procedure.nombre}}</ng-option>
              </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoCita">
                <div class="error-message"
                  *ngIf="validationsForm.get('procedimientos').hasError(validation.type) && (validationsForm.get('procedimientos').dirty || validationsForm.get('procedimientos').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textCorreoElectronico"><span class="icon-label icon-envelope"></span> Correo electrónico</label>
              <input type="email" id="textCorreoElectronico" formControlName="email" class="form-control text--xm" placeholder="Ingresa tu eMail de contacto">

              <ng-container *ngFor="let validation of constants.validationMessages.email">
                <div class="error-message"
                  *ngIf="validationsForm.get('email').hasError(validation.type) && (validationsForm.get('email').dirty || validationsForm.get('email').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <ng-container formGroupName="matching_passwords">
                <div *ngIf="validationsForm.get('tipoRol').value != constants.users.types.patient && editUser == false" class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
                  <label class="labels" for="textPassword"><span class="icon-label icon-key"></span> Contraseña</label>
                  <input type="password" id="textPassword" formControlName="password" class="form-control text--xm"
                    placeholder="Contraseña">

                  <ng-container *ngFor="let validation of constants.validationMessages.password">
                    <div class="error-message"
                      *ngIf="validationsForm.get('matching_passwords').get('password').hasError(validation.type) && (validationsForm.get('matching_passwords').get('password').dirty || validationsForm.get('matching_passwords').get('password').touched)">
                      <i class="fas fa-info-circle"></i> {{ validation.message }}
                    </div>
                  </ng-container>

                  <ng-container *ngFor="let validation of constants.validationMessages.matching_passwords">
                    <div class="error-message"
                      *ngIf="validationsForm.get('matching_passwords').hasError(validation.type) && (validationsForm.get('matching_passwords').get('confirm_password').dirty || validationsForm.get('matching_passwords').get('confirm_password').touched)">
                      <i class="fas fa-info-circle"></i> {{ validation.message }}
                    </div>
                  </ng-container>
                </div>

                <div *ngIf="validationsForm.get('tipoRol').value != constants.users.types.patient && editUser == false" class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
                  <label class="labels" for="textConfirmPassword"><span class="icon-label icon-key"></span> Confirmar
                    contraseña</label>
                  <input type="password" id="textConfirmPassword" formControlName="confirm_password" class="form-control text--xm"
                    placeholder="Confirmar contraseña">

                  <ng-container *ngFor="let validation of constants.validationMessages.confirm_password">
                    <div class="error-message"
                      *ngIf="validationsForm.get('matching_passwords').get('confirm_password').hasError(validation.type) && (validationsForm.get('matching_passwords').get('confirm_password').dirty || validationsForm.get('matching_passwords').get('confirm_password').touched)">
                      <i class="fas fa-info-circle"></i> {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
            </ng-container>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textCelulares"><span class="icon-label icon-phone"></span> Teléfono celular</label>
              <input type="tel" id="textCelulares" formControlName="celulares" class="form-control text--xm" placeholder="Ingresa tu número móvil">

              <ng-container *ngFor="let validation of constants.validationMessages.celulares">
                <div class="error-message" *ngIf="validationsForm.get('celulares').hasError(validation.type) && (validationsForm.get('celulares').dirty || validationsForm.get('celulares').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textTelefonos"><span class="icon-label icon-phoneFijo"></span> Teléfono fijo</label>
              <input type="tel" id="textTelefonos" formControlName="telefonos" class="form-control text--xm" placeholder="Ingresa tu número móvil">

              <ng-container *ngFor="let validation of constants.validationMessages.telefonos">
                <div class="error-message"
                  *ngIf="validationsForm.get('telefonos').hasError(validation.type) && (validationsForm.get('telefonos').dirty || validationsForm.get('telefonos').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <button aria-label="Guardar" type="submit" [disabled]="!validationsForm.valid" class="btn btn-lg btn-primary text-uppercase font-weight-bold">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
