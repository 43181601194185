<div class="content-wrapper-area py-3 px-4 bg-white d-flex justify-content-between align-items-center">
    <div class="container">
      <div class="row text-center d-flex justify-content-between align-items-center">
        <div class="col-12 mb-3">
          <a routerLink="/">
            <img loading="lazy" src="/assets/img/logos/logoLogin.svg" alt="" class="logo">
          </a>
        </div>
        <div class="col-12">
          <h2 class="titulos">404 página no encontrada</h2>
          <p class="parrafos">
            No se puede encontrar la página que buscas. Verifica la URL para ver si tiene errores.<br>
            ¿Sigue sin funcionar? Ve a nuestra <a class="text-primary" routerLink="/">página de inicio</a> y sigue navegando.</p>
        </div>
        <div class="col-12">
          <img loading="lazy" src="/assets/img/error404.svg" alt="" class="w-75">
        </div>
    </div>
  </div>
</div>
