import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

import { Constants } from '../../../../providers/constants/constants';
import { ToolsService } from '../../../services/tools.service';
import { ProcedureService } from '../../../services/procedure.service';
import { UserService } from '../../../services/user.service';
import { AgendaService } from '../../../services/agenda.service';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-businesshours',
  templateUrl: './businesshours.component.html',
  styleUrls: ['./businesshours.component.scss']
})
export class BusinesshoursComponent implements OnInit {
  @Output() dateSelect = new EventEmitter<NgbDateStruct>();
  constants = Constants;
  moment = moment;
  usersSearchSubs: Subscription;
  formAgendaUserOrOrganization: FormGroup;
  validationsForm: FormGroup;
  businessHours: any;
  idOrganization = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones._id;
  dataGetBusinessHours: any = [];
  users: any = [];
  // parametros que se utilizan para vaidar los campos del formulario y asi poder hacerlos obligatorios o no
  paramsSundayChecked: any = { day: 'sundayChecked', timeFirstIsValid:'firstSundayTimeIsValid', timeSecondIsValid: 'secondSundayTimeIsValid', startTimeFirst: 'startTimeFirstShiftSunday', endTimeFirst: 'endTimeFirstShiftSunday', startTimeSecond: 'startTimeSecondShiftSunday', endTimeSecond: 'endTimeSecondShiftSunday'};
  paramsMondayChecked: any = { day: 'mondayChecked', timeFirstIsValid:'firstMondayTimeIsValid', timeSecondIsValid: 'secondMondayTimeIsValid', startTimeFirst: 'startTimeFirstShiftMonday', endTimeFirst: 'endTimeFirstShiftMonday', startTimeSecond: 'startTimeSecondShiftMonday', endTimeSecond: 'endTimeSecondShiftMonday'};
  paramsTuesdayChecked: any = { day: 'tuesdayChecked', timeFirstIsValid:'firstTuesdayTimeIsValid', timeSecondIsValid: 'secondTuesdayTimeIsValid', startTimeFirst: 'startTimeFirstShiftTuesday', endTimeFirst: 'endTimeFirstShiftTuesday', startTimeSecond: 'startTimeSecondShiftTuesday', endTimeSecond: 'endTimeSecondShiftTuesday'};
  paramsWednesdayChecked: any = { day: 'wednesdayChecked', timeFirstIsValid:'firstWednesdayTimeIsValid', timeSecondIsValid: 'secondWednesdayTimeIsValid', startTimeFirst: 'startTimeFirstShiftWednesday', endTimeFirst: 'endTimeFirstShiftWednesday', startTimeSecond: 'startTimeSecondShiftWednesday', endTimeSecond: 'endTimeSecondShiftWednesday'};
  paramsThursdayChecked: any = { day: 'thursdayChecked', timeFirstIsValid:'firstThursdayTimeIsValid', timeSecondIsValid: 'secondThursdayTimeIsValid', startTimeFirst: 'startTimeFirstShiftThursday', endTimeFirst: 'endTimeFirstShiftThursday', startTimeSecond: 'startTimeSecondShiftThursday', endTimeSecond: 'endTimeSecondShiftThursday'};
  paramsFridayChecked: any = { day: 'fridayChecked', timeFirstIsValid:'firstFridayTimeIsValid', timeSecondIsValid: 'secondFridayTimeIsValid', startTimeFirst: 'startTimeFirstShiftFriday', endTimeFirst: 'endTimeFirstShiftFriday', startTimeSecond: 'startTimeSecondShiftFriday', endTimeSecond: 'endTimeSecondShiftFriday'};
  paramsSaturdayChecked: any = { day: 'saturdayChecked', timeFirstIsValid:'firstSaturdayTimeIsValid', timeSecondIsValid: 'secondSaturdayTimeIsValid', startTimeFirst: 'startTimeFirstShiftSaturday', endTimeFirst: 'endTimeFirstShiftSaturday', startTimeSecond: 'startTimeSecondShiftSaturday', endTimeSecond: 'endTimeSecondShiftSaturday'};



  meridian = true;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toolsService: ToolsService,
    public formBuilder: FormBuilder,
    private procedureService: ProcedureService,
    private userService: UserService,
    private agendaService: AgendaService,
  ) { }

  /**
   * @description metodo nativo de ionic, se ejecuta apenas entran a la vista
   **/
  ngOnInit() {
    this.validatorsFormBusinessHours();
    this.getUsersSearch();
  }

  ngOnDestroy() {
    this.unsubscribeWs();
  }

  unsubscribeWs(){
    this.usersSearchSubs?.unsubscribe();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  /**
   * @description inicializa el formBuilder.group del formulario
   */
   validatorsFormBusinessHours() {
    this.formAgendaUserOrOrganization = this.formBuilder.group({
      agendaUserOrOrganization: ['-1', [Validators.required]]
    });

    this.validationsForm = this.formBuilder.group({
      // Domingo
      sundayChecked: [false],
      startTimeFirstShiftSunday: [''],
      endTimeFirstShiftSunday: [''],
      firstSundayTimeIsValid: [false],
      startTimeSecondShiftSunday: [''],
      endTimeSecondShiftSunday: [''],
      secondSundayTimeIsValid: [false],
      //  Lunes
      mondayChecked: [false],
      startTimeFirstShiftMonday: [''],
      endTimeFirstShiftMonday: [''],
      firstMondayTimeIsValid: [false],
      startTimeSecondShiftMonday: [''],
      endTimeSecondShiftMonday: [''],
      secondMondayTimeIsValid: [false],
      // Martes
      tuesdayChecked: [false],
      startTimeFirstShiftTuesday: [''],
      endTimeFirstShiftTuesday: [''],
      firstTuesdayTimeIsValid: [false],
      startTimeSecondShiftTuesday: [''],
      endTimeSecondShiftTuesday: [''],
      secondTuesdayTimeIsValid: [false],
      // Miercoles
      wednesdayChecked: [false],
      startTimeFirstShiftWednesday: [''],
      endTimeFirstShiftWednesday: [''],
      firstWednesdayTimeIsValid: [false],
      startTimeSecondShiftWednesday: [''],
      endTimeSecondShiftWednesday: [''],
      secondWednesdayTimeIsValid: [false],
      // Jueves
      thursdayChecked: [false],
      startTimeFirstShiftThursday: [''],
      endTimeFirstShiftThursday: [''],
      firstThursdayTimeIsValid: [false],
      startTimeSecondShiftThursday: [''],
      endTimeSecondShiftThursday: [''],
      secondThursdayTimeIsValid: [false],
      // Viernes
      fridayChecked: [false],
      startTimeFirstShiftFriday: [''],
      endTimeFirstShiftFriday: [''],
      firstFridayTimeIsValid: [false],
      startTimeSecondShiftFriday: [''],
      endTimeSecondShiftFriday: [''],
      secondFridayTimeIsValid: [false],
      // Sabado
      saturdayChecked: [false],
      startTimeFirstShiftSaturday: [''],
      endTimeFirstShiftSaturday: [''],
      firstSaturdayTimeIsValid: [false],
      startTimeSecondShiftSaturday: [''],
      endTimeSecondShiftSaturday: [''],
      secondSaturdayTimeIsValid: [false],
      // Horario extendido
      horarioExtendido: [false]
    });
  }

  /**
   * @description Se hace llamado al web services que busca usuarios de la cuenta
   */
   getUsersSearch() {
    const data = JSON.stringify({ usuarios: { estados: 'activo', roles: ['atiendecitas', 'administrador'] } });
    this.usersSearchSubs = this.userService.getUsersSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          if (response.data.length > 0) {
            this.users = response.data;
            this.unsubscribeWs();
            this.getHoursBusinessOrUsers();
          } else {
            this.users = null;
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description trae los horarios de atencion de la organizacion o del atendedor de citas
   */
  getHoursBusinessOrUsers() {
    const agendaUserOrOrganization = this.formAgendaUserOrOrganization.get('agendaUserOrOrganization').value;
    const idUser = agendaUserOrOrganization === '-1' ? null : agendaUserOrOrganization;
    const data = JSON.stringify({ organizaciones: this.idOrganization, usuarios: idUser });
    // console.log(data);
    this.dataGetBusinessHours = [];
    this.validationsForm.reset();
    this.agendaService.getAgendaByParams(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        // console.log(response.data.length);
        if (typeResponse === this.constants.services.types.success && response.data.length > 0) {
          this.dataGetBusinessHours = response.data[0];
          this.setDataBusinessHours(this.dataGetBusinessHours);
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.alertErrorInformationWs(response, false, this.constants.contactAgendate.supportTeam.nameKeyTeam);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  formatHoursSetDataInputs(hour: string){
    return {hour: parseInt(moment(hour, 'HH:mm').format('HH'), 10), minute: parseInt(moment(hour, 'HH:mm').format('mm'), 10)};
    //  return moment(hour, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  setDataBusinessHours(dataHours: any){
    this.validationsForm.patchValue({
      // 0 Domingo
      sundayChecked: dataHours.horarios[0].length > 0 ? true : false,
      startTimeFirstShiftSunday: dataHours.horarios[0].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[0][0].i) : '',
      endTimeFirstShiftSunday: dataHours.horarios[0].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[0][0].f) : '',
      firstSundayTimeIsValid: dataHours.horarios[0].length > 0 ? true : false,
      startTimeSecondShiftSunday: dataHours.horarios[0].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[0][1].i) : '',
      endTimeSecondShiftSunday: dataHours.horarios[0].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[0][1].f) : '',
      secondSundayTimeIsValid: dataHours.horarios[0].length > 1 ? true : false,
      // 1 Lunes
      mondayChecked: dataHours.horarios[1].length > 0 ? true : false,
      startTimeFirstShiftMonday: dataHours.horarios[1].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[1][0].i) : '',
      endTimeFirstShiftMonday: dataHours.horarios[1].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[1][0].f) : '',
      firstMondayTimeIsValid: dataHours.horarios[1].length > 0 ? true : false,
      startTimeSecondShiftMonday: dataHours.horarios[1].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[1][1].i) : '',
      endTimeSecondShiftMonday: dataHours.horarios[1].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[1][1].f) : '',
      secondMondayTimeIsValid: dataHours.horarios[1].length > 1 ? true : false,
      // 2 Martes
      tuesdayChecked: dataHours.horarios[2].length > 0 ? true : false,
      startTimeFirstShiftTuesday: dataHours.horarios[2].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[2][0].i) : '',
      endTimeFirstShiftTuesday: dataHours.horarios[2].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[2][0].f) : '',
      firstTuesdayTimeIsValid: dataHours.horarios[2].length > 0 ? true : false,
      startTimeSecondShiftTuesday: dataHours.horarios[2].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[2][1].i) : '',
      endTimeSecondShiftTuesday: dataHours.horarios[2].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[2][1].f) : '',
      secondTuesdayTimeIsValid: dataHours.horarios[2].length > 1 ? true : false,
      // 3 Miercoles
      wednesdayChecked: dataHours.horarios[3].length > 0 ? true : false,
      startTimeFirstShiftWednesday: dataHours.horarios[3].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[3][0].i) : '',
      endTimeFirstShiftWednesday: dataHours.horarios[3].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[3][0].f) : '',
      firstWednesdayTimeIsValid: dataHours.horarios[3].length > 0 ? true : false,
      startTimeSecondShiftWednesday: dataHours.horarios[3].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[3][1].i) : '',
      endTimeSecondShiftWednesday: dataHours.horarios[3].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[3][1].f) : '',
      secondWednesdayTimeIsValid: dataHours.horarios[3].length > 1 ? true : false,
      // 4 Jueves
      thursdayChecked: dataHours.horarios[4].length > 0 ? true : false,
      startTimeFirstShiftThursday: dataHours.horarios[4].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[4][0].i) : '',
      endTimeFirstShiftThursday: dataHours.horarios[4].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[4][0].f) : '',
      firstThursdayTimeIsValid: dataHours.horarios[4].length > 0 ? true : false,
      startTimeSecondShiftThursday: dataHours.horarios[4].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[4][1].i) : '',
      endTimeSecondShiftThursday: dataHours.horarios[4].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[4][1].f) : '',
      secondThursdayTimeIsValid: dataHours.horarios[4].length > 1 ? true : false,
      // 5 Viernes
      fridayChecked: dataHours.horarios[5].length > 0 ? true : false,
      startTimeFirstShiftFriday: dataHours.horarios[5].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[5][0].i) : '',
      endTimeFirstShiftFriday: dataHours.horarios[5].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[5][0].f) : '',
      firstFridayTimeIsValid: dataHours.horarios[5].length > 0 ? true : false,
      startTimeSecondShiftFriday: dataHours.horarios[5].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[5][1].i) : '',
      endTimeSecondShiftFriday: dataHours.horarios[5].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[5][1].f) : '',
      secondFridayTimeIsValid: dataHours.horarios[5].length > 1 ? true : false,
      // 6 Sabado
      saturdayChecked: dataHours.horarios[6].length > 0 ? true : false,
      startTimeFirstShiftSaturday: dataHours.horarios[6].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[6][0].i) : '',
      endTimeFirstShiftSaturday: dataHours.horarios[6].length > 0 ? this.formatHoursSetDataInputs(dataHours.horarios[6][0].f) : '',
      firstSaturdayTimeIsValid: dataHours.horarios[6].length > 0 ? true : false,
      startTimeSecondShiftSaturday: dataHours.horarios[6].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[6][1].i) : '',
      endTimeSecondShiftSaturday: dataHours.horarios[6].length > 1 ? this.formatHoursSetDataInputs(dataHours.horarios[6][1].f) : '',
      secondSaturdayTimeIsValid: dataHours.horarios[6].length > 1 ? true : false,
      // horario extendido
      horarioExtendido: dataHours?.extendido ? dataHours?.extendido : false
    });
    this.validationsForm.updateValueAndValidity();
  }

  /**
   * @description se activa cuando cambia de estado el check para activar los horarios de atencion por dias
   * @param day nombre del formControlName del ion-toggle por dias
   * @param startTimeFirst nombre del formControlName hora de incio primer turno
   * @param endTimeFirst nombre del formControlName hora de fin  primer turno
   * @param startTimeSecond nombre del formControlName hora de incio segundo turno
   * @param endTimeSecond nombre del formControlName hora de fin  segundo turno
   */
  dayChecked(dataTimes:any){
    if(this.validationsForm.get(dataTimes.day).value){
      // hora inicial del primer turno
      this.validationsForm.controls[dataTimes.startTimeFirst].setValidators([Validators.required]);
      this.validationsForm.controls[dataTimes.startTimeFirst].updateValueAndValidity();
      // hora final del primer turno
      this.validationsForm.controls[dataTimes.endTimeFirst].setValidators([Validators.required]);
      this.validationsForm.controls[dataTimes.endTimeFirst].updateValueAndValidity();
      // validacion de horario inicial y final sea valido
      this.validationsForm.controls[dataTimes.timeFirstIsValid].setValidators([Validators.requiredTrue]);
      this.validationsForm.controls[dataTimes.timeFirstIsValid].updateValueAndValidity();

      // hora inicial del segundo turno
      this.validationsForm.controls[dataTimes.startTimeSecond].setValue('');
      this.validationsForm.controls[dataTimes.startTimeSecond].setValidators([]);
      this.validationsForm.controls[dataTimes.startTimeSecond].updateValueAndValidity();
      // hora final del segundo turno
      this.validationsForm.controls[dataTimes.endTimeSecond].setValue('');
      this.validationsForm.controls[dataTimes.endTimeSecond].setValidators([]);
      this.validationsForm.controls[dataTimes.endTimeSecond].updateValueAndValidity();
      // validacion de horario inicial y final sea valido
      this.validationsForm.get(dataTimes.timeSecondIsValid).patchValue(false);
      this.validationsForm.controls[dataTimes.timeSecondIsValid].setValidators([]);
      this.validationsForm.controls[dataTimes.timeSecondIsValid].updateValueAndValidity();
    } else {
      // limpio los inpust de horas en los horarios del primer y segundo turno
      this.validationsForm.controls[dataTimes.startTimeFirst].setValue('');
      this.validationsForm.controls[dataTimes.startTimeFirst].setValidators([]);
      this.validationsForm.controls[dataTimes.startTimeFirst].updateValueAndValidity();
      this.validationsForm.controls[dataTimes.endTimeFirst].setValue('');
      this.validationsForm.controls[dataTimes.endTimeFirst].setValidators([]);
      this.validationsForm.controls[dataTimes.endTimeFirst].updateValueAndValidity();
      this.validationsForm.get(dataTimes.timeFirstIsValid).patchValue(false);
      this.validationsForm.controls[dataTimes.timeFirstIsValid].setValidators([]);
      this.validationsForm.controls[dataTimes.timeFirstIsValid].updateValueAndValidity();

      this.validationsForm.controls[dataTimes.startTimeSecond].setValue('');
      this.validationsForm.controls[dataTimes.startTimeSecond].setValidators([]);
      this.validationsForm.controls[dataTimes.startTimeSecond].updateValueAndValidity();
      this.validationsForm.controls[dataTimes.endTimeSecond].setValue('');
      this.validationsForm.controls[dataTimes.endTimeSecond].setValidators([]);
      this.validationsForm.controls[dataTimes.endTimeSecond].updateValueAndValidity();

      this.validationsForm.get(dataTimes.timeSecondIsValid).patchValue(false);
      this.validationsForm.controls[dataTimes.timeSecondIsValid].setValidators([]);
      this.validationsForm.controls[dataTimes.timeSecondIsValid].updateValueAndValidity();
    }
  }

  validateHoursMinute(data){
    return data <= 9 ? `0${data}` : data;
  }

  /**
   * @description valida que las horas de inicio y fin sen correctas y la hora de inicio no sea mayor a la hora de fin
   */
  validateHoursByShifts(isTheFirstTurn: boolean, startTime: any, endTime: any, timeIsValid: string, previousStartTime: any = null){
    const valueStartTime = this.validationsForm.get(startTime).value;
    const valueEndTime = this.validationsForm.get(endTime).value;
    // valido que la hora inicial y hora final esten diligenciadas
    if(valueStartTime === '' || valueStartTime === null) return;
    if(valueEndTime === '' || valueEndTime === null) return;
    // se formatean las horas en formato de 24 horas, pero quedan convertidas a string
    const date = moment().format('YYYY-MM-DD');
    const startTime24hFormat = moment(`${date}T${this.validateHoursMinute(valueStartTime.hour)}:${this.validateHoursMinute(valueStartTime.minute)}`);//.format('HH:mm');
    const endTime24hFormat = moment(`${date}T${this.validateHoursMinute(valueEndTime.hour)}:${this.validateHoursMinute(valueEndTime.minute)}`);//.format('HH:mm');
    // console.log(startTime24hFormat);
    // console.log(endTime24hFormat);
    // las horas formateadas a 24h se convierten en fecha con formato de 24 horas
    const startTimeFormat = startTime24hFormat; // moment(startTime24hFormat, 'HH:mm');
    const endTimeFormat = endTime24hFormat; // moment(endTime24hFormat, 'HH:mm');
    // console.log(startTimeFormat);
    // console.log(endTimeFormat);

    // validacion para saber si es el primer turno del día
    if(isTheFirstTurn){
      // se valida que la hora inicial del turno = startTimeFormat sea anterior a la hora final del turno endTimeFormat
      if (startTimeFormat.isBefore(endTimeFormat)){
        // console.log('el tiempo es valido');
        this.validationsForm.get(timeIsValid).patchValue(true);
        this.validationsForm.controls[timeIsValid].markAsTouched();
        this.validationsForm.controls[timeIsValid].updateValueAndValidity();
        return;
      }
      // console.log('el tiempo NO es valido =(');
      this.validationsForm.get(timeIsValid).patchValue(false);
      this.validationsForm.controls[timeIsValid].markAsTouched();
      this.validationsForm.controls[timeIsValid].updateValueAndValidity();
      return;
    }else{
      // validacion de horario inicial y final sea valido
      this.validationsForm.controls[timeIsValid].setValidators([Validators.requiredTrue]);
      this.validationsForm.controls[timeIsValid].markAsTouched();
      this.validationsForm.controls[timeIsValid].updateValueAndValidity();

      const previousStartTime24hFormat = moment(this.validationsForm.get(previousStartTime).value).format('HH:mm');
      const previousStartTimeFormat = moment(previousStartTime24hFormat, 'HH:mm');

      if (startTimeFormat.isBefore(previousStartTimeFormat)){
        // console.log('el tiempo final del primer turno debe ser menor al tiempo inicial del segundo turno');
        this.validationsForm.get(timeIsValid).patchValue(false);
        this.validationsForm.controls[timeIsValid].markAsTouched();
        this.validationsForm.controls[timeIsValid].updateValueAndValidity();
        return;
      }

      // se valida que la hora inicial del turno = startTimeFormat sea anterior a la hora final del turno endTimeFormat
      if (startTimeFormat.isBefore(endTimeFormat)){
        // console.log('el tiempo es valido en el else');
        this.validationsForm.get(timeIsValid).patchValue(true);
        this.validationsForm.controls[timeIsValid].markAsTouched();
        this.validationsForm.controls[timeIsValid].updateValueAndValidity();
        return;
      }
      // console.log('el tiempo NO es valido =( en el else');
      this.validationsForm.get(timeIsValid).patchValue(false);
      this.validationsForm.controls[timeIsValid].markAsTouched();
      this.validationsForm.controls[timeIsValid].updateValueAndValidity();
      return;
    }
  }

  /**
   * @description permite limpiar los campos de hora en los horarios y les quita las validaciones
   * @param isTheFirstTurn permite saber si se dio click en el boton de limpiar horas del primer turno o segundo turno
   * @param dataTimes nombres de los campos del formulario por dias de la semana
   */
  cleanTimeInputs(isTheFirstTurn: boolean, dataTimes: any){
    const startTime = isTheFirstTurn ? dataTimes.startTimeFirst : dataTimes.startTimeSecond;
    const endTime = isTheFirstTurn ? dataTimes.endTimeFirst : dataTimes.endTimeSecond;
    const timeIsValid = isTheFirstTurn ? dataTimes.timeFirstIsValid : dataTimes.timeSecondIsValid;
    const timeSetValidators = isTheFirstTurn ? [Validators.requiredTrue] : [];

    // limpio los inpust de las horas
    this.validationsForm.controls[startTime].setValue('');
    this.validationsForm.controls[startTime].setValidators([]);
    this.validationsForm.controls[startTime].updateValueAndValidity();

    this.validationsForm.controls[endTime].setValue('');
    this.validationsForm.controls[endTime].setValidators([]);
    this.validationsForm.controls[endTime].updateValueAndValidity();

    this.validationsForm.get(timeIsValid).patchValue(false);
    this.validationsForm.controls[timeIsValid].setValidators(timeSetValidators);
    this.validationsForm.controls[timeIsValid].updateValueAndValidity();
  }

  /**
   * @description permite obtener la hora del input y le cambia el formato por el de 24 horas
   * @param controlName es el nombre del input al que se le van a obtener los datos
   */
  formatValue24Hours(controlName: string){
    return moment(this.validationsForm.get(controlName).value).format('HH:mm');
  }

  /**
   * @description obtine las horas de los dos turnos y por medio del parametro dataTimes obtine los nombres de los campos
   * @param dataTimes nombres de los campos del formulario por dias de la semana
   */
  getHoursDays(dataTimes: any) {
    const hours = [];
    // valido que este chequeado el dia de la semana
    if (this.validationsForm.get(dataTimes.day).value) {
      // agrego al array los horarios del primer turno
      hours.push({ i: this.formatValue24Hours(dataTimes.startTimeFirst), f: this.formatValue24Hours(dataTimes.endTimeFirst) });
      // valido que los horarios del segundo turno sean valido
      if (this.validationsForm.get(dataTimes.timeSecondIsValid).value) {
        // agrego al array los horarios del segundo turno
        hours.push({ i: this.formatValue24Hours(dataTimes.startTimeSecond), f: this.formatValue24Hours(dataTimes.endTimeSecond) });
      }
    }
    return hours;
  }

  /**
   * @description guarda los horarios en los que trabaja la empresa
   */
  updateBusinessHours(){
    const agendaUserOrOrganization = this.formAgendaUserOrOrganization.get('agendaUserOrOrganization').value;
    const idUser = agendaUserOrOrganization === '-1' ? null : agendaUserOrOrganization;
    const hoursOfTheWeek = {
      0: this.getHoursDays(this.paramsSundayChecked),
      1: this.getHoursDays(this.paramsMondayChecked),
      2: this.getHoursDays(this.paramsTuesdayChecked),
      3: this.getHoursDays(this.paramsWednesdayChecked),
      4: this.getHoursDays(this.paramsThursdayChecked),
      5: this.getHoursDays(this.paramsFridayChecked),
      6: this.getHoursDays(this.paramsSaturdayChecked),
    };

    const data = {
      nombre: 'Jornada Normal',
      horarios: hoursOfTheWeek,
      activo: true,
      organizaciones: this.idOrganization,
      usuarios: idUser,
      extendido: this.validationsForm.get('horarioExtendido').value
    };
    // valido si se va a editar el horario si no se crea uno nuevo
    if(this.dataGetBusinessHours._id){
      this.updateAgenda(this.dataGetBusinessHours._id,data);
    } else {
      this.newAgenda(data);
    }
  }

  newAgenda(data: any){
    // this.loading.present(this.constants.loading.messages.createBusinessHours, this.constants.loading.spinner);

    this.agendaService.postCreateAgenda(data)
    .subscribe((response: any) => {
      // this.loading.dismiss();
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        this.toolsService.presentToast(this.constants.agenda.notificationCreated);
        this.getHoursBusinessOrUsers();
        setTimeout(() => {
          this.scrollToTop();
        }, 100);
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
      } else {
        this.toolsService.alertErrorInformationWs(response, false, this.constants.contactAgendate.supportTeam.nameKeyTeam);
      }
    }, err => {
      // this.loading.dismiss();
      this.toolsService.statusService(err);
    }, () => {
      // this.loading.dismiss();
    });
  }

  updateAgenda(idAgenda: string, data: any) {
    // this.loading.present(this.constants.loading.messages.createBusinessHours, this.constants.loading.spinner);

    this.agendaService.putUpdateAgenda(idAgenda, data)
      .subscribe((response: any) => {
        // this.loading.dismiss();
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.toolsService.presentToast(this.constants.agenda.notificationUpdate);
          this.getHoursBusinessOrUsers();
          setTimeout(() => {
            this.scrollToTop();
          }, 100);
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
        } else {
          this.toolsService.alertErrorInformationWs(response, false, this.constants.contactAgendate.supportTeam.nameKeyTeam);
        }
      }, err => {
        // this.loading.dismiss();
        this.toolsService.statusService(err);
      }, () => {
        // this.loading.dismiss();
      });
  }
}

