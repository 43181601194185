<div class="row mx-0  d-flex align-content-between flex-wrap">
  <div class="col-12 d-flex justify-content-between align-items-center px-4 my-3">

    <div>
      <a [routerLink]="['/']" class="">
        <figure class="mb-0">
          <picture>
            <source media="(max-width: 991px)" srcset="./assets/img/logos/logoLogin.svg">
            <source media="(min-width: 992px)" srcset="./assets/img/logos/logoLogin.svg">
            <img loading="lazy" class="w-50" src="./assets/img/logos/logoLogin.svg" border="0" />
          </picture>
        </figure>
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="d-none d-md-flex mr-3 text--normal text--xm text-secondary">¿Ya tienes una cuenta?</div>
      <a [routerLink]="['/cuenta/ingresar']" class="btn text--upper text--gray text--extrabold text--xl btn-primary rounded-pill">Iniciar Sesión</a>
    </div>


  </div>
  <div class="d-flex col-11 col-sm-11 col-md-12 col-lg-10 col-xl-8 mx-auto mt-5">


    <div class="row mx-0 mb-4 w-100">
      <div class="col-12 mt-4 mt-md-3 px-0 titulos">Registate en Agéndate App</div>
      <div class="col-12 px-0 text-secondary text--xl my-3">Recibe 1 mes gratis.</div>
      <!-- <div class="col-12 px-0 mb-3">
        <div class="row mx-0 d-flex align-items-center justify-content-md-start justify-content-center">
          <div class="d-flex justify-content-center mr-3">
            <button class="px-4 px-md-5 py-2 btn-blue-fb border-0 border-radius-0 bs-none outline-0 text-center text-white text--xm text--bold text-center">
              <i class="text-white fa-md fab fa-facebook-f mr-2"></i> Facebook
            </button>
          </div>
          <div class="d-flex justify-content-center">
            <button class="px-4 px-md-5 py-2 btn-red-google border-0 border-radius-0 bs-none outline-0 text-center text-white text--xm text--bold text-center">
              <i class="text-white fa-md fab fa-google mr-2"></i> Google+
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 text--blue text--xl mb-4">O utiliza tu correo electrónico</div> -->

      <form class="col-12 px-0 forms" [formGroup]="validationsForm" (ngSubmit)="createAccount()">
        <!-- contenedor paso 1 del registro -->
        <div [ngClass]="{ 'd-none' : stepRegistration === 2 }">
          <div class="titulos text-secondary">Paso 1 de 2</div>
          <div class="titulos mb-3">Datos personales</div>
          <div class="row">

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textNombres"><span class="icon-label icon-user"></span> Nombres</label>
              <input type="text" id="textNombres" formControlName="nombres" class="form-control text--xm"
                placeholder="Nombres completos">

              <ng-container *ngFor="let validation of constants.validationMessages.nombres">
                <div class="error-message"
                  *ngIf="validationsForm.get('nombres').hasError(validation.type) && (validationsForm.get('nombres').dirty || validationsForm.get('nombres').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textApellidos"><span class="icon-label icon-user"></span> Apellidos</label>
              <input type="text" id="textApellidos" formControlName="apellidos" class="form-control text--xm"
                placeholder="Apellidos completos">
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="selectTipoIdentificacion"><span class="icon-label icon-businessCards"></span> Tipo de documento</label>
              <ng-select formControlName="tipoIdentificacion" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTipoIdentificacion" [multiple]="false" [searchable]="false" placeholder="Seleccione un tipo">
                <ng-option selected disabled value="">Seleccione un tipo</ng-option>
                <ng-option *ngFor="let item of objSelectTypesOfIdentification" value="{{item._id}}">{{item.nombre}}</ng-option>
              </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoIdentificacion">
                <div class="error-message" *ngIf="validationsForm.get('tipoIdentificacion').hasError(validation.type) && (validationsForm.get('tipoIdentificacion').dirty || validationsForm.get('tipoIdentificacion').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textIdentificacion"><span class="icon-label icon-businessCards"></span> Número de documento</label>
              <input type="text" id="textIdentificacion" formControlName="identificacion" class="form-control text--xm" placeholder="Número de documento">

              <ng-container *ngFor="let validation of constants.validationMessages.identificacion">
                <div class="error-message" *ngIf="validationsForm.get('identificacion').hasError(validation.type) && (validationsForm.get('identificacion').dirty || validationsForm.get('identificacion').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <label class="labels" for="textCorreoElectronico"><span class="icon-label icon-envelope"></span> Correo electrónico</label>
              <input type="email" id="textCorreoElectronico" formControlName="email" class="form-control text--xm" placeholder="Ingresa tu eMail de contacto">

              <ng-container *ngFor="let validation of constants.validationMessages.email">
                <div class="error-message"
                  *ngIf="validationsForm.get('email').hasError(validation.type) && (validationsForm.get('email').dirty || validationsForm.get('email').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <ng-container formGroupName="matching_passwords">
              <div class="col-12 col-sm-12 col-md-6 mb-3">
                <label class="labels" for="textPassword"><span class="icon-label icon-key"></span> Contraseña</label>
                <input type="password" id="textPassword" formControlName="password" class="form-control text--xm" placeholder="Contraseña">

                <ng-container *ngFor="let validation of constants.validationMessages.password">
                  <div class="error-message" *ngIf="validationsForm.get('matching_passwords').get('password').hasError(validation.type) && (validationsForm.get('matching_passwords').get('password').dirty || validationsForm.get('matching_passwords').get('password').touched)">
                    <i class="fas fa-info-circle"></i> {{ validation.message }}
                  </div>
                </ng-container>

                <ng-container *ngFor="let validation of constants.validationMessages.matching_passwords">
                  <div class="error-message" *ngIf="validationsForm.get('matching_passwords').hasError(validation.type) && (validationsForm.get('matching_passwords').get('confirm_password').dirty || validationsForm.get('matching_passwords').get('confirm_password').touched)">
                    <i class="fas fa-info-circle"></i> {{ validation.message }}
                  </div>
                </ng-container>
              </div>

              <div class="col-12 col-sm-12 col-md-6 mb-3">
                <label class="labels" for="textConfirmPassword"><span class="icon-label icon-key"></span> Confirmar contraseña</label>
                <input type="password" id="textConfirmPassword" formControlName="confirm_password" class="form-control text--xm" placeholder="Confirmar contraseña">

                <ng-container *ngFor="let validation of constants.validationMessages.confirm_password">
                  <div class="error-message" *ngIf="validationsForm.get('matching_passwords').get('confirm_password').hasError(validation.type) && (validationsForm.get('matching_passwords').get('confirm_password').dirty || validationsForm.get('matching_passwords').get('confirm_password').touched)">
                    <i class="fas fa-info-circle"></i>  {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textCelulares"><span class="icon-label icon-phone"></span> Teléfono celular</label>
              <input type="tel" id="textCelulares" formControlName="celulares" class="form-control text--xm" placeholder="Ingresa tu número móvil">

              <ng-container *ngFor="let validation of constants.validationMessages.celulares">
                <div class="error-message" *ngIf="validationsForm.get('celulares').hasError(validation.type) && (validationsForm.get('celulares').dirty || validationsForm.get('celulares').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textTelefonos"><span class="icon-label icon-phoneFijo"></span> Teléfono fijo</label>
              <input type="tel" id="textTelefonos" formControlName="telefonos" class="form-control text--xm" placeholder="Ingresa tu número móvil">

              <ng-container *ngFor="let validation of constants.validationMessages.telefonos">
                <div class="error-message"
                  *ngIf="validationsForm.get('telefonos').hasError(validation.type) && (validationsForm.get('telefonos').dirty || validationsForm.get('telefonos').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
              <button aria-label="Continuar" type="button" (click)="nextStepRegistration(2)" class="btn btn-lg btn-primary font-weight-bold rounded-pill {{ constants.gtm.css.registrationStep1 }}">Continuar</button>
            </div>
          </div>
        </div><!-- fin del condetenor del paso 1-->

        <!-- contenedor paso 2 del registro -->
        <div [ngClass]="{ 'd-none' : stepRegistration === 1 }">
          <div class="titulos text-secondary">Paso 2 de 2</div>
          <div class="titulos mb-3">Datos de la empresa</div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textNombreEmpresa"><span class="icon-label icon-businessCards"></span> Nombre</label>
              <input type="text" id="textNombreEmpresa" formControlName="nombreEmpresa" class="form-control text--xm" placeholder="Empresa">

              <ng-container *ngFor="let validation of constants.validationMessages.nombreEmpresa">
                <div class="error-message" *ngIf="validationsForm.get('nombreEmpresa').hasError(validation.type) && (validationsForm.get('nombreEmpresa').dirty || validationsForm.get('nombreEmpresa').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textNitEmpresa"><span class="icon-label icon-businessCards"></span> Nit</label>
              <input type="text" id="textNitEmpresa" formControlName="nitEmpresa" class="form-control text--xm" placeholder="NIT o Numero de identificación personal">

              <ng-container *ngFor="let validation of constants.validationMessages.nitEmpresa">
                <div class="error-message"
                  *ngIf="validationsForm.get('nitEmpresa').hasError(validation.type) && (validationsForm.get('nitEmpresa').dirty || validationsForm.get('nitEmpresa').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textDireccionEmpresa"><span class="icon-label icon-locationPoint"></span> Dirección</label>
              <input type="text" id="textDireccionEmpresa" formControlName="direccionEmpresa" class="form-control text--xm" placeholder="Dirección">

              <ng-container *ngFor="let validation of constants.validationMessages.direccionEmpresa">
                <div class="error-message" *ngIf="validationsForm.get('direccionEmpresa').hasError(validation.type) && (validationsForm.get('direccionEmpresa').dirty || validationsForm.get('direccionEmpresa').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="selectTipoServicioEmpresa"><span class="icon-label icon-businessCards"></span> Tipo de servicio</label>
              <ng-select formControlName="tipoServicioEmpresa" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTipoServicioEmpresa" [multiple]="false" [searchable]="false" placeholder="Seleccione un tipo de servicio">
                <ng-option selected disabled value="">Seleccione un tipo</ng-option>
                <ng-option *ngFor="let item of objSelectTypesOfService" value="{{item._id}}">{{item.nombre}}</ng-option>
              </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoServicioEmpresa">
                <div class="error-message" *ngIf="validationsForm.get('tipoServicioEmpresa').hasError(validation.type) && (validationsForm.get('tipoServicioEmpresa').dirty || validationsForm.get('tipoServicioEmpresa').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <!-- <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="selectTipoDePlan">Tipo de plan</label>
              <select class="form-control text--xm" id="selectTipoDePlan" formControlName="tipoDePlan">
                <option selected disabled value="">Seleccione un tipo de plan</option>
                <option *ngFor="let item of objSelectPlan" value="{{item._id}}">{{item.nombre}}</option>
              </select>

              <ng-container *ngFor="let validation of constants.validationMessages.tipoDePlan">
                <div class="error-message" *ngIf="validationsForm.get('tipoDePlan').hasError(validation.type) && (validationsForm.get('tipoDePlan').dirty || validationsForm.get('tipoDePlan').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div> -->

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textCelularesEmpresa"><span class="icon-label icon-phone"></span> Teléfono celular</label>
              <input type="tel" id="textCelularesEmpresa" formControlName="celularesEmpresa" class="form-control text--xm" placeholder="Ingresa tu número móvil">

              <ng-container *ngFor="let validation of constants.validationMessages.celulares">
                <div class="error-message" *ngIf="validationsForm.get('celularesEmpresa').hasError(validation.type) && (validationsForm.get('celularesEmpresa').dirty || validationsForm.get('celularesEmpresa').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <label class="labels" for="textTelefonosEmpresa"><span class="icon-label icon-phoneFijo"></span> Teléfono fijo</label>
              <input type="tel" id="textTelefonosEmpresa" formControlName="telefonosEmpresa" class="form-control text--xm" placeholder="Ingresa tu número fijo">

              <ng-container *ngFor="let validation of constants.validationMessages.telefonos">
                <div class="error-message" *ngIf="validationsForm.get('telefonosEmpresa').hasError(validation.type) && (validationsForm.get('telefonosEmpresa').dirty || validationsForm.get('telefonosEmpresa').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <label class="labels" for="textReferidos"><span class="icon-label icon-codeRef"></span> Código de referido</label>
              <input type="text" id="textReferidos" formControlName="referidos" class="form-control text--xm" placeholder="Recibirás descuentos adicionales">
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <label class="labels" for="selectComoTeEnteraste"><span
                  class="icon-label icon-agendaConfirmadaCita"></span> Cómo te enteraste de nosotros</label>
              <ng-select formControlName="comoTeEnteraste" class="text--xl" [dropdownPosition]="'bottom'"
                appearance="outline" labelForId="selectComoTeEnteraste" [multiple]="true" [searchable]="false"
                placeholder="Seleccione un opción">
                <ng-option selected disabled value="">Selecciona una o varias opciones</ng-option>
                <ng-option *ngFor="let parameter of parameters" value="{{parameter}}">{{ parameter }}</ng-option>
              </ng-select>
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <div class="form-check custom-checkbox checkbox-negro">
                <input type="checkbox" id="checkAceptedTerms" formControlName="aceptedTerms" class="custom-control-input" (click)='confirmacionTerminosYCondiciones()'>
                <label class="text--xm pt-1 custom-control-label" for="checkAceptedTerms">
                  Acepto <a href="https://agendateapp.com/politicasdeprivacidad/terminos-y-condiciones-AgendateApp-v.1.0.1.pdf" target="_blank" class="text-primary">política de tratamiento de datos y términos y condiciones</a>
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 mb-3 text-center">
              <button aria-label="Atrás" type="button" (click)="nextStepRegistration(1)" class="btn btn-lg btn-primary font-weight-bold rounded-pill mr-3">Atrás</button>
              <button aria-label="Registrarme" type="submit" [disabled]="!validationsForm.valid" class="btn btn-lg btn-primary font-weight-bold rounded-pill {{ constants.gtm.css.registrationStep2 }}">Registrarme</button>
            </div>
          </div>
        </div><!-- Fin del contenedor del paso 2 -->
      </form>

    </div>



  </div>
  <div class="row mx-0 w-100 justify-content-center pb-4 networks d-md-none d-lg-none d-xl-none">
    <span class="col-12 text-primary mb-2 text--normal text--xm text-center">
      Siguenos en nuestras redes sociales
    </span>
    <app-social-networks-icons></app-social-networks-icons>
  </div>
</div>
