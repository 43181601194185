<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Horarios</h6>
        <h6 class="card-subtitle">Define los horarios específicos de atención de la empresa o personal encargado de
          atender las citas/reservas.</h6>

        <div class="row">
          <div class="col-12">
            <form [formGroup]="formAgendaUserOrOrganization">
              <label class="labels text-primary" for="selectAgendaUserOrOrganization">
                <span class="icon-label icon-businessCards"></span> Empresa o atiende citas
              </label>
              <ng-select formControlName="agendaUserOrOrganization" (change)="getHoursBusinessOrUsers()" class="text--xl" [dropdownPosition]="'bottom'"
                appearance="outline" labelForId="selectAgendaUserOrOrganization" [multiple]="false" [searchable]="false"
                placeholder="Seleccione un tipo">
                <ng-option value="-1">Empresa</ng-option>
                <ng-option *ngFor="let user of users" [value]="user._id">{{user.nombres}} {{user.apellidos}}</ng-option>
              </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.agendaUserOrOrganization">
                <div class="error-message"
                  *ngIf="formAgendaUserOrOrganization.get('agendaUserOrOrganization').hasError(validation.type) && (formAgendaUserOrOrganization.get('agendaUserOrOrganization').dirty || formAgendaUserOrOrganization.get('agendaUserOrOrganization').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </form>
          </div>
        </div>

        <form class="forms m-t-40" [formGroup]="validationsForm" (ngSubmit)="updateBusinessHours()">
          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Domingo</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsSundayChecked);" formControlName="sundayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('sundayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftSunday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftSunday', 'endTimeFirstShiftSunday', 'firstSundayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftSunday', 'endTimeFirstShiftSunday', 'firstSundayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftSunday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftSunday').dirty || validationsForm.get('startTimeFirstShiftSunday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftSunday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftSunday', 'endTimeFirstShiftSunday', 'firstSundayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftSunday', 'endTimeFirstShiftSunday', 'firstSundayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftSunday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftSunday').dirty || validationsForm.get('endTimeFirstShiftSunday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsSundayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstSundayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstSundayTimeIsValid').dirty || validationsForm.get('firstSundayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftSunday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftSunday', 'endTimeSecondShiftSunday', 'secondSundayTimeIsValid', 'endTimeFirstShiftSunday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftSunday', 'endTimeSecondShiftSunday', 'secondSundayTimeIsValid', 'endTimeFirstShiftSunday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftSunday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftSunday').dirty || validationsForm.get('startTimeSecondShiftSunday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftSunday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftSunday', 'endTimeSecondShiftSunday', 'secondSundayTimeIsValid', 'endTimeFirstShiftSunday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftSunday', 'endTimeSecondShiftSunday', 'secondSundayTimeIsValid', 'endTimeFirstShiftSunday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftSunday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftSunday').dirty || validationsForm.get('endTimeSecondShiftSunday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsSundayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondSundayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondSundayTimeIsValid').dirty || validationsForm.get('secondSundayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('sundayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA DOMINGO -->

          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Lunes</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsMondayChecked);" formControlName="mondayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('mondayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftMonday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftMonday', 'endTimeFirstShiftMonday', 'firstMondayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftMonday', 'endTimeFirstShiftMonday', 'firstMondayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftMonday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftMonday').dirty || validationsForm.get('startTimeFirstShiftMonday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftMonday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftMonday', 'endTimeFirstShiftMonday', 'firstMondayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftMonday', 'endTimeFirstShiftMonday', 'firstMondayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftMonday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftMonday').dirty || validationsForm.get('endTimeFirstShiftMonday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsMondayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstMondayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstMondayTimeIsValid').dirty || validationsForm.get('firstMondayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftMonday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftMonday', 'endTimeSecondShiftMonday', 'secondMondayTimeIsValid', 'endTimeFirstShiftMonday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftMonday', 'endTimeSecondShiftMonday', 'secondMondayTimeIsValid', 'endTimeFirstShiftMonday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftMonday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftMonday').dirty || validationsForm.get('startTimeSecondShiftMonday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftMonday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftMonday', 'endTimeSecondShiftMonday', 'secondMondayTimeIsValid', 'endTimeFirstShiftMonday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftMonday', 'endTimeSecondShiftMonday', 'secondMondayTimeIsValid', 'endTimeFirstShiftMonday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftMonday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftMonday').dirty || validationsForm.get('endTimeSecondShiftMonday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsMondayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondMondayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondMondayTimeIsValid').dirty || validationsForm.get('secondMondayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('mondayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA LUNES -->

          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Martes</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsTuesdayChecked);" formControlName="tuesdayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('tuesdayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftTuesday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftTuesday', 'endTimeFirstShiftTuesday', 'firstTuesdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftTuesday', 'endTimeFirstShiftTuesday', 'firstTuesdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftTuesday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftTuesday').dirty || validationsForm.get('startTimeFirstShiftTuesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftTuesday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftTuesday', 'endTimeFirstShiftTuesday', 'firstTuesdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftTuesday', 'endTimeFirstShiftTuesday', 'firstTuesdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftTuesday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftTuesday').dirty || validationsForm.get('endTimeFirstShiftTuesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsTuesdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstTuesdayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstTuesdayTimeIsValid').dirty || validationsForm.get('firstTuesdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftTuesday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftTuesday', 'endTimeSecondShiftTuesday', 'secondTuesdayTimeIsValid', 'endTimeFirstShiftTuesday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftTuesday', 'endTimeSecondShiftTuesday', 'secondTuesdayTimeIsValid', 'endTimeFirstShiftTuesday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftTuesday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftTuesday').dirty || validationsForm.get('startTimeSecondShiftTuesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftTuesday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftTuesday', 'endTimeSecondShiftTuesday', 'secondTuesdayTimeIsValid', 'endTimeFirstShiftTuesday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftTuesday', 'endTimeSecondShiftTuesday', 'secondTuesdayTimeIsValid', 'endTimeFirstShiftTuesday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftTuesday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftTuesday').dirty || validationsForm.get('endTimeSecondShiftTuesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsTuesdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondTuesdayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondTuesdayTimeIsValid').dirty || validationsForm.get('secondTuesdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('tuesdayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA MARTES -->

          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Miercoles</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsWednesdayChecked);" formControlName="wednesdayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('wednesdayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftWednesday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftWednesday', 'endTimeFirstShiftWednesday', 'firstWednesdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftWednesday', 'endTimeFirstShiftWednesday', 'firstWednesdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftWednesday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftWednesday').dirty || validationsForm.get('startTimeFirstShiftWednesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftWednesday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftWednesday', 'endTimeFirstShiftWednesday', 'firstWednesdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftWednesday', 'endTimeFirstShiftWednesday', 'firstWednesdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftWednesday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftWednesday').dirty || validationsForm.get('endTimeFirstShiftWednesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsWednesdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstWednesdayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstWednesdayTimeIsValid').dirty || validationsForm.get('firstWednesdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftWednesday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftWednesday', 'endTimeSecondShiftWednesday', 'secondWednesdayTimeIsValid', 'endTimeFirstShiftWednesday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftWednesday', 'endTimeSecondShiftWednesday', 'secondWednesdayTimeIsValid', 'endTimeFirstShiftWednesday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftWednesday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftWednesday').dirty || validationsForm.get('startTimeSecondShiftWednesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftWednesday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftWednesday', 'endTimeSecondShiftWednesday', 'secondWednesdayTimeIsValid', 'endTimeFirstShiftWednesday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftWednesday', 'endTimeSecondShiftWednesday', 'secondWednesdayTimeIsValid', 'endTimeFirstShiftWednesday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftWednesday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftWednesday').dirty || validationsForm.get('endTimeSecondShiftWednesday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsWednesdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondWednesdayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondWednesdayTimeIsValid').dirty || validationsForm.get('secondWednesdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('wednesdayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA MIERCOLES -->

          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Jueves</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsThursdayChecked);" formControlName="thursdayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('thursdayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftThursday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftThursday', 'endTimeFirstShiftThursday', 'firstThursdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftThursday', 'endTimeFirstShiftThursday', 'firstThursdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftThursday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftThursday').dirty || validationsForm.get('startTimeFirstShiftThursday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftThursday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftThursday', 'endTimeFirstShiftThursday', 'firstThursdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftThursday', 'endTimeFirstShiftThursday', 'firstThursdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftThursday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftThursday').dirty || validationsForm.get('endTimeFirstShiftThursday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsThursdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstThursdayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstThursdayTimeIsValid').dirty || validationsForm.get('firstThursdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftThursday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftThursday', 'endTimeSecondShiftThursday', 'secondThursdayTimeIsValid', 'endTimeFirstShiftThursday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftThursday', 'endTimeSecondShiftThursday', 'secondThursdayTimeIsValid', 'endTimeFirstShiftThursday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftThursday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftThursday').dirty || validationsForm.get('startTimeSecondShiftThursday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftThursday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftThursday', 'endTimeSecondShiftThursday', 'secondThursdayTimeIsValid', 'endTimeFirstShiftThursday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftThursday', 'endTimeSecondShiftThursday', 'secondThursdayTimeIsValid', 'endTimeFirstShiftThursday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftThursday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftThursday').dirty || validationsForm.get('endTimeSecondShiftThursday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsThursdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondThursdayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondThursdayTimeIsValid').dirty || validationsForm.get('secondThursdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('thursdayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA JUEVES -->

          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Viernes</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsFridayChecked);" formControlName="fridayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('fridayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftFriday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftFriday', 'endTimeFirstShiftFriday', 'firstFridayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftFriday', 'endTimeFirstShiftFriday', 'firstFridayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftFriday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftFriday').dirty || validationsForm.get('startTimeFirstShiftFriday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftFriday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftFriday', 'endTimeFirstShiftFriday', 'firstFridayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftFriday', 'endTimeFirstShiftFriday', 'firstFridayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftFriday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftFriday').dirty || validationsForm.get('endTimeFirstShiftFriday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsFridayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstFridayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstFridayTimeIsValid').dirty || validationsForm.get('firstFridayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftFriday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftFriday', 'endTimeSecondShiftFriday', 'secondFridayTimeIsValid', 'endTimeFirstShiftFriday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftFriday', 'endTimeSecondShiftFriday', 'secondFridayTimeIsValid', 'endTimeFirstShiftFriday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftFriday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftFriday').dirty || validationsForm.get('startTimeSecondShiftFriday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftFriday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftFriday', 'endTimeSecondShiftFriday', 'secondFridayTimeIsValid', 'endTimeFirstShiftFriday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftFriday', 'endTimeSecondShiftFriday', 'secondFridayTimeIsValid', 'endTimeFirstShiftFriday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftFriday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftFriday').dirty || validationsForm.get('endTimeSecondShiftFriday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsFridayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondFridayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondFridayTimeIsValid').dirty || validationsForm.get('secondFridayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('fridayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA VIERNES -->

          <div class="row mt-2">
            <div class="col-6">
              <span class="text-primary text--bold">Sabado</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" (change)="dayChecked(paramsSaturdayChecked);" formControlName="saturdayChecked"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
          </div>
          <div class="row border-bottom pb-2 align-items-center" [ngClass]="{ 'd-none' : !validationsForm.get('saturdayChecked').value }">
            <div class="col-12">
              <span class="text-primary">Primer turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeFirstShiftSaturday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftSaturday', 'endTimeFirstShiftSaturday', 'firstSaturdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftSaturday', 'endTimeFirstShiftSaturday', 'firstSaturdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeFirstShiftSaturday').hasError(validation.type) && (validationsForm.get('startTimeFirstShiftSaturday').dirty || validationsForm.get('startTimeFirstShiftSaturday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeFirstShiftSaturday" (click)="validateHoursByShifts(true, 'startTimeFirstShiftSaturday', 'endTimeFirstShiftSaturday', 'firstSaturdayTimeIsValid');" (change)="validateHoursByShifts(true, 'startTimeFirstShiftSaturday', 'endTimeFirstShiftSaturday', 'firstSaturdayTimeIsValid');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeFirstShiftSaturday').hasError(validation.type) && (validationsForm.get('endTimeFirstShiftSaturday').dirty || validationsForm.get('endTimeFirstShiftSaturday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(true, paramsSaturdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.startTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('firstSaturdayTimeIsValid').hasError(validation.type) && (validationsForm.get('firstSaturdayTimeIsValid').dirty || validationsForm.get('firstSaturdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <span class="text-primary">Segundo turno</span>
            </div>
            <div class="col-5">
              <span class="text-primary">Inicia:</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="startTimeSecondShiftSaturday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftSaturday', 'endTimeSecondShiftSaturday', 'secondSaturdayTimeIsValid', 'endTimeFirstShiftSaturday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftSaturday', 'endTimeSecondShiftSaturday', 'secondSaturdayTimeIsValid', 'endTimeFirstShiftSaturday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('startTimeSecondShiftSaturday').hasError(validation.type) && (validationsForm.get('startTimeSecondShiftSaturday').dirty || validationsForm.get('startTimeSecondShiftSaturday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-5">
              <span class="text-primary">Termina</span>
              <!-- [(ngModel)]="time" -->
              <ngb-timepicker formControlName="endTimeSecondShiftSaturday" (click)="validateHoursByShifts(false, 'startTimeSecondShiftSaturday', 'endTimeSecondShiftSaturday', 'secondSaturdayTimeIsValid', 'endTimeFirstShiftSaturday');" (change)="validateHoursByShifts(false, 'startTimeSecondShiftSaturday', 'endTimeSecondShiftSaturday', 'secondSaturdayTimeIsValid', 'endTimeFirstShiftSaturday');" [meridian]="meridian"></ngb-timepicker>
              <ng-container *ngFor="let validation of constants.validationMessages.requiredHour">
                <div class="error-message py-2" *ngIf="validationsForm.get('endTimeSecondShiftSaturday').hasError(validation.type) && (validationsForm.get('endTimeSecondShiftSaturday').dirty || validationsForm.get('endTimeSecondShiftSaturday').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <div class="col-2 mt-3">
              <button type="button" (click)="cleanTimeInputs(false, paramsSaturdayChecked)" class="btn btn-outline-primary btn-circle"><i class="fa fa-times"></i> </button>
            </div>
            <div class="col-12">
              <ng-container *ngFor="let validation of constants.validationMessages.endTimeIsInvalid">
                <div class="error-message py-2" *ngIf="validationsForm.get('secondSaturdayTimeIsValid').hasError(validation.type) && (validationsForm.get('secondSaturdayTimeIsValid').dirty || validationsForm.get('secondSaturdayTimeIsValid').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template [ngIf]="!validationsForm.get('saturdayChecked').value">
            <div class="row">
              <div class="col-12 border-bottom py-2">
                <span class="text-muted text--medium">CERRADO</span>
              </div>
            </div>
          </ng-template>
          <!-- FIN DIA SABADO -->

          <!-- Horario exendido -->
          <div class="row mt-2 border-bottom pb-2">
            <div class="col-6">
              <span class="text-primary text--bold">Ofrece horario extendido</span>
            </div>
            <div class="col-6">
              <div class="switch pull-right">
                <label><input type="checkbox" formControlName="horarioExtendido"><span class="lever switch-col-blue"></span></label>
              </div>
            </div>
            <div class="col-12">
              <span class="text-secondary">Permite agendar en horarios de atención que no sean los configurados.</span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 mt-3">
              <button aria-label="Guardar" type="submit" [disabled]="!validationsForm.valid"
                class="btn btn-lg btn-primary text-uppercase font-weight-bold">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
