import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import 'moment-duration-format';

// Redux
import { Store } from '@ngrx/store';
import * as personalizationClientActions from '../../store/actions/personalizationClient.actions';
import { AppState } from '../../store/app.reducers';

import { Constants } from 'src/providers/constants/constants';
import { ClientService } from 'src/app/services/client.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-precio-procedimientos-clientes',
  templateUrl: './precio-procedimientos-clientes.component.html',
  styleUrls: ['./precio-procedimientos-clientes.component.scss']
})
export class PrecioProcedimientosClientesComponent implements OnInit {
  moment = moment;
  constants = Constants;
  clientSubDomain: any;
  dataOrganization: any;
  dataPersonalization: any;
  tipoServicio: string = '';
  procedures: any = [];
  searchProcedures = '';
  typesProcedures: any = [];
  pageSkip = 0;
  pageSize = 15;
  // datos interfaz personalización cliente
  colorPrimaryAgendateApp = this.constants.colors.primary;
  logoCliente = '';
  private timeoutSearch: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private clientService: ClientService,
    private procedureService: ProcedureService,
    public toolsService: ToolsService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.typesProcedures = [];
    this.pageSkip = 0;
    // se cambia el color primario solo mientras carga el ws de personalizacion y asi no mostrar el color principal de agendate
    this.elementRef.nativeElement.style.setProperty('--primary', '#FFFFFF');
    // obtengo los datos que lleguen por parametro
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.clientSubDomain) {
        this.clientSubDomain = params.clientSubDomain;
        this.validateClientOrganization();
      } else {
        this.router.navigate(['/404']);
      }
    });
  }

  validateClientOrganization() {
    this.store.dispatch(personalizationClientActions.triggerPersonalizationClientInit());
    this.clientService.postClientOrganizationValidate({ sub_dominio: this.clientSubDomain })
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success) {
          this.dataOrganization = response.data;
          this.dataPersonalization = response.data.personalization;
          console.log(this.dataPersonalization);

          this.tipoServicio = this.dataOrganization.type_service;
          // si no existe personalizacion no se aplican los cambios
          if (this.dataPersonalization !== '' && this.dataPersonalization !== undefined) {
            this.store.dispatch(personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: this.dataPersonalization }));
            this.setPersonalization(true);
          } else {
            this.setPersonalization(false);
          }
          this.getProceduresSearch();
        } else if (typeResponse === this.constants.services.types.error) {
          this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimaryAgendateApp);
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description obtinene los datos de personalizacion de la empresa y los aplica
   */
  setPersonalization(personalizar: boolean = false) {
    if (personalizar) {
      // se aplica la personalizacion que venga del ws
      // this.tituloPublicidad = this.dataPersonalization.texto_principal !== '' ? this.dataPersonalization.texto_principal : this.tituloPublicidad;
      // this.descripcionPublicidad = this.dataPersonalization.texto_secundario !== '' ? this.dataPersonalization.texto_secundario : this.descripcionPublicidad;
      this.logoCliente = (this.dataPersonalization.logo !== '') ? this.dataPersonalization.logo : '';
      // this.logoClienteSecundario = (this.dataPersonalization.logo_secundario !== '') ? this.dataPersonalization.logo_secundario : '';
      // this.imgFondo = (this.dataPersonalization.fondo !== '') ? this.dataPersonalization.fondo : '/assets/img/bg-agendar-citas.jpg';
      const colorPrimary = (this.dataPersonalization.color_principal !== '') ? `#${this.dataPersonalization.color_principal}` : this.colorPrimaryAgendateApp;
      this.elementRef.nativeElement.style.setProperty('--primary', colorPrimary);
      // console.log(this.dataPersonalization);
      return;
    }
    this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimaryAgendateApp);
  }

  /**
* @description se activa cuando cambia el change del buscador de procedimientos
*/
  searchProceduresChange(event: any) {
    this.searchProcedures = event.target.value;
    if(this.searchProcedures.length > 1 && this.searchProcedures.length < 3) return;
    clearTimeout(this.timeoutSearch);
    this.typesProcedures = [];
    this.pageSkip = 0;
    this.timeoutSearch = setTimeout(() => {
      this.getProceduresSearch();
    }, 300);
  }

  /**
 * @description Se hace llamado al web services que busca los tipos de procedimientos
 */
  getProceduresSearch(event = null) {
    // esta es la guia de la data con buscador
    // const data = JSON.stringify(
    //   {
    //     usuarios: { estados: ['activo', 'inactivo'], texto: this.searchUsers },
    //     paginador: {
    //       limit: {
    //         skip: this.pageSkip,
    //         size: this.pageSize
    //       },
    //       order: {
    //         nombres: 1,
    //         apellidos: 1
    //       }
    //     }
    //   }
    // );
    const data = JSON.stringify({ tipos: [this.tipoServicio], estados: 'activo', nombre: this.searchProcedures });
    this.procedureService.getProcedureSearch(data, this.dataOrganization.token)
      .subscribe(response => {
        const typeResponse = response['type'];
        const lengthData = response['data'].length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.typesProcedures = this.typesProcedures.concat(response['data']);
          this.pageSkip = this.typesProcedures.length;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response['message'].messageDescription);
        } else if (event === null) {
          this.typesProcedures = null;
        }
        // si es llamado desde el evento del infinite-scroll
        if (event) {
          event.target.complete();
          // this.ionInfiniteScroll.disabled = false;
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }
}
