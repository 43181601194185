import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TypesService {

  constructor(public Api: ApiService) { }

  getGroupTypes(paramType: string) {
    // return this.Api.get(`/type?grupo=${paramType}`);
    return this.Api.get(`/type/params/{"grupo":"${paramType}"}`);
  }
}
