<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Datos del procedimiento</h6>
        <!-- <h6 class="card-subtitle">Just add class to the form that's it.</h6> -->
        <form class="forms m-t-40" [formGroup]="validationsForm" (ngSubmit)="createAndEditProcedure()">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textNombre"><span class="icon-label icon-businessCards"></span> Nombre</label>
              <input type="text" id="textNombre" formControlName="nombre" class="form-control text--xm" placeholder="Nombre del procedimiento">

              <ng-container *ngFor="let validation of constants.validationMessages.nombres">
                <div class="error-message" *ngIf="validationsForm.get('nombre').hasError(validation.type) && (validationsForm.get('nombre').dirty || validationsForm.get('nombre').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textAbreviatura"><span class="icon-label icon-businessCards"></span> Abreviatura</label>
              <input type="text" id="textAbreviatura" formControlName="abreviatura" class="form-control text--xm" placeholder="Abreviatura del procedimiento">
            </div>

            <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textareaDescripcion">
                <span class="icon-label icon-note"></span> Descripción
              </label>
              <textarea class="form-control" id="textareaDescripcion" formControlName="descripcion" placeholder="Ingresa una descripción." rows="3"></textarea>

              <ng-container *ngFor="let validation of constants.validationMessages.nota">
                <div class="error-message" *ngIf="validationsForm.get('descripcion').hasError(validation.type) && (validationsForm.get('descripcion').dirty || validationsForm.get('descripcion').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div> -->

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="selectTiempoEstimado">
                <span class="icon-label icon-clock"></span> Tiempo estimado de duración en minutos
              </label>
                <ng-select formControlName="tiempo_estimado" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectTiempoEstimado" [multiple]="false" [searchable]="true" placeholder="Seleccione un tiempo estimado">
                  <ng-option *ngFor="let timeProcedure of constants.timeProcedures; let i = index" value="{{ timeProcedure.value }}">{{ timeProcedure.label }}</ng-option>
                </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tiempoEstimado">
                <div class="error-message"
                  *ngIf="validationsForm.get('tiempo_estimado').hasError(validation.type) && (validationsForm.get('tiempo_estimado').dirty || validationsForm.get('tiempo_estimado').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textPrecio"><span class="icon-label icon-businessCards"></span> Precio (Solo números y sin puntos)</label>
              <input type="number" id="textPrecio" formControlName="precio" class="form-control text--xm" placeholder="Precio">
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="selectEstado">
                <span class="icon-label icon-clock"></span> Estado
              </label>
                <ng-select formControlName="estado" class="text--xl" [dropdownPosition]="'bottom'" appearance="outline" labelForId="selectEstado" [multiple]="false" [searchable]="true" placeholder="Seleccione un estado">
                  <ng-option [value]="constants.state.active">Activo</ng-option>
                  <ng-option [value]="constants.state.inactive">Inactivo</ng-option>
                </ng-select>

              <ng-container *ngFor="let validation of constants.validationMessages.tiempoEstimado">
                <div class="error-message"
                  *ngIf="validationsForm.get('estado').hasError(validation.type) && (validationsForm.get('estado').dirty || validationsForm.get('estado').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-3 mb-3">
              <label class="labels" for="textareaDescripcion">
                <span class="icon-label icon-note"></span> Descripción
              </label>
              <textarea class="form-control text--xm" id="textareaDescripcion" formControlName="descripcion" placeholder="Ingresa una descripción." rows="3"></textarea>

              <ng-container *ngFor="let validation of constants.validationMessages.nota">
                <div class="error-message" *ngIf="validationsForm.get('descripcion').hasError(validation.type) && (validationsForm.get('descripcion').dirty || validationsForm.get('descripcion').touched)">
                  <i class="fas fa-info-circle"></i> {{ validation.message }}
                </div>
              </ng-container>
            </div>

            <div class="col-12 col-sm-12 col-md-12 mb-3">
              <button aria-label="Guardar" type="submit" [disabled]="!validationsForm.valid" class="btn btn-lg btn-primary text-uppercase font-weight-bold">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
