import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class RegisteraccountService {

  constructor(public Api: ApiService) { }

  postCreateAccount(paramData: any) {
    return this.Api.post('/account/create', paramData);
  }
}
