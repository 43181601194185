import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(public Api: ApiService) { }

  getAllParameter(token: string = null) {
    // return this.Api.get(`/parameter/${data}`);
    return this.Api.get(`/parameter/`, token);
  }

  getParameterByParams(data: any, token: string = null) {
    return this.Api.get(`/parameter/params/${data}`, token);
  }

  postCreateParameter(data: any) {
    return this.Api.post('/parameter/', data);
  }
}
