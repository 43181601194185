<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
    <!-- Sidebar navigation-->
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <li class="user-profile">
          <a class="has-arrow waves-effect waves-dark" href="javascript:void();" aria-expanded="false"><img loading="lazy" src="./assets/images/users/profile.png" alt="user" /><span class="hide-menu">{{ nameUSer }} </span></a>
          <ul aria-expanded="false" class="collapse">
            <ng-container *ngFor="let menu of constants.accountMenu">
              <li><a class="cursor-pointer" (click)="openPage(menu)"><i [class]="menu.icon"></i> {{ menu.title }}</a></li>
            </ng-container>
          </ul>
        </li>
        <li class="nav-devider"></li>
        <li class="nav-small-cap text-uppercase">configuraciones</li>
        <!-- <li>
          <a class="has-arrow waves-effect waves-dark" aria-expanded="false">
            <i class="mdi mdi-gauge"></i><span class="hide-menu">Administrar</span>
          </a>
          <ul aria-expanded="false" class="collapse">
            <li *ngFor="let p of appPages" (click)="openPage(p)"><a [routerLink]="[p.url]" routerLinkActive="active">{{p.title}} </a></li>
          </ul>
        </li> -->
        <ng-template [ngIf]="idOrganization !== '5ed433d811831630f2006e57'">
          <li *ngFor="let p of appPages" (click)="openPage(p)" [routerLink]="[p.url]" routerLinkActive="active">
            <a class="waves-effect waves-dark">
              <i [class]="p.icon"></i><span class="hide-menu">{{p.title}}</span>
            </a>
          </li>
        </ng-template>
        <!-- 5ed433d811831630f2006e57 -->
        <ng-template [ngIf]="idOrganization == '5ed433d811831630f2006e57'">
          <li routerLink="/admin/api/dashboard" routerLinkActive="active">
            <a class="waves-effect waves-dark">
              <i class="mdi mdi-clipboard-text"></i><span class="hide-menu">Informe de consumo</span>
            </a>
          </li>
        </ng-template>
      </ul>
    </nav>
    <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
