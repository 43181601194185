import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { Constants } from '../../providers/constants/constants';
// import { WOW } from 'wowjs/dist/wow.min';
// new WOW().init();
declare var $: any;

@Component({
  selector: 'app-web-agendateapp',
  templateUrl: './web-agendateapp.component.html',
  styleUrls: ['./web-agendateapp.component.scss']
})
export class WebAgendateappComponent implements OnInit {
  constants = Constants;
  currentYear = moment().format('YYYY');

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.initJquey();
  }

  initJquey() {
    $(document).ready(() => {

      $('body').scrollspy({
        target: '#navbar',
        offset: 80
      });

      // Page scrolling feature
      $('a.page-scroll').bind('click', function(event) {
        const link = $(this);
        try {
          $('html, body').stop().animate({
              scrollTop: $(link.attr('href')).offset().top - 50
          }, 500);
        } catch (error) {
        }
        event.preventDefault();
        $('#navbar').collapse('hide');
    });
    });

    const cbpAnimatedHeader = (() => {
      const docElem = document.documentElement;
      const header = document.querySelector('.navbar-default');
      let didScroll = false;
      const changeHeaderOn = 200;
      function init() {
        window.addEventListener('scroll', (event) => {
          if (!didScroll) {
            didScroll = true;
            setTimeout(scrollPage, 250);
          }
        }, false);
      }
      function scrollPage() {
        const sy = scrollY();
        if (sy >= changeHeaderOn) {
          $(header).addClass('navbar-scroll');
        }
        else {
          $(header).removeClass('navbar-scroll');
        }
        didScroll = false;
      }
      function scrollY() {
        return window.pageYOffset || docElem.scrollTop;
      }
      init();

    })();

  }
}

