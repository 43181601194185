import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivationEnd, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { setTheme } from 'ngx-bootstrap/utils';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Subscription } from 'rxjs';
// Redux
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducers';
import * as dataSeoPageActions from './store/actions/dataSeoPage.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public metaDataSeoSubs$: Subscription;
  styleAdminWeb = document.querySelector('#styleAdminWeb');
  themeAdminWeb = document.querySelector('#themeAdminWeb');
  jsCustomAdminWeb = document.querySelector('#jsCustomAdminWeb');

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    angulartics2Facebook: Angulartics2Facebook
  ) {
    setTheme('bs4'); // or 'bs3'
    angulartics2GoogleTagManager.startTracking();
    angulartics2GoogleAnalytics.startTracking();
    angulartics2Facebook.startTracking();
    this.metaDataSeoSubs$ = this.getArgumentosRuta().subscribe((data) => {
      this.store.dispatch(dataSeoPageActions.dataSeoPaginaSuccess({ dataSeoPage: data }));
      this.setMetaData(data);
    });
  }

  ngOnInit() {
    this.addStyleAdminWeb();
  }

  ngOnDestroy(): void {
    this.metaDataSeoSubs$?.unsubscribe();
  }

  addStyleAdminWeb() {
    // let urlActual = '';
    // this.router.events.subscribe((event: RouterEvent) => {
    //   urlActual = event.url !== undefined ? event.url : '';
    //   if (event instanceof NavigationEnd && urlActual.includes('/admin/')) {
    //     // Custom CSS
    //     this.styleAdminWeb.setAttribute('href', 'assets/css/style.css');
    //     // You can change the theme colors from here
    //     this.themeAdminWeb.setAttribute('href', 'assets/css/colors/default-dark.css');
    //     this.jsCustomAdminWeb.setAttribute('src', 'assets/js/custom.min.js');
    //   } else if (event instanceof NavigationEnd) {
    //     // Custom CSS
    //     this.styleAdminWeb.setAttribute('href', '');
    //     // You can change the theme colors from here
    //     this.themeAdminWeb.setAttribute('href', '');
    //     this.jsCustomAdminWeb.setAttribute('src', '');
    //   }
    // });
  }

  getArgumentosRuta() {
    return this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild === null),
        map((event: ActivationEnd) => event.snapshot.data),
      );
  }

  /**
   * @description registra los meta datos en los buscadores mas populares, Google , facebook y twitter
   * @param data objeto con metadatos principales
   */
  setMetaData(data) {
    const titulo = data.title !== undefined ? `${data.title} | Agéndate App` : '';
    const url = this.router.url === '/' ? 'https://agendateapp.com' : `https://agendateapp.com${this.router.url}`;

    this.titleService.setTitle(titulo);
    this.meta.updateTag({ 'name': 'keywords', 'content': data?.keywords !== undefined ? data?.keywords : '' });
    this.meta.updateTag({ 'name': 'description', 'content': data?.description !== undefined ? data?.description : '' });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': titulo });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': titulo });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data?.description !== undefined ? data?.description : '' });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data?.image !== undefined ? data?.image : '' });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': titulo });
    this.meta.updateTag({ 'property': 'og:title', 'content': titulo });
    this.meta.updateTag({ 'property': 'og:url', 'content': url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data?.image !== undefined ? data?.image : '' });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': titulo });
    this.meta.updateTag({ 'property': 'og:description', 'content': data?.description !== undefined ? data?.description : '' });
    // this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    // this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    // this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    // this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    // this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    // this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    // this.meta.updateTag({ 'property': 'fb:app_id', 'content': '183640772242005' });
    // this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });
  }
}
