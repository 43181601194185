import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { Constants } from '../../../../../providers/constants/constants';
import { I18nService } from '../../../../services/i18n.service';
import { CustomDatepickerI18nService } from '../../../../services/custom.datepickerI18n.Service';
import { ProcedureService } from '../../../../services/procedure.service';
import { ToolsService } from '../../../../services/tools.service';
import { UserService } from '../../../../services/user.service';
import { AppointmentService } from '../../../../services/appointment.service';

import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { async } from '@angular/core/testing';

// lenguaje calendario de fechas
const currentDate = new Date();
@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
  providers: [I18nService, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }] // define custom NgbDatepickerI18n provider
})
export class CreateAppointmentComponent implements OnInit, OnDestroy {
  constants = Constants;
  dataActivatedRoute: { [key: string]: any } = {};
  validationsForm: FormGroup;
  idUserSesion = JSON.parse(localStorage.getItem(this.constants.localStorage.user))._id;
  tipoServicio = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones.tipo_servicio;
  procedures: any = [];
  users$: Observable<any>;
  usersLoading = false;
  usersInput$ = new Subject<string>();
  listUsuariosAdminAtinedeCitasSubs: Subscription = new Subscription();
  disponivilidadCitasPorUsuarioAtendedorSubs: Subscription = new Subscription();

  searchUsers: string = '';
  usersAttendsAppointment: any = [];
  idUserClient: string;
  idAppointment: string;
  nameUserClient: string = '';
  editAppointment = false;
  editDateAppointment = false;
  // datos config datepicker
  @ViewChild('dpAppointment') dpAppointment;
  fechaNgbStrcut: NgbDateStruct;
  listaFechasDisponiblesCitas: any = [];
  fechasDisponiblesEnCalendario: any = [];
  horasDisponiblesPorDia: any = [];
  fechaDisponibleSeleccionada = '';
  horaDisponibleSeleccionada = '';
  valorDelProcedimiento: string = '';
  // fin config
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private procedureService: ProcedureService,
    public toolsService: ToolsService,
    private userService: UserService,
    private appointmentService: AppointmentService,
  ) { }

  ngOnInit(): void {
    this.validatorsFormNewAppointment();
    this.getDataParams();
    this.getUsersSearch();
    this.getProcedure();

  }

  ngOnDestroy() {
    this.listUsuariosAdminAtinedeCitasSubs.unsubscribe();
    this.listUsuariosAdminAtinedeCitasSubs.unsubscribe();
  }

  validatorsFormNewAppointment() {
    this.validationsForm = this.formBuilder.group({
      usuarioCliente: ['', [Validators.required]],
      tipoCita: ['', [Validators.required]],
      usuarioAtendedor: ['', [Validators.required]],
      fechaCita: ['', [Validators.required]],
      fechaHora: ['', [Validators.required]],
      nota: ['', [Validators.required]],
    });
  }

  /**
   * @description Limpia los datos de las fechas, llama a servio de disponibilidad de calendario y activa el calendario y el seleccionador de horas
   */
  dateAppointmentEdit() {
    this.editDateAppointment = true;
    this.resetValoresFechasCitas();
    this.changeConsultarDisponivilidadCitas();
  }

  getDataParams(){
    // obtengo los datos que lleguen por parametro
    this.activatedRoute.params.subscribe(data => {
      // se utiliza this.dataActivatedRoute de forma global
      this.dataActivatedRoute = { ...data };
      if (data._id) {
        this.idUserClient = data._id;
        this.nameUserClient = `${data.nombres} ${data.apellidos}`;
      }
    });
  }

  /**
   * @description Se hace llamado al web services que busca usuarios de la cuenta
   */
  getUsersSearch() {
    this.users$ = concat(
      of([]), // default items
      this.usersInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.usersLoading = true),
        switchMap(term => {
          const data = JSON.stringify({
              usuarios: { estados: 'activo', roles: 'paciente', texto: term },
              paginador: {
                limit: { skip: 0, size: 100, },
                order: { nombres: 1, apellidos: 1 }
              }});
          return this.userService.getUsersSearchPipe(data).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.usersLoading = false)
          )
        })
      )
    );
  }

  /**
   * @description Se hace llamado al web services que busca los tipos de procedimiento de la empresa
   */
  getProcedure() {
    const data = JSON.stringify({ tipos: [this.tipoServicio], estados: 'activo' });
    this.procedureService.getProcedureSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.procedures = response.data;
          setTimeout(() => {
            // se cargan datos en el formulario solo si es editar cita
            if (this.dataActivatedRoute._idAppointment) {
              this.editAppointment = true;
              this.idAppointment = this.dataActivatedRoute._idAppointment;
              const dataAppointment = {
                usuarioCliente: this.dataActivatedRoute._id,
                tipoCita: this.dataActivatedRoute.procedimientos,
                fechaCita: moment(this.dataActivatedRoute.fecha).format('YYYY-MM-DD'),
                fechaHora: moment(this.dataActivatedRoute.fecha).format('HH:mm'),
                nota: this.dataActivatedRoute.nota
              };
              this.validationsForm.patchValue(dataAppointment);
              this.validationsForm.updateValueAndValidity();
              this.getUsersSearchAttendsAppointment();
            }
          }, 500);
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se hace llamado al web services que busca usuarios de la cuenta
   */
  async getUsersSearchAttendsAppointment() {
    this.usersAttendsAppointment = [];
    this.validationsForm.get('usuarioAtendedor').reset();
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const dataTipoCita = this.procedures.filter(procedimiento => procedimiento._id === tipoCita)[0];
    this.valorDelProcedimiento = dataTipoCita?.caracteristicas ? dataTipoCita?.caracteristicas.valor_servicio.valor : '';
    const data = JSON.stringify({ usuarios: { estados: 'activo', roles: ['atiendecitas', 'administrador'], procedimientos: tipoCita }});
    this.listUsuariosAdminAtinedeCitasSubs = await this.userService.getUsersSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          if (response.data.length > 0) {
            this.usersAttendsAppointment = response.data;
            setTimeout(() => {
              // se cargan datos solo si es editar cita se selecciona el usuario atendendo de la cita
              if (this.dataActivatedRoute._idAppointment && this.usersAttendsAppointment.filter(user => user._id === this.dataActivatedRoute.usuario_atendedor).length > 0) {
                this.validationsForm.patchValue({ usuarioAtendedor: this.dataActivatedRoute.usuario_atendedor });
                this.validationsForm.updateValueAndValidity();
                this.disponivilidadCitasPorUsuarioAtendedor(moment(currentDate,'YYYY-MM-DD').format('YYYY-MM-DD'));
              }
            }, 500);
          } else {
            // se notifica al usuario que no existen usuarios atiende citas y se manda a crear usuario
            this.dataActivatedRoute.dataRedirectUrl = '/admin/citas/crear';
            this.toolsService.alertNotificacionSimple(this.constants.mensajesInformativos.noUsersAttendAppointmentsNewAppointment, '/admin/usuarios/crear', this.dataActivatedRoute, 'Ir al administrador de usuarios');
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description resetea los valores que se utilizan para las fechas y horas del calendario de citas disponibles
   */
  resetValoresFechasCitas() {
    this.fechasDisponiblesEnCalendario = [];
    this.listaFechasDisponiblesCitas = [];
    this.horasDisponiblesPorDia = [];
    this.horaDisponibleSeleccionada = '';
    this.fechaDisponibleSeleccionada = '';
    this.validationsForm.controls.fechaCita.setValue('');
    this.validationsForm.controls.fechaHora.setValue('');
  }

  /**
   * @description valida que se seleccione un usuarioAtendedor y tipoCita para consultar disponibilidad de citas
   */
  changeConsultarDisponivilidadCitas() {
    // se limpia el calendario, horas disponibles e inputs ocultos
    this.resetValoresFechasCitas();
    const usuarioAtendedor = this.validationsForm.get('usuarioAtendedor').value;
    const tipoCita = this.validationsForm.get('tipoCita').value;
    const currentDateFormat = moment(currentDate,'YYYY-MM-DD').format('YYYY-MM-DD');
    // validacion para activar el calendario de citas disponibles y horas, solo se activa cuando es editar cita
    if(this.editAppointment && (usuarioAtendedor !== this.dataActivatedRoute.usuario_atendedor || tipoCita !== this.dataActivatedRoute.procedimientos)){
      this.editDateAppointment = true;
    }
    if (usuarioAtendedor !== null && usuarioAtendedor !== '' && tipoCita !== null && tipoCita !== '') {
      // se manda la fecha actual para que el servicio solo traiga apartir de la fecha que se manda
      this.disponivilidadCitasPorUsuarioAtendedor(currentDateFormat);
    }
  }

  // NOTA: recordarle a victor que se debe agregar el tiempo por defecto a los procedimientos que ya existen en la base de datos
  // por que por ahora le estoy asignado 30 minutos
  async disponivilidadCitasPorUsuarioAtendedor(fechaInicial: string = null) {
    const idProcedure = this.validationsForm.get('tipoCita').value;
    const tipoProcedimiento = this.procedures.filter(procedure => procedure._id === idProcedure);
    const tiempoEstimado = (tipoProcedimiento[0].tiempo_estimado) ? tipoProcedimiento[0].tiempo_estimado : 30;
    const data = {
      usuario_atendedor: this.validationsForm.get('usuarioAtendedor').value,
      rango: 'mes', // posibles randos: mes, semana y dia
      fecha: (fechaInicial !== null) ? moment(fechaInicial).valueOf() : null,
      procedimientos: idProcedure,
      tiempo_estimado: tiempoEstimado,
      origen: this.constants.origenNewCita[1]
    };

  this.listUsuariosAdminAtinedeCitasSubs = await this.appointmentService.postValidarAgendaAtendedorPorProcedimientoDia(data).subscribe((response: any) => {
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        if (response.data.dias.length > 0) {
          this.listaFechasDisponiblesCitas = response.data.dias;
          for (const item of this.listaFechasDisponiblesCitas) {
            // YYYY-MM-DD
            if (this.fechasDisponiblesEnCalendario.findIndex(fecha => fecha === item.fecha) === -1) {
              this.fechasDisponiblesEnCalendario.push(item.fecha);
            }
          }
          this.selectToday(this.fechasDisponiblesEnCalendario[0]);
        } else {
          // se notifica al usuario que no existen citas disponibles
          this.toolsService.presentToast(this.constants.mensajesInformativos.noHayCitasDisponibles.mensaje, this.constants.iconsSweetalert.warning);
        }
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
      }
    }, err => {
      this.toolsService.statusService(err);
    }, () => {
    });
  }

  /**
   * @description selecciona por defecto la fecha en el calendario,
   * se manda el primer registro de los dias disponibles de fechasDisponiblesEnCalendario
   * @param {string} fechaSeleccionar fecha a seleccionar, llega en formato YYYY-MM-DD
   */
  selectToday(fechaSeleccionar: string) {
    this.fechaNgbStrcut = {
      year: parseInt(moment(fechaSeleccionar, 'YYYY-MM-DD').format('YYYY'), 10),
      month: parseInt(moment(fechaSeleccionar, 'YYYY-MM-DD').format('MM'), 10),
      day: parseInt(moment(fechaSeleccionar, 'YYYY-MM-DD').format('DD'), 10),
    };
    this.validationsForm.controls.fechaCita.setValue(this.fechaNgbStrcut);
    this.getHorasDisponiblesPorDia(fechaSeleccionar);
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  hasTask(date: NgbDateStruct) {
    return this.fechaConDisponibilidadDeCitas(date);
  }

  /**
   * @description se activa cuando cambian de mes o año en el calendario
   * @param date fecha que tiene el calendario al activarse el evento navigate del calendario
   */
  navegacionFechas(date: NgbDateStruct) {
    const mesActual = parseInt(moment(currentDate).format('MM'), 10);
    const anoActual = parseInt(moment(currentDate).format('YYYY'), 10);
    // se resetean los valores
    this.resetValoresFechasCitas();
    if (date.year >= anoActual && date.month >= mesActual && this.validationsForm.get('usuarioAtendedor').value !== null && this.validationsForm.get('usuarioAtendedor').value !== ''
      && this.validationsForm.get('tipoCita').value !== null && this.validationsForm.get('tipoCita').value !== '') {
      // se manda la fecha actual para que el servicio solo traiga apartir de la fecha que se manda
      const dateCalendar = moment(`${date.year}-${date.month}-01`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      this.disponivilidadCitasPorUsuarioAtendedor(dateCalendar);
    }
    // else if ((date.month - mesActual) <= 1) {
    //   // la operacion del if es por que solo se permite consultar el mes siguiente al actual,
    //   // de lo contrario no se permite ver mas disponibilidad de fechas
    //   this.disponivilidadCitasPorUsuarioAtendedor(`${date.year}-${date.month}-01`);
    // }
  }

  showTasks(date: NgbDateStruct) {
    if (this.fechaConDisponibilidadDeCitas(date)) {
      const ano = date.year;
      const mes = date.month <= 9 ? `0${date.month}` : date.month;
      const dia = date.day <= 9 ? `0${date.day}` : date.day;
      this.getHorasDisponiblesPorDia(`${ano}-${mes}-${dia}`);
    } else {
      this.horasDisponiblesPorDia = [];
    }
  }

  fechaConDisponibilidadDeCitas(date: NgbDateStruct): boolean {
    // solo se renderiza el mes actual  con el del calendario
    // if (currentDate.getMonth() + 1 === date.month) {
    for (const item of this.fechasDisponiblesEnCalendario) {
      const day: number = parseInt(moment(item, 'YYYY-MM-DD').format('DD'), 10);
      const month: number = parseInt(moment(item, 'YYYY-MM-DD').format('MM'), 10);
      const year: number = parseInt(moment(item, 'YYYY-MM-DD').format('YYYY'), 10);

      if (day === date.day && month === date.month && year === date.year) {
        return true;
      }
    }
    // }
  }

  /**
   * @description se recorre el array de fechas inicial listaFechasDisponiblesCitas y se compara con la fecha que llega por parametro
   * se agregan las horas que coinciden con la fecha
   * @param {string} fechaDisponibilidad fecha selecciona en el calendario
   */
  getHorasDisponiblesPorDia(fechaDisponibilidad: string) {
    this.horasDisponiblesPorDia = [];
    this.horaDisponibleSeleccionada = '';
    this.fechaDisponibleSeleccionada = fechaDisponibilidad;

    //  NOTA: ESTE FOR SE PUEDE MEJORAR POR UN FILTER CUANDO EL SERVICIO DEVUELVA LA FECHA POR CADA DIA
    for (const item of this.listaFechasDisponiblesCitas) {
      // YYYY-MM-DD
      if (item.fecha === fechaDisponibilidad) {
        for (const horaDisponible of item.horas) {
          if (this.horasDisponiblesPorDia.findIndex(hora => hora === horaDisponible) === -1) {
            // se compara la fecha que llega por parametro con la fecha actual si son iguales
            // se validan las horas mayores a la actual y que sea mayor a 2 horas
            // es para validar disponivilidad de citas
            if (fechaDisponibilidad === moment(currentDate).format('YYYY-MM-DD')) {
              const horaDisponibleCita = moment(horaDisponible, 'h:mm');
              // se le agregan dos horas mas a la hora actual para que se autoasignen citas 2 horas despues de la actual
              // y asi evitar que se asignen con poco tiempo y evitar contratiempos
              // se dej en comentario ya que por ahora no vamos adicionar dos horas a la fecha actual
              // para agendar una cita se va a evaluar la posibilidad de hacer una configuracion mas optima con y parametrizable por el cliente
              // const horaActual = moment(currentDate, 'h:mm').add(2, 'h');
              // se agregan las horas mayores a la actual
              // if (horaDisponibleCita.isBefore(horaActual) === false) {
              if (horaDisponibleCita.isBefore(currentDate) === false) {
                this.horasDisponiblesPorDia.push(horaDisponible);
              }
            } else {
              this.horasDisponiblesPorDia.push(horaDisponible);
            }
          }
        }
      }
    }
  }

  formatHoraDisponibleCita(horaDisponible: string) {
    return moment(horaDisponible, 'HH:mm').format('hh:mm a');
  }

  /**
   * @description asigna la fecha seleciona a una variable que se utiliza al momento de asignar la cita
   * @param {string} horaSeleccionada hora disponible de la cita
   */
  seleccionarHoraDisponibleCita(horaSeleccionada: string) {
    this.horaDisponibleSeleccionada = horaSeleccionada;
    this.validationsForm.controls.fechaHora.setValue(horaSeleccionada);
  }

  /**
   * @description Se hace llamado al web services que crea o edita la cita
   */
  createAndEditAppointment() {
    const fechaNgbStructValue = this.validationsForm.get('fechaCita').value;
    const year = fechaNgbStructValue.year;
    const month = (fechaNgbStructValue.month <= 9) ? `0${fechaNgbStructValue.month}` : fechaNgbStructValue.month;
    const day = (fechaNgbStructValue.day <= 9) ? `0${fechaNgbStructValue.day}` : fechaNgbStructValue.day;
    const fechaFormat = `${year}-${month}-${day}`;
    const date = moment(`${fechaFormat}T${this.validationsForm.get('fechaHora').value}`).format('YYYY-MM-DDTHH:mm');
    const fechaActual = moment().format('YYYY-MM-DDTHH:mm');
    const validarFecha = moment(date).format('YYYY-MM-DDTHH:mm');
    // validacion para verificar que la fecha y hora sean mayor o igual a la fecha actual
    if (!moment(validarFecha).isSameOrAfter(fechaActual)) {
      this.toolsService.alertNotificacionSimple(this.constants.mensajesInformativos.fechaMenorAgendarCita, null, null, 'Aceptar', this.constants.iconsSweetalert.error);
      return;
    }
    const idProcedure = this.validationsForm.get('tipoCita').value;
    const tipoProcedimiento = this.procedures.filter(procedure => procedure._id === idProcedure);
    const timeProcedureSelect = (tipoProcedimiento[0].tiempo_estimado) ? tipoProcedimiento[0].tiempo_estimado : 30;
    const data = {
      usuario_agendador: this.idUserSesion,
      usuario_atendedor: this.validationsForm.get('usuarioAtendedor').value,
      usuario_cliente: this.validationsForm.get('usuarioCliente').value,
      nota: this.validationsForm.get('nota').value.trim(),
      localizacion: '',
      fecha: moment(date).valueOf(),
      tipos: this.tipoServicio,
      procedimientos: idProcedure,
      tiempo_estimado: timeProcedureSelect,
      origen: this.constants.origenNewCita[1]
    };

    // this.loading.present(this.constants.loading.messages.createdAppointment, this.constants.loading.spinner);
    // validacion para identificar si se crea o edita la cita
    if (this.idAppointment) {
      this.appointmentService.putUpdateAppointment(this.idAppointment, data)
        .subscribe((response: any) => {
          // this.loading.dismiss();
          const typeResponse = response.type;
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.appointment.notificationUpdate);
            this.validationsForm.reset();
            this.router.navigate(['/admin/citas']);
          } else if (typeResponse === this.constants.services.types.error) {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          } else {
            this.toolsService.alertErrorInformationWs(response, false, this.constants.contactAgendate.supportTeam.nameKeyTeam);
          }
        }, err => {
          // this.loading.dismiss();
          this.toolsService.statusService(err);
        }, () => {
          // this.loading.dismiss();
        });
    } else {
      this.appointmentService.postCreateAppointment(data)
        .subscribe((response: any) => {
          // this.loading.dismiss();
          const typeResponse = response.type;
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.appointment.notificationCreated);
            this.validationsForm.reset();
            this.router.navigate(['/admin/citas']);
          } else if (typeResponse === this.constants.services.types.error) {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          } else {
            this.toolsService.alertErrorInformationWs(response, false, this.constants.contactAgendate.supportTeam.nameKeyTeam);
          }
        }, err => {
          // this.loading.dismiss();
          this.toolsService.statusService(err);
        }, () => {
          // this.loading.dismiss();
        });
    }
  }
}
