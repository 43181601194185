<div class="row mx-0 d-flex align-content-between flex-wrap">
  <div class="col-12 d-flex justify-content-between align-items-center px-4 my-3">

    <div>
      <a [routerLink]="['/']" class="">
        <figure class="mb-0">
          <picture>
            <source media="(max-width: 991px)" srcset="./assets/img/logos/logoLogin.svg">
            <source media="(min-width: 992px)" srcset="./assets/img/logos/logoLogin.svg">
            <img loading="lazy" class="w-50" src="./assets/img/logos/logoLogin.svg" border="0" />
          </picture>
        </figure>
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="d-none d-md-flex mr-3 text--normal text--xm text-secondary">¿Ya tienes una cuenta?</div>
      <a [routerLink]="['/cuenta/ingresar']"
        class="btn text--upper text--gray text--extrabold text--xl btn-primary rounded-pill">Iniciar Sesión</a>
    </div>


  </div>
  <div class="d-flex col-11 col-sm-11 col-md-12 col-lg-10 col-xl-8 mx-auto mt-5">


    <div class="row mx-0 mb-4 w-100">
      <div class="col-12 mt-4 mt-md-3 px-0 titulos {{ constants.gtm.css.registrationAccountThanks }}">¡Gracias por registrarte en Agéndate App!</div>
      <div class="col-12 mt-4 mt-md-3 text--lg px-0">
        <p class="parrafos">{{ constants.mensajesInformativos.cuentaCreada.mensaje }}</p>
        <p class="parrafos">Recuerda que puedes acceder por nuestra aplicación móvil o página web.</p>
      </div>
      <div class="col-12 mt-4 mt-md-3 text-center">
        <p class="parrafos text--lg">Descarga la aplicación</p>
        <a [href]="constants.urlAppGooglePlay" class="my-2" target="_blanck"><img loading="lazy" src="./assets/img/logos/boton-google-play.png" class="mr-2" alt="Botón de google play store" width="170" height="50"></a>
        <!-- <a [href]="constants.urlAppAppleStore" class="my-2" target="_blanck"><img loading="lazy" src="./assets/img/logos/boton-app-store.png" class="mr-2" alt="Botón de app store" width="170" height="50"></a> -->
        <a [routerLink]="['/cuenta/ingresar']" class="btn btn-lg text--upper text--gray text--extrabold text--xl bg-black text-white my-2 mr-2 p-3">Iniciar Sesión Web</a>
      </div>
    </div>
  </div>
</div>
