import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../../guards/auth.guard';
import { AdminWebComponent } from './admin-web.component';
import { GestionarCitasComponent } from './citas/gestionar-citas/gestionar-citas.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminusersComponent } from './users/adminusers/adminusers.component';
import { CreateandeditusersComponent } from './users/createandeditusers/createandeditusers.component';
import { SearchtypesofproceduresComponent } from './typesofprocedures/searchtypesofprocedures/searchtypesofprocedures.component';
import { CreateandeditproceduresComponent } from './typesofprocedures/createandeditprocedures/createandeditprocedures.component';
import { CreateAppointmentComponent } from './citas/create-appointment/create-appointment.component';
import { PlandetailComponent } from './account/plandetail/plandetail.component';
import { DashboardApiComponent } from './api/dashboard-api/dashboard-api.component';
import { BusinesshoursComponent } from './businesshours/businesshours.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminWebComponent,
    canActivate: [ AuthGuard ],
    children: [
      { path: 'citas', component: GestionarCitasComponent, data: { title: 'Gestión de citas.'} },
      { path: 'citas/crear', component: CreateAppointmentComponent, data: { title: 'Crear cita.'} },
      { path: 'citas/editar', component: CreateAppointmentComponent, data: { title: 'Editar cita.'} },
      { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard.'} },
      { path: 'usuarios', component: AdminusersComponent, data: { title: 'Listado de usuarios.'} },
      { path: 'usuarios/crear', component: CreateandeditusersComponent, data: { title: 'Crear usuario.'} },
      { path: 'usuarios/editar', component: CreateandeditusersComponent, data: { title: 'Editar usuario.'} },
      { path: 'tiposdeprocedimientos', component: SearchtypesofproceduresComponent, data: { title: 'Listado de tipos de procedimiento.'} },
      { path: 'tiposdeprocedimientos/crear', component: CreateandeditproceduresComponent, data: { title: 'Crear procedimiento.'} },
      { path: 'tiposdeprocedimientos/editar', component: CreateandeditproceduresComponent, data: { title: 'Editar procedimiento.'} },
      { path: 'cuenta/plan', component: PlandetailComponent, data: { title: 'Mi plan actual.'} },
      { path: 'api/dashboard', component: DashboardApiComponent, data: { title: 'Informe de consumo.'} },
      { path: 'horariodeatencion', component: BusinesshoursComponent, data: { title: 'Horarios de atención.'} },
      // { path: 'progress', component: ProgressComponent },
      // { path: 'graficas1', component: Graficas1Component },
      { path: '', redirectTo: '/citas', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class PagesAdminWebRoutingModule {}
