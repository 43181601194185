/**
 * @description Archivo que contiene los llamados a los web services para manejar
 * la creacion y edicion de usuarios
 * @author Juan Manuel Viveros M
 * @copyright JMV
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

import { Constants } from '../../../../../providers/constants/constants';
import { PasswordValidator } from '../../../../validators/password.validator';
import { ToolsService } from '../../../../services/tools.service';
import { TypesService } from '../../../../services/types.service';
import { RolesService } from '../../../../services/roles.service';
import { UserService } from '../../../../services/user.service';
// import { LoadingService } from './../../service/loading/loading.service';
import { ProcedureService } from '../../../../services/procedure.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-createandeditusers',
  templateUrl: './createandeditusers.component.html',
  styleUrls: ['./createandeditusers.component.scss']
})
export class CreateandeditusersComponent implements OnInit, OnDestroy {
  constants = Constants;
  usersSearchSubs: Subscription;
  validationsForm: FormGroup;
  matching_passwords_group: FormGroup;
  dataActivatedRoute: {[key: string]: any} = {};
  user: any = {};
  editUser = false;
  showPassword = false;
  titleForm: String = this.constants.users.titleCreated;
  tipoServicio = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).organizaciones.tipo_servicio;
  procedures: any = [];
  objSelectTypesOfRoles: any = [];
  objSelectTypesOfIdentification: any = [];
  urlRedirect = '/admin/usuarios';
  fechaMenorQuelaActual: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toolsService: ToolsService,
    private typesService: TypesService,
    private rolesService: RolesService,
    private userService: UserService,
    private procedureService: ProcedureService,
    public formBuilder: FormBuilder,
  ) { }

  // /**
  //  * @description metodo nativo de ionic, se ejecuta apenas entran a la vista
  //  **/
  ngOnInit() {
    this.getTypesOfRoles();
    this.getTypesOfIdentification();
    this.getProcedure();
    this.validatorsFormCreateAndEditUser();
    // obtengo los datos que lleguen por parametro y se los asigno a this.user, esto pasa al editar un usuario
    this.activatedRoute.params.subscribe(data => {
      this.editUser = false;
      this.dataActivatedRoute = { ...data };
      if (data._id && data.dataRedirectUrl === undefined) {
        this.editUser = true;
        this.user = data;
        this.titleForm = this.constants.users.titleUpdate;
        this.getDataUSer();
      }

      if (data.dataRedirectUrl) {
        this.urlRedirect = data.dataRedirectUrl;
      }
    });
    this.validatorsChangesFormCreateAndEditUser();
  }

  ngOnDestroy() {
    if(this.usersSearchSubs) {
      this.usersSearchSubs?.unsubscribe();
    }
  }

  validatorsFormCreateAndEditUser() {
    this.matching_passwords_group = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup);
    });

    this.validationsForm = this.formBuilder.group({
      tipoRol: ['', [Validators.required]],
      nombres: new FormControl('', Validators.compose([
        // Validators.maxLength(25),
        Validators.minLength(3),
        // Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
        Validators.required
      ])),
      apellidos: [''],
      nacimiento: [''],
      email: ['', [Validators.required, Validators.email]],
      tipoIdentificacion: ['', [Validators.required]],
      identificacion: ['', [Validators.required]],
      procedimientos: [''],
      matching_passwords: this.matching_passwords_group,
      celulares: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      telefonos: ['', [Validators.minLength(7), Validators.maxLength(7)]]
    });
  }

  validatorsChangesFormCreateAndEditUser() {
    if (this.user._id) {
      this.validationsForm.controls['matching_passwords'].reset();
      this.validationsForm.controls['matching_passwords'].disable();
      this.validationsForm.updateValueAndValidity();
      this.showPassword = false;
      return;
    }

    this.validationsForm.controls['tipoRol'].valueChanges.subscribe(data => {
      if (data === this.constants.users.types.patient) {
        this.showPassword = false;
        this.validationsForm.controls['matching_passwords'].reset();
        this.validationsForm.controls['matching_passwords'].disable();
        this.validationsForm.updateValueAndValidity();
      } else {
        this.showPassword = true;
        this.validationsForm.controls['matching_passwords'].enable();
        this.validationsForm.updateValueAndValidity();
      }
    });
  }

  validatorsChangesRoleType() {
    const roleType = this.validationsForm.get('tipoRol').value;
    // validacion para saber si se muestra o no la opcion de tipo de citas que atiende el usuario tipo atiende citas
    if (roleType === this.constants.users.types.attendAppointments || roleType === this.constants.users.types.admin) {
      // seteo nuevas validaciones para el input
      this.validationsForm.controls.procedimientos.setValidators([Validators.required]);
      this.validationsForm.controls.procedimientos.updateValueAndValidity();
    } else {
      // quito validaciones para el input
      this.validationsForm.controls.procedimientos.setValue('');
      this.validationsForm.controls.procedimientos.setValidators([]);
      this.validationsForm.controls.procedimientos.updateValueAndValidity();
    }
  }

  /**
   * @description trae la lista de tipos de roles existentes
   */
  getTypesOfRoles() {
    const data = JSON.stringify({ estados: 'activo' });
    this.rolesService.getRolesTypesByParams(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          const userRole = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).roles._id;
          const dataRoles = response.data;
          // validacion para solo mostrar todos los tipos de roles cuando el rol del usuario en sesion sea el administrador
          if (userRole === this.constants.roles.administrador) {
            this.objSelectTypesOfRoles = dataRoles;
          } else {
            // se muestran solo los roles de usuario que no sean adminsutrador
            this.objSelectTypesOfRoles = dataRoles.filter(roles => roles._id !== this.constants.roles.administrador);
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description trae la lista de tipos de identificación existentes
   */
  getTypesOfIdentification() {
    this.typesService.getGroupTypes('Identificacion')
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.objSelectTypesOfIdentification = response.data;
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  validarFechaNacimiento(){
    this.fechaMenorQuelaActual = '';
    const fecha = this.validationsForm.get('nacimiento')?.value; // es la fecha del select
    const fechaActual = moment().format('YYYY-MM-DD'); // fecha para hacer validaciones
    const fechaMenorQueActual = moment(fechaActual).isAfter(moment(fecha).format('YYYY-MM-DD'));

    // si la fecha es mayor a la actual muestro mensaje de error
    if (!fechaMenorQueActual) {
      // notifico al cliente qeu la fecha debe ser menor a la actual y limpio el campo fecha
      this.fechaMenorQuelaActual = 'La fecha seleccionada debe ser menor a la actual.';
      this.validationsForm.patchValue({nacimiento: ''})
    }
  }

  /**
   * @description Se hace llamado al web services que busca los tipos de procedimiento de la empresa
   */
  getProcedure() {
    const data = JSON.stringify({ tipos: [this.tipoServicio], estados: 'activo' });
    this.procedureService.getProcedureSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.procedures = response.data;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  getDataUSer() {
    const data = JSON.stringify({ usuarios: { _id: this.user._id } });
    this.usersSearchSubs = this.userService.getUsersSearch(data)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          const responseData = response.data[0];
          const listProcedures = [];
          // solo cuando el tipo de usuario es atiende citas se valida si tiene asignados tipos de procedimientos para asignarlos a procedimientos
          if ((responseData.roles._id === this.constants.users.types.attendAppointments || responseData.roles._id === this.constants.users.types.admin) && responseData.procedimientos.length > 0) {
            responseData.procedimientos.forEach(procedure => {
              listProcedures.push(procedure._id);
            });
          }

          const dataUser = {
            tipoRol: responseData.roles._id,
            nombres: responseData.nombres,
            apellidos: responseData.apellidos,
            nacimiento: responseData?.nacimiento ? moment(responseData?.nacimiento).format('YYYY-MM-DD') : null,
            email: responseData.email,
            tipoIdentificacion: responseData.tipo_identificacion._id,
            procedimientos: listProcedures,
            identificacion: responseData.identificacion,
            celulares: responseData.celulares[0],
            telefonos: responseData.telefonos[0]
          };
          this.validationsForm.patchValue(dataUser);
          this.validationsForm.updateValueAndValidity();
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se hace llamado al web services que crea o edita al usuario
   */
  createAndEditUser() {
    let idUser = null;
    let estadoUser = this.constants.state.active;
    let credencial = this.validationsForm.get('matching_passwords.password').value;
    const fechaNacimiento = moment(this.validationsForm.get('nacimiento').value).format('YYYY-MM-DD');
    // se crea hora 00 es necesaria para formar la fecha en formato long
    const horaInicio = moment('000', 'hmm').format('HH:mm:ss.SSS');
    // validacion para saber si se esta editando o creando un usuario
    if (this.user._id) {
      idUser = this.user._id;
      estadoUser = this.user.estados;
    }

    // credencial por defecto para usuarios tipo paciente
    if (this.validationsForm.get('tipoRol').value === 'paciente') {
      credencial = '1qaz2wsX';
    }

    // .trim() quita todos los espacios al inicio y final del string
    const dataUSer = {
      roles: this.validationsForm.get('tipoRol').value,
      nombres: this.validationsForm.get('nombres').value.trim(),
      apellidos: this.validationsForm.get('apellidos').value.trim(),
      nacimiento: moment(`${fechaNacimiento}T${horaInicio}`).valueOf(),
      email: this.validationsForm.get('email').value.trim(),
      credencial: credencial,
      tipo_identificacion: this.validationsForm.get('tipoIdentificacion').value,
      identificacion: this.validationsForm.get('identificacion').value.trim(),
      procedimientos: this.validationsForm.get('procedimientos').value,
      celulares: [this.validationsForm.get('celulares').value.trim()],
      telefonos: [this.validationsForm.get('telefonos').value.trim()],
      terminos_condiciones: true,
      estados: estadoUser,
      idiomas: 'es'
    };

    if (this.user._id) {
      this.user = dataUSer;
      // this.loading.present(this.constants.loading.messages.editUser, this.constants.loading.spinner);
      this.userService.putUpdateUser(idUser, this.user)
        .subscribe(response => {
          // this.loading.dismiss();
          const typeResponse = response['type'];
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.users.notificationUpdate);
            this.validationsForm.reset();
            this.router.navigate([`${this.urlRedirect}`, this.dataActivatedRoute]);
          } else {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          }
        }, err => {
          // this.loading.dismiss();
          this.toolsService.statusService(err);
        }, () => {
          // this.loading.dismiss();
        });
    } else {
      this.user = { usuario: dataUSer };
      // this.loading.present(this.constants.loading.messages.createdUser, this.constants.loading.spinner);
      this.userService.postCreateUser(this.user)
        .subscribe((response: any) => {
          // this.loading.dismiss();
          const typeResponse = response.type;
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.users.notificationCreated);
            this.validationsForm.reset();
            this.router.navigate([`${this.urlRedirect}`, this.dataActivatedRoute]);
          } else {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          }
        }, err => {
          // this.loading.dismiss();
          this.toolsService.statusService(err);
        }, () => {
          // this.loading.dismiss();
        });
    }
  }
}
