import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(public Api: ApiService) { }

  getForwardAppointment(data: any) {
    return this.Api.post(`/notification/resend/${data}`, {});
  }

  getDataConfirmationAppointment(id: string) {
    return this.Api.get(`/appointment_management/info/${id}`);
  }

  postCreateAppointment(data: any, token: string = null) {
    return this.Api.post('/appointment_management/create', data, token);
  }

  putUpdateAppointment(idAppointment: string, data: any) {
    return this.Api.put(`/appointment_management/update/${idAppointment}`, data);
  }

  putCancelAppointment(idAppointment: string, data: any) {
    return this.Api.put(`/appointment_management/cancel/${idAppointment}`, data);
  }

  putConfirmationAppointment(idAppointment: string, data: any) {
    return this.Api.put(`/appointment_management/confirm/${idAppointment}`, data);
  }

  postValidarAgendaAtendedorPorProcedimientoDia(data: any, token: string = null) {
    return this.Api.post('/client/appointment/validate', data, token);
  }
}
